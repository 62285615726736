//
// buttons.scss
//

.btn {
  color: $white;

  .mdi {
    &:before {
      margin-top: -1px;
    }
  }
}

.btn-rounded {
  border-radius: 2em;
}

//
// light button
//
.btn-light,
.btn-white {
  color: $gray-900 !important;
}

.btn-toolbar {
  color: $gray-900 !important;
  border: none;

  width: 40px;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    pointer-events: none;
  }

  &:not(:disabled):hover,
  &.active {
    color: $primary !important;
  }

  &:focus,
  &:focus-visible,
  &:focus-within {
    box-shadow: none;
  }

  &.active {
    box-shadow: 0 0 0 0.15rem rgba($primary, 0.25);
  }

  i {
    font-size: 20px;
    line-height: 20px;
  }
}

//
// Link buttons
//
// Make a button look and behave like a link
.btn-link {
  font-weight: $font-weight-normal;
  color: $link-color;
  background-color: transparent;
  @include hover {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
    background-color: transparent;
    border-color: transparent;
  }
  &:focus,
  &.focus {
    text-decoration: $link-hover-decoration;
    border-color: transparent;
    box-shadow: none;
  }
  &:disabled,
  &.disabled {
    color: $btn-link-disabled-color;
    pointer-events: none;
  } // No need for an active state here
}
//
// Alternate buttons
//
@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @include button-variant($value, $value);
  }

  .swal2-actions .swal2-confirm.swal2-styled.btn-#{$color} {
    @include button-variant($value, $value);
  }

  .swal2-actions .swal2-cancel.swal2-styled.btn-#{$color} {
    @include button-variant($value, $value);
  }
}
@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    @include button-outline-variant($value);
  }
}

//
// Button labels
//

.btn-label {
  margin: -0.55rem 0.9rem -0.55rem -0.9rem;
  padding: 0.6rem 0.9rem;
  background-color: rgba($gray-900, 0.1);
}

.btn-label-right {
  margin: -0.55rem -0.9rem -0.55rem 0.9rem;
  padding: 0.6rem 0.9rem;
  background-color: rgba($gray-900, 0.1);
}

//
// Button Extra Small Size
//

.btn-xs {
  padding: 0;
  font-size: 15px;
  width: 31px;
  height: 31px;
  border-radius: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

// btn bordered

@mixin button-bordered-variant($bg) {
  background-color: $bg;
  color: $white !important;
  border-bottom: 2px solid darken($bg, 7%);
}

@each $color, $value in $theme-colors {
  .btn-bordered-#{$color} {
    @include button-bordered-variant($value);

    &:focus,
    &.focus {
      box-shadow: $btn-box-shadow, 0 0 0 $btn-focus-width rgba($value, 0.5);
    }
  }
}

// file upload

.fileupload {
  overflow: hidden;
  position: relative;
  input.upload {
    cursor: pointer;
    filter: alpha(opacity=0);
    font-size: 20px;
    margin: 0;
    opacity: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.btn-group {
  .border-right-white {
    margin-right: 2px;
  }
}

.input-group-prepend .btn,
.input-group-append .btn {
  z-index: unset;
}

.btn-light {
  background-color: $white;
  border-color: $input-border-color;

  &:disabled,
  &.disabled {
    border-color: $input-border-color;
  }
}

.swal2-actions .swal2-confirm.btn-light,
.swal2-actions .swal2-cancel.btn-light {
  &:not(:hover) {
    background-color: $white !important;
  }
  border-color: $input-border-color;
  border-width: $input-border-width;
  border-style: solid;
}

.btn-group.btn-group-sm.btn-group-toggle > .btn {
  line-height: 24px;
}

.btn-sm {
  line-height: 24px;
}

label.btn-primary:not(:disabled):not(.disabled).active {
  background-color: $primary !important;
  border-color: $primary !important;
}
