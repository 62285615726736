.notify-item {
  padding: 12px 20px;
  display: flex;
  align-items: flex-start;

  .notify-icon {
    height: 36px;
    width: 36px;
    min-width: 36px;
    line-height: 36px;
    font-size: 18px;
    text-align: center;
    margin-right: 10px;
    border-radius: 50%;
  }

  .notify-details {
    margin-bottom: 5px;
    color: $gray-800;
    font-family: $font-family-secondary;
    overflow: hidden;

    .notify-title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    b {
      font-weight: 500;
    }
    small {
      display: block;
      white-space: normal;
    }
    span {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 13px;
    }
    .notify-message {
      margin-bottom: 5px;
    }
  }

  .notify-clear {
    text-align: center;
    margin-left: 10px;
  }

  .user-msg {
    margin-left: 45px;
    white-space: normal;
    line-height: 16px;
  }
}
