#sidebar-menu {
  width: $leftbar-width-collapsed;
  position: fixed;
  top: 0;
  left: 0;
  right: unset;
  bottom: 0;
  z-index: 1000;
  background-color: $primary;

  .metismenu {
    padding: 0;
    z-index: 9999;

    li {
      list-style: none;
      position: relative;

      .icon-circle {
        width: 40px;
        height: 40px;
        border-radius: 50%;

        display: flex;
        justify-content: center;
        align-items: center;
      }

      a {
        color: $text-muted;

        &.active {
          color: $primary !important;
        }

        width: $leftbar-width-collapsed;
        height: 60px;

        display: flex;
        align-items: center;
        justify-content: center;

        // padding: 15px 20px;
        // text-align: center;

        i {
          font-size: 1.3rem;
          color: rgba($white, 0.8);
        }

        &.active {
          .icon-circle {
            background-color: rgba($white, 0.3);
          }

          i {
            color: $white;
          }
        }
      }

      .menu-tooltip {
        display: none;

        position: absolute;
        top: -1px;
        left: $leftbar-width-collapsed;
        width: 186px;

        padding: 20px 24px;
        background-color: $white;

        transition: all 0.3s ease;

        border-top-right-radius: $card-border-radius;
        border-bottom-right-radius: $card-border-radius;

        z-index: 20;

        min-height: 62px;

        box-sizing: border-box;
        border: 1px solid $card-border-color;
        border-left-width: 0;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &.has-submenu {
          height: auto;
        }

        .submenu {
          a {
            height: auto;
            justify-content: start;
          }
          a:not(:last-child) {
            margin-bottom: 20px;
          }
        }
      }

      &:hover {
        .menu-tooltip {
          display: block;
        }

        i,
        .active i {
          // color: $white;
          color: $primary;
        }

        .active {
          .icon-circle {
            background-color: rgba($primary, 0.15);
          }
        }

        a:not(.menu-tooltip) {
          background-color: $white;
          z-index: 10000;
        }
      }
    }
    ul {
      padding: 0;
      li {
        width: 100%;
      }
    }
  }

  .logo-container {
    padding-top: 20px;
    height: 85px;
  }
}
