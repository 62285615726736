.tooltip {
  .tooltip-inner {
    font-size: 12px;
    z-index: 9999 !important;
    padding: 0.3rem 0.6rem;
    background-color: transparentize($dark, 0.02) !important;
    border-color: transparentize($dark, 0.02) !important;
  }

  &.bs-tooltip-left {
    .arrow::before {
      border-left-color: transparentize($dark, 0.02) !important;
    }
  }

  &.bs-tooltip-right {
    .arrow::before {
      border-right-color: transparentize($dark, 0.02) !important;
    }
  }

  &.bs-tooltip-bottom {
    .arrow::before {
      border-bottom-color: transparentize($dark, 0.02) !important;
    }
  }

  &.bs-tooltip-top {
    .arrow::before {
      border-top-color: transparentize($dark, 0.02) !important;
    }
  }
}
