//
// tables.scss
//

.table {
  th {
    font-weight: $font-weight-medium;
    color: $text-muted;
  }

  .small {
    th {
      font-weight: $font-weight-bold;
    }
  }
}
//Table centered (Custom)
.table-centered {
  td,
  th {
    vertical-align: middle !important;
  }
}

// Custom table components (Custom)
.table {
  .table-user {
    img {
      height: 30px;
      width: 30px;
    }
  }
}

.action-icon {
  color: $gray-600;
  font-size: 1.2rem;
  display: inline-block;
  padding: 0 3px;

  &:hover {
    color: $gray-800;
  }
}

tr:focus,
tr:active {
  outline: 0px solid transparent !important;
}

td:focus,
td:active {
  outline: 0px solid transparent !important;
}

.thead-accent-light {
  background-color: rgb(250, 252, 252) !important;
}
