@import './mapbox';

.ol-control {
  border-radius: $btn-border-radius !important;

  &-stacked {
    button:not(:last-child) {
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
    button:not(:first-child) {
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
    }

    button.active {
      background-color: map-get($theme-colors, primary) !important;
      color: #fff;
    }
  }
}

.ol-control button {
  border-radius: $btn-border-radius !important;
  width: 31px !important;
  height: 31px !important;
  font-size: 16px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  font-weight: normal !important;

  border: none;

  &:hover,
  &:focus {
    outline: 2px solid transparentize(map-get($theme-colors, primary), 0.8);
  }
}

.ol-control button.icon-text-button {
  width: auto !important;
  height: auto !important;
}

.ol-control.ol-zoom .ol-zoom-in {
  border-radius: 0.25rem 0.25rem 0 0 !important;
}

.ol-control.ol-zoom .ol-zoom-out {
  border-radius: 0 0 0.25rem 0.25rem !important;
}

.custom-ol-control {
  background-color: rgba(255, 255, 255, 0.4);
  padding: 2px;
  border-radius: 0.25rem;

  &-badge {
    padding: 0;
    background-color: #fff;
  }
}

.ol-scale-line {
  background-color: transparentize(#fff, 0.15) !important;
  left: calc(1em + 50px) !important;
  .ol-scale-line-inner {
    color: $body-color !important;
    border-color: $gray-500 !important;
  }
}

@media screen and (max-width: 767px) {
  .ol-scale-line {
    left: calc(1em + 55px) !important;
  }
}

.ol-full-screen.ol-unselectable {
  opacity: 0 !important;
  pointer-events: none !important;
}

.ol-popup {
  position: absolute;
  background-color: white;
  -webkit-filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
  filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
  border: 1px solid #cccccc;
  bottom: unset;
  left: 20px;
  top: unset;
  margin-top: -15px;
  min-width: 400px !important;
  max-width: 600px !important;
  width: 100% !important;
  max-height: 400px;
  ul {
    padding-inline-start: 25px;
    :not(li, a) {
      margin-left: -25px;
    }
  }

  .element-container input[type='range']:disabled::-webkit-slider-thumb {
    background-color: $primary !important;
  }

  .element-container input[type='range']:disabled::-moz-range-thumb {
    background-color: $primary !important;
  }
}

.ol-popup:after,
.ol-popup:before {
  right: 100%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.ol-popup:after {
  border-right-color: #f5ffff;
  border-width: 7px;
  top: 15px;
}
.ol-popup:before {
  border-right-color: #cccccc;
  border-width: 7px;
  top: 15px;
}

.popup-animated {
  animation-name: popup-slide-open;
  animation-duration: 0.75s;
  animation-fill-mode: forwards;
}

@media only screen and (max-width: 425px) {
  .ol-popup {
    min-width: 80vw !important;
    max-width: 90vw !important;
  }
  .popup-animated {
    animation-name: popup-slide-open-mobile;
    animation-duration: 0.75s;
    animation-fill-mode: forwards;
    max-height: 320px;
  }
}

@keyframes popup-slide-open {
  from {
    max-height: 0 !important;
  }
  to {
    max-height: 400px !important;
  }
}
@keyframes popup-slide-open-mobile {
  from {
    max-height: 0 !important;
  }
  to {
    max-height: 320px !important;
  }
}
.ol-popup:hover,
.ol-popup:active {
  cursor: context-menu !important;
}

.clickable-map {
  cursor: pointer !important;
}

/* OL tooltip*/
.draw-tooltip {
  font-weight: 400;
  font-size: 0.8rem;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 5px;
  padding: 5px;
  opacity: 1 !important;
  width: auto !important;
  min-width: 150px;
  max-width: 200px;
}
