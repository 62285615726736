//
// general.scss
//

html {
  position: relative;
  min-height: 100%;
}

body {
  overflow-x: hidden;
}

@media (min-width: 1000px) {
  .container {
    max-width: 900px !important;
  }
}

@media (min-width: 1050px) {
  .container {
    max-width: 950px !important;
  }
}

@media (min-width: 1100px) {
  .container {
    max-width: 1000px !important;
  }
}

@media (min-width: 1150px) {
  .container {
    max-width: 1050px !important;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1100px !important;
  }
}

@media (min-width: 1250px) {
  .container {
    max-width: 1150px !important;
  }
}

@media (min-width: 1300px) {
  .container {
    max-width: 1200px !important;
  }
}

@media (min-width: 1350px) {
  .container {
    max-width: 1250px !important;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1300px !important;
  }
}

@media (min-width: 1450px) {
  .container {
    max-width: 1350px !important;
  }
}

@media (min-width: 1500px) {
  .container {
    max-width: 1400px !important;
  }
}

@media (min-width: 1550px) {
  .container {
    max-width: 1450px !important;
  }
}

@media (min-width: 1600px) {
  .container {
    max-width: 1500px !important;
  }
}

@media (min-width: 1650px) {
  .container {
    max-width: 1550px !important;
  }
}

@media (min-width: 1700px) {
  .container {
    max-width: 1600px !important;
  }
}

@media (min-width: 1750px) {
  .container {
    max-width: 1650px !important;
  }
}
