.ql-text-element {
  iframe,
  img,
  video {
    max-width: 80vw !important;
  }

  p,
  ol,
  ul,
  dl {
    margin: 0 !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 !important;
    padding: 0 !important;
  }
}

.ql-align-center {
  text-align: center;
}

.ql-align-right {
  text-align: right;
}

.ql-align-justify {
  text-align: justify;
}

.ql-editor {
  h1 {
    font-size: $h1-font-size !important;
  }
  h2 {
    font-size: $h2-font-size !important;
  }
  h3 {
    font-size: $h3-font-size !important;
  }
  h4 {
    font-size: $h4-font-size !important;
  }
  h5 {
    font-size: $h5-font-size !important;
  }
  h6 {
    font-size: $h6-font-size !important;
  }
}

span.mention {
  background-color: rgba($info, 0.15);
  color: $info;
  font-weight: 500;
  padding: 3px 6px;
  border-radius: 3px;
}
