@use 'sass:math' as math;

//
// card.scss
//

.card {
  margin-bottom: $grid-gutter-width;
}

.card-drop {
  font-size: 20px;
  line-height: 0;
  color: inherit;
}

.card-widgets {
  float: right;
  height: 16px;
  > a {
    color: inherit;
    font-size: 18px;
    display: inline-block;
    line-height: 1;

    &.collapsed {
      i {
        &:before {
          content: '\F415';
        }
      }
    }
  }
}

// Card title / Card Header
.card-title,
.card-header {
  margin-top: 0;
}

//Card disable loading (Custom Cards)
.card-disabled {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: $card-border-radius;
  background: rgba($white, 0.8);
  cursor: progress;

  .card-portlets-loader {
    position: absolute;
    left: calc(50% - 1rem);
    top: calc(50% - 1rem);
    margin-left: -(math.div($grid-gutter-width, 2));
    margin-top: -(math.div($grid-gutter-width, 2));
  }
}

// Custom card box
.card-box {
  background-color: $card-bg;
  background-clip: border-box;
  border: $card-border-width solid $card-border-color;
  padding: $card-spacer-x;
  margin-bottom: $grid-gutter-width;
  @include border-radius($card-border-radius);
}

.header-title {
  font-size: 1rem;
  margin: 0 0 5px 0;
}

.sub-header {
  font-size: 0.875rem;
  margin-bottom: $grid-gutter-width;
  color: $gray-600;
}

.card-footer {
  background-color: $white !important;
}

.card-header {
  background-color: #fafcfc;
}

.element-container {
  width: 100%;
  border-radius: 6px;
  padding: 10px 15px;

  background-color: $light;

  .form-label {
    .label-icon {
      vertical-align: middle;
      line-height: 0.8rem;
    }
  }

  .subquestion {
    font-weight: 500;
  }

  transition: color 0.2s ease;
}

.element-container:not(.cdk-drag-placeholder):hover {
  background-color: $light;
}

.element-container.active:not(.cdk-drag-placeholder) {
  background-color: $light;
  border: 2px solid $primary;
}

.popover {
  &-confirm {
    .popover-title.popover-header {
      background-color: #fff;
    }
  }
}

.popover-body {
  box-shadow: 2px 2px 10px 0px $gray-400;
}

.info-card {
  border-left: 4px solid $gray-400;
}
