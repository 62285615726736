//
// type.scss
//

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 10px 0;
  font-weight: $font-weight-bold;
  font-family: $font-family-secondary;
  color: $gray-800;
  letter-spacing: -0.015em;
}

// Font sizes (Custom)
.font-8 {
  font-size: 8px !important;
}
.font-10 {
  font-size: 10px !important;
}
.font-11 {
  font-size: 11px !important;
}
.font-12 {
  font-size: 12px !important;
}
.font-13 {
  font-size: 13px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-15 {
  font-size: 15px !important;
}

.font-16 {
  font-size: 16px !important;
}

.font-17 {
  font-size: 17px !important;
}

.font-18 {
  font-size: 18px !important;
}

.font-19 {
  font-size: 19px !important;
}

.font-20 {
  font-size: 20px !important;
}

.font-22 {
  font-size: 22px !important;
}

.font-24 {
  font-size: 24px !important;
}
.font-30 {
  font-size: 30px !important;
}
.font-35 {
  font-size: 35px !important;
}
.font-40 {
  font-size: 40px !important;
}
.font-60 {
  font-size: 60px !important;
}
.font-70 {
  font-size: 70px !important;
}
.font-80 {
  font-size: 80px !important;
}

// Font Family
.font-family-secondary {
  font-family: $font-family-secondary;
}

// Font weight

.font-weight-medium {
  font-weight: $font-weight-medium;
}

.font-weight-semibold {
  font-weight: $font-weight-semibold;
}

h5,
.h5 {
  font-size: 0.95rem;
}

.text-extra-muted {
  color: $gray-500 !important;
}

@each $color, $value in $theme-colors {
  .text-soft-#{$color} {
    color: rgba(($value), 0.25) !important;
  }
}

@each $color, $value in $theme-colors {
  .text-softer-#{$color} {
    color: rgba(($value), 0.08) !important;
  }
}

small,
.small {
  font-size: 0.8rem;
  font-weight: 400;
}

@each $name, $color in $theme-colors {
  .text-#{$name}-darker {
    color: darken($color, 15%);
  }
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-through {
  text-decoration: line-through;
}

.text-underline {
  text-decoration: underline;
}

.thick-border-right {
  border-right: 1.5px solid $text-extra-muted;
}
