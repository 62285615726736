/*
Template Name: Minton - Responsive Bootstrap 4 Admin Dashboard
Author: CoderThemes
Version: 4.0.0
Website: https://coderthemes.com/
Contact: support@coderthemes.com
File: Main Css File
*/

// Structure
@import 'custom/structure/general';
@import 'custom/structure/left-menu';
@import 'custom/structure/topbar';
@import 'custom/structure/page-head';
@import 'custom/structure/footer';
@import 'custom/structure/right-sidebar';
@import 'custom/structure/layouts';

//Components
@import 'custom/components/helper';
@import 'custom/components/social';
@import 'custom/components/widgets';
@import 'custom/components/custom-checkbox';
@import 'custom/components/custom-radio';
@import 'custom/components/ribbons';
@import 'custom/components/print';
@import 'custom/components/preloader';
@import 'custom/components/range';
@import 'custom/components/accordions';
@import 'custom/components/badge';
@import 'custom/components/backgrounds';
@import 'custom/components/breadcrumb';
@import 'custom/components/buttons';
@import 'custom/components/card';
@import 'custom/components/dropdown';
@import 'custom/components/forms';
@import 'custom/components/modal';
@import 'custom/components/nav';
@import 'custom/components/pagination';
@import 'custom/components/popover';
@import 'custom/components/progress';
@import 'custom/components/reboot';
@import 'custom/components/tables';
@import 'custom/components/type';
@import 'custom/components/notifications';

// Plugins
@import 'custom/plugins/waves';
@import 'custom/plugins/slimscroll';
@import 'custom/plugins/toaster';
@import 'custom/plugins/sweetalert';
@import 'custom/plugins/rangeslider';
@import 'custom/plugins/multiple-select';
@import 'custom/plugins/select2';
@import 'custom/plugins/datepicker';
@import 'custom/plugins/daterange';
@import 'custom/plugins/summernote';
@import 'custom/plugins/form-wizard';
@import 'custom/plugins/dropzone';
@import 'custom/plugins/datatable';
@import 'custom/plugins/apexcharts';
@import 'custom/plugins/carousel';
@import 'custom/plugins/ui-switch';
@import 'custom/plugins/tooltip';
@import 'custom/plugins/openlayers';
@import 'custom/plugins/archwizard';
@import 'custom/plugins/quill';

// Pages
@import 'custom/pages/components-demo';
@import 'custom/pages/error';
@import 'custom/pages/email';
@import 'custom/pages/taskboard';
@import 'custom/pages/timeline';
@import 'custom/pages/reports';

@media screen and (max-width: 767px) {
  ul {
    padding-inline-start: 20px;
  }
}

.movable:hover {
  cursor: move;
}

ul {
  list-style-type: circle;
}

.perfect-scroll {
  overflow: auto;
}
.perfect-scroll::-webkit-scrollbar:horizontal {
  height: 9px;
  margin-bottom: 4px;
}
.perfect-scroll::-webkit-scrollbar {
  width: 9px;
  margin-right: 4px;
}
.perfect-scroll::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.2);
}
.perfect-scroll-inside::-webkit-scrollbar {
  width: 9px;
  margin-right: 4px;
  transform: translateX(-9px);
}
.perfect-scroll-inside::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.2);
  transform: translateX(-9px);
}

.img-thumbnail-small {
  height: 24px;
  width: 24px;
  border-radius: 50%;

  &.not-rounded {
    border-radius: 0;
  }
}

.hidden-picker {
  visibility: hidden;
  height: 0;
  width: 0;
}

.clickable:hover {
  cursor: pointer;
}

.avatar-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: contain;
  border: 1px solid #eaeaea;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  img {
    width: 40px;
    height: 40px;
    object-fit: contain;
  }
}

.avatar-image-md {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: contain;
  border: 1px solid #eaeaea;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  img {
    width: 35px;
    height: 35x;
    object-fit: contain;
  }
}

.badge-strava {
  background-color: $strava-primary;
  color: $white;
}

.mh-100 {
  min-height: 100%;
}

.custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: transparentize($primary, 0.5);
}

.rot-90 {
  transform: rotate(90deg);
}

.rot-90-neg {
  transform: rotate(-90deg);
}

.hero-title {
  font-weight: 900 !important;
  line-height: 120% !important;
  font-size: 42px !important;
  letter-spacing: -0.015em;
}

.mini-thumbnail {
  height: 35px;
  width: 35px;
  object-fit: cover;
  border-radius: 50%;
}

.justify-content-stretch {
  justify-content: stretch;
}
