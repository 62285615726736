// style components for the report

.report-topbar {
  width: 100%;
  .logo-left {
    max-width: 300px;
    img {
      max-width: 300px;
      max-height: 60px;
      object-fit: contain;
    }
  }
  .logo-right {
    max-width: 300px;
    img {
      max-width: 300px;
      max-height: 60px;
      object-fit: contain;
    }
  }
}