//
// topbar.scss
//

// Logo
.logo {
  display: block;
  line-height: $topbar-height;
  span.logo-lg {
    display: block;
  }
  span.logo-sm {
    display: none;
  }
  .logo-lg-text-dark {
    color: $dark;
    font-weight: $font-weight-bold;
    font-size: 22px;
    text-transform: uppercase;
  }
  .logo-lg-text-light {
    color: $white;
    font-weight: $font-weight-bold;
    font-size: 22px;
    text-transform: uppercase;
  }
}

.logo-box {
  height: $topbar-height;
  width: $leftbar-width;
  float: left;
  background-color: $logo-box-background;
  transition: all 0.2s ease-out;
}

.app-tag {
  margin: 0 0 0 8px !important;
}

.breadcrumbs {
  height: 100%;
  display: flex;
  align-items: center;
  color: transparentize($color: #fff, $amount: 0.25);

  .active {
    color: #fff;
  }
}

.navbar-custom {
  background-color: $bg-topbar-dark;
  padding: 0 10px 0 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: $topbar-height;
  z-index: 100;
  box-shadow: 0px 1px 1px 0px rgba(50, 58, 70, 0.1);

  .topnav-menu {
    > li {
      float: left;
    }
    .nav-link {
      padding: 0 15px;
      color: $white;
      min-width: 32px;
      display: block;
      line-height: $topbar-height;
      text-align: center;
      max-height: $topbar-height;
    }
  }
  .dropdown.show {
    .nav-link {
      background-color: transparentize($white, 0.85);
      color: $white;
    }
  }

  /* Search */
  .app-search {
    overflow: hidden;
    height: $topbar-height;
    display: table;
    max-width: 180px;
    margin-right: 20px;

    .app-search-box {
      display: table-cell;
      vertical-align: middle;

      input::-webkit-input-placeholder {
        font-size: 0.8125rem;
        color: rgba($white, 0.5);
      }
    }
    .form-control {
      border: none;
      height: 38px;
      padding-left: 20px;
      padding-right: 0;
      color: $white;
      background-color: rgba($white, 0.15);
      box-shadow: none;
      border-radius: 30px 0 0 30px;
    }
    .input-group-append {
      margin-left: 0;
      z-index: 4;
    }

    .btn {
      background-color: rgba($white, 0.15);
      border-color: transparent;
      color: rgba($white, 0.5);
      border-radius: 0 30px 30px 0;
      box-shadow: none !important;
    }
  }

  .button-menu-mobile {
    border: none;
    color: $white;
    display: inline-block;
    height: $topbar-height;
    line-height: $topbar-height;
    width: 60px;
    background-color: transparent;
    font-size: 24px;
    cursor: pointer;
  }

  .button-menu-mobile.disable-btn {
    display: none;
  }
}

/* Notification */
.noti-scroll {
  max-height: 300px;
}

.notification-list {
  margin-left: 0;

  .noti-title {
    background-color: $white;
    padding: 15px 20px;
  }

  .noti-icon {
    font-size: 21px;
    vertical-align: middle;
  }

  .noti-icon-badge {
    display: inline-block;
    position: absolute;
    top: 16px;
    right: 2px;

    font-size: 12px;
    line-height: 12px;
    padding: 4px 6px;
  }

  .notify-item {
    padding: 12px 20px;
    display: flex;
    align-items: flex-start;

    .notify-icon {
      height: 36px;
      width: 36px;
      min-width: 36px;
      line-height: 36px;
      font-size: 18px;
      text-align: center;
      margin-right: 10px;
      border-radius: 50%;
    }

    .notify-details {
      margin-bottom: 5px;
      color: $gray-800;
      font-family: $font-family-secondary;
      overflow: hidden;

      .notify-title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      b {
        font-weight: 600;
      }
      small {
        display: block;
        white-space: normal;
      }
      span {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 13px;
      }
      .notify-message {
        margin-bottom: 5px;
      }
    }

    .notify-clear {
      text-align: center;
      margin-left: 10px;
    }

    .user-msg {
      margin-left: 45px;
      white-space: normal;
      line-height: 16px;
    }
  }
  .profile-dropdown {
    .notify-item {
      padding: 7px 20px;

      .badge {
        margin-top: 4px;
      }
    }
  }
}

.profile-dropdown {
  min-width: 170px;

  .dropdown-item {
    padding: 0.6rem 1.2rem;
  }
}

.nav-user {
  // padding: 0 4px 0 15px !important;
  margin-right: -15px !important;

  img {
    height: 32px;
    width: 32px;
  }
}

// Topbar light
.topbar-light {
  .navbar-custom {
    background-color: $bg-topbar-light !important;
    border-bottom: 1px solid $gray-100;

    .topnav-menu {
      .nav-link {
        color: $gray-800;
      }
    }
    .dropdown.show {
      .nav-link {
        background-color: rgba($dark, 0.03);
      }
    }

    .button-menu-mobile {
      color: $dark;
    }

    /* Search */
    .app-search {
      input::-webkit-input-placeholder {
        color: $gray-500 !important;
      }
      .form-control {
        color: $dark;
        background-color: $gray-100;
        border-color: $gray-100;
      }
      .btn {
        background-color: $gray-100;
        color: $gray-400;
      }
    }
  }
}

// .dropdown-item:not(.noti-title):hover {
//   background-color: rgba($secondary, 0.1);
// }

.dropdown-item.noti-title:hover {
  background-color: transparent;
}

hr.bottom {
  border-top: 1px solid #3c485826;
}

.navbar-sticky {
  position: sticky;
  top: 0;
  transition: all 0.25s ease;
  z-index: 2;

  &.nav-sticky {
    box-shadow: 0 0 3px #3c485826;

    hr.bottom {
      display: none;
    }
  }

  // max-height: 74px;
}

.navbar-nav {
  .nav-item {
    margin: 0 10px;

    .nav-link {
      text-transform: uppercase;
      font-size: 13px;
      color: #3c4858;
      font-weight: 600;
      transition: all 0.5s ease;
      letter-spacing: 1px;

      &:hover,
      &.active {
        color: $primary;
      }
    }
  }
}

@media (min-width: 768px) {
  .navbar-expand-md .navbar-nav .nav-link {
    padding-top: 25px;
    padding-bottom: 25px;
    min-height: 62px;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container {
    padding-left: 12px;
    padding-right: 12px;
  }
}
