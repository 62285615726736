input[type='range']::-webkit-slider-runnable-track {
  height: 4px;
  margin-top: 5px;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  margin-top: -8px;
}

input[type='range']::-moz-range-track {
  height: 4px;
  margin-top: 8px;
}

input[type='range']::-moz-range-thumb {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  margin-top: -8px;
}

input[type='range']::-moz-range-thumb:hover {
  cursor: pointer;
}

input[type='range']:focus::-moz-range-thumb {
  background-color: darken($primary, 0.15);
}

input[type='range']:focus::-webkit-slider-thumb {
  background-color: darken($primary, 0.15);
}
