//UPDATED REMIXICON v4.3.0

@font-face {
  font-family: 'remixicon';
  src: url('../../../../fonts/remixicon.eot?t=1718271040674'); /* IE9*/
  src: url('../../../../fonts/remixicon.eot?t=1718271040674#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../../../../fonts/remixicon.woff2?t=1718271040674') format('woff2'),
    url('../../../../fonts/remixicon.woff?t=1718271040674') format('woff'),
    url('../../../../fonts/remixicon.ttf?t=1718271040674') format('truetype'),
    /* chrome, firefox, opera, Safari, Android, iOS 4.2+*/
      url('../../../../fonts/remixicon.svg?t=1718271040674#remixicon') format('svg'); /* iOS 4.1- */
}

[class^='remixicon-'],
[class*=' remixicon-'] {
  font-family: 'remixicon' !important;
  font-size: inherit;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.remixicon-24-hours-fill:before {
  content: '\ea01';
}
.remixicon-24-hours-line:before {
  content: '\ea02';
}
.remixicon-4k-fill:before {
  content: '\ea03';
}
.remixicon-4k-line:before {
  content: '\ea04';
}
.remixicon-a-b:before {
  content: '\ea05';
}
.remixicon-account-box-fill:before {
  content: '\ea06';
}
.remixicon-account-box-line:before {
  content: '\ea07';
}
.remixicon-account-circle-fill:before {
  content: '\ea08';
}
.remixicon-account-circle-line:before {
  content: '\ea09';
}
.remixicon-account-pin-box-fill:before {
  content: '\ea0a';
}
.remixicon-account-pin-box-line:before {
  content: '\ea0b';
}
.remixicon-account-pin-circle-fill:before {
  content: '\ea0c';
}
.remixicon-account-pin-circle-line:before {
  content: '\ea0d';
}
.remixicon-add-box-fill:before {
  content: '\ea0e';
}
.remixicon-add-box-line:before {
  content: '\ea0f';
}
.remixicon-add-circle-fill:before {
  content: '\ea10';
}
.remixicon-add-circle-line:before {
  content: '\ea11';
}
.remixicon-add-fill:before {
  content: '\ea12';
}
.remixicon-add-line:before {
  content: '\ea13';
}
.remixicon-admin-fill:before {
  content: '\ea14';
}
.remixicon-admin-line:before {
  content: '\ea15';
}
.remixicon-advertisement-fill:before {
  content: '\ea16';
}
.remixicon-advertisement-line:before {
  content: '\ea17';
}
.remixicon-airplay-fill:before {
  content: '\ea18';
}
.remixicon-airplay-line:before {
  content: '\ea19';
}
.remixicon-alarm-fill:before {
  content: '\ea1a';
}
.remixicon-alarm-line:before {
  content: '\ea1b';
}
.remixicon-alarm-warning-fill:before {
  content: '\ea1c';
}
.remixicon-alarm-warning-line:before {
  content: '\ea1d';
}
.remixicon-album-fill:before {
  content: '\ea1e';
}
.remixicon-album-line:before {
  content: '\ea1f';
}
.remixicon-alert-fill:before {
  content: '\ea20';
}
.remixicon-alert-line:before {
  content: '\ea21';
}
.remixicon-aliens-fill:before {
  content: '\ea22';
}
.remixicon-aliens-line:before {
  content: '\ea23';
}
.remixicon-align-bottom:before {
  content: '\ea24';
}
.remixicon-align-center:before {
  content: '\ea25';
}
.remixicon-align-justify:before {
  content: '\ea26';
}
.remixicon-align-left:before {
  content: '\ea27';
}
.remixicon-align-right:before {
  content: '\ea28';
}
.remixicon-align-top:before {
  content: '\ea29';
}
.remixicon-align-vertically:before {
  content: '\ea2a';
}
.remixicon-alipay-fill:before {
  content: '\ea2b';
}
.remixicon-alipay-line:before {
  content: '\ea2c';
}
.remixicon-amazon-fill:before {
  content: '\ea2d';
}
.remixicon-amazon-line:before {
  content: '\ea2e';
}
.remixicon-anchor-fill:before {
  content: '\ea2f';
}
.remixicon-anchor-line:before {
  content: '\ea30';
}
.remixicon-ancient-gate-fill:before {
  content: '\ea31';
}
.remixicon-ancient-gate-line:before {
  content: '\ea32';
}
.remixicon-ancient-pavilion-fill:before {
  content: '\ea33';
}
.remixicon-ancient-pavilion-line:before {
  content: '\ea34';
}
.remixicon-android-fill:before {
  content: '\ea35';
}
.remixicon-android-line:before {
  content: '\ea36';
}
.remixicon-angularjs-fill:before {
  content: '\ea37';
}
.remixicon-angularjs-line:before {
  content: '\ea38';
}
.remixicon-anticlockwise-2-fill:before {
  content: '\ea39';
}
.remixicon-anticlockwise-2-line:before {
  content: '\ea3a';
}
.remixicon-anticlockwise-fill:before {
  content: '\ea3b';
}
.remixicon-anticlockwise-line:before {
  content: '\ea3c';
}
.remixicon-app-store-fill:before {
  content: '\ea3d';
}
.remixicon-app-store-line:before {
  content: '\ea3e';
}
.remixicon-apple-fill:before {
  content: '\ea3f';
}
.remixicon-apple-line:before {
  content: '\ea40';
}
.remixicon-apps-2-fill:before {
  content: '\ea41';
}
.remixicon-apps-2-line:before {
  content: '\ea42';
}
.remixicon-apps-fill:before {
  content: '\ea43';
}
.remixicon-apps-line:before {
  content: '\ea44';
}
.remixicon-archive-drawer-fill:before {
  content: '\ea45';
}
.remixicon-archive-drawer-line:before {
  content: '\ea46';
}
.remixicon-archive-fill:before {
  content: '\ea47';
}
.remixicon-archive-line:before {
  content: '\ea48';
}
.remixicon-arrow-down-circle-fill:before {
  content: '\ea49';
}
.remixicon-arrow-down-circle-line:before {
  content: '\ea4a';
}
.remixicon-arrow-down-fill:before {
  content: '\ea4b';
}
.remixicon-arrow-down-line:before {
  content: '\ea4c';
}
.remixicon-arrow-down-s-fill:before {
  content: '\ea4d';
}
.remixicon-arrow-down-s-line:before {
  content: '\ea4e';
}
.remixicon-arrow-drop-down-fill:before {
  content: '\ea4f';
}
.remixicon-arrow-drop-down-line:before {
  content: '\ea50';
}
.remixicon-arrow-drop-left-fill:before {
  content: '\ea51';
}
.remixicon-arrow-drop-left-line:before {
  content: '\ea52';
}
.remixicon-arrow-drop-right-fill:before {
  content: '\ea53';
}
.remixicon-arrow-drop-right-line:before {
  content: '\ea54';
}
.remixicon-arrow-drop-up-fill:before {
  content: '\ea55';
}
.remixicon-arrow-drop-up-line:before {
  content: '\ea56';
}
.remixicon-arrow-go-back-fill:before {
  content: '\ea57';
}
.remixicon-arrow-go-back-line:before {
  content: '\ea58';
}
.remixicon-arrow-go-forward-fill:before {
  content: '\ea59';
}
.remixicon-arrow-go-forward-line:before {
  content: '\ea5a';
}
.remixicon-arrow-left-circle-fill:before {
  content: '\ea5b';
}
.remixicon-arrow-left-circle-line:before {
  content: '\ea5c';
}
.remixicon-arrow-left-down-fill:before {
  content: '\ea5d';
}
.remixicon-arrow-left-down-line:before {
  content: '\ea5e';
}
.remixicon-arrow-left-fill:before {
  content: '\ea5f';
}
.remixicon-arrow-left-line:before {
  content: '\ea60';
}
.remixicon-arrow-left-right-fill:before {
  content: '\ea61';
}
.remixicon-arrow-left-right-line:before {
  content: '\ea62';
}
.remixicon-arrow-left-s-fill:before {
  content: '\ea63';
}
.remixicon-arrow-left-s-line:before {
  content: '\ea64';
}
.remixicon-arrow-left-up-fill:before {
  content: '\ea65';
}
.remixicon-arrow-left-up-line:before {
  content: '\ea66';
}
.remixicon-arrow-right-circle-fill:before {
  content: '\ea67';
}
.remixicon-arrow-right-circle-line:before {
  content: '\ea68';
}
.remixicon-arrow-right-down-fill:before {
  content: '\ea69';
}
.remixicon-arrow-right-down-line:before {
  content: '\ea6a';
}
.remixicon-arrow-right-fill:before {
  content: '\ea6b';
}
.remixicon-arrow-right-line:before {
  content: '\ea6c';
}
.remixicon-arrow-right-s-fill:before {
  content: '\ea6d';
}
.remixicon-arrow-right-s-line:before {
  content: '\ea6e';
}
.remixicon-arrow-right-up-fill:before {
  content: '\ea6f';
}
.remixicon-arrow-right-up-line:before {
  content: '\ea70';
}
.remixicon-arrow-up-circle-fill:before {
  content: '\ea71';
}
.remixicon-arrow-up-circle-line:before {
  content: '\ea72';
}
.remixicon-arrow-up-down-fill:before {
  content: '\ea73';
}
.remixicon-arrow-up-down-line:before {
  content: '\ea74';
}
.remixicon-arrow-up-fill:before {
  content: '\ea75';
}
.remixicon-arrow-up-line:before {
  content: '\ea76';
}
.remixicon-arrow-up-s-fill:before {
  content: '\ea77';
}
.remixicon-arrow-up-s-line:before {
  content: '\ea78';
}
.remixicon-artboard-2-fill:before {
  content: '\ea79';
}
.remixicon-artboard-2-line:before {
  content: '\ea7a';
}
.remixicon-artboard-fill:before {
  content: '\ea7b';
}
.remixicon-artboard-line:before {
  content: '\ea7c';
}
.remixicon-article-fill:before {
  content: '\ea7d';
}
.remixicon-article-line:before {
  content: '\ea7e';
}
.remixicon-aspect-ratio-fill:before {
  content: '\ea7f';
}
.remixicon-aspect-ratio-line:before {
  content: '\ea80';
}
.remixicon-asterisk:before {
  content: '\ea81';
}
.remixicon-at-fill:before {
  content: '\ea82';
}
.remixicon-at-line:before {
  content: '\ea83';
}
.remixicon-attachment-2:before {
  content: '\ea84';
}
.remixicon-attachment-fill:before {
  content: '\ea85';
}
.remixicon-attachment-line:before {
  content: '\ea86';
}
.remixicon-auction-fill:before {
  content: '\ea87';
}
.remixicon-auction-line:before {
  content: '\ea88';
}
.remixicon-award-fill:before {
  content: '\ea89';
}
.remixicon-award-line:before {
  content: '\ea8a';
}
.remixicon-baidu-fill:before {
  content: '\ea8b';
}
.remixicon-baidu-line:before {
  content: '\ea8c';
}
.remixicon-ball-pen-fill:before {
  content: '\ea8d';
}
.remixicon-ball-pen-line:before {
  content: '\ea8e';
}
.remixicon-bank-card-2-fill:before {
  content: '\ea8f';
}
.remixicon-bank-card-2-line:before {
  content: '\ea90';
}
.remixicon-bank-card-fill:before {
  content: '\ea91';
}
.remixicon-bank-card-line:before {
  content: '\ea92';
}
.remixicon-bank-fill:before {
  content: '\ea93';
}
.remixicon-bank-line:before {
  content: '\ea94';
}
.remixicon-bar-chart-2-fill:before {
  content: '\ea95';
}
.remixicon-bar-chart-2-line:before {
  content: '\ea96';
}
.remixicon-bar-chart-box-fill:before {
  content: '\ea97';
}
.remixicon-bar-chart-box-line:before {
  content: '\ea98';
}
.remixicon-bar-chart-fill:before {
  content: '\ea99';
}
.remixicon-bar-chart-grouped-fill:before {
  content: '\ea9a';
}
.remixicon-bar-chart-grouped-line:before {
  content: '\ea9b';
}
.remixicon-bar-chart-horizontal-fill:before {
  content: '\ea9c';
}
.remixicon-bar-chart-horizontal-line:before {
  content: '\ea9d';
}
.remixicon-bar-chart-line:before {
  content: '\ea9e';
}
.remixicon-barcode-box-fill:before {
  content: '\ea9f';
}
.remixicon-barcode-box-line:before {
  content: '\eaa0';
}
.remixicon-barcode-fill:before {
  content: '\eaa1';
}
.remixicon-barcode-line:before {
  content: '\eaa2';
}
.remixicon-barricade-fill:before {
  content: '\eaa3';
}
.remixicon-barricade-line:before {
  content: '\eaa4';
}
.remixicon-base-station-fill:before {
  content: '\eaa5';
}
.remixicon-base-station-line:before {
  content: '\eaa6';
}
.remixicon-basketball-fill:before {
  content: '\eaa7';
}
.remixicon-basketball-line:before {
  content: '\eaa8';
}
.remixicon-battery-2-charge-fill:before {
  content: '\eaa9';
}
.remixicon-battery-2-charge-line:before {
  content: '\eaaa';
}
.remixicon-battery-2-fill:before {
  content: '\eaab';
}
.remixicon-battery-2-line:before {
  content: '\eaac';
}
.remixicon-battery-charge-fill:before {
  content: '\eaad';
}
.remixicon-battery-charge-line:before {
  content: '\eaae';
}
.remixicon-battery-fill:before {
  content: '\eaaf';
}
.remixicon-battery-line:before {
  content: '\eab0';
}
.remixicon-battery-low-fill:before {
  content: '\eab1';
}
.remixicon-battery-low-line:before {
  content: '\eab2';
}
.remixicon-battery-saver-fill:before {
  content: '\eab3';
}
.remixicon-battery-saver-line:before {
  content: '\eab4';
}
.remixicon-battery-share-fill:before {
  content: '\eab5';
}
.remixicon-battery-share-line:before {
  content: '\eab6';
}
.remixicon-bear-smile-fill:before {
  content: '\eab7';
}
.remixicon-bear-smile-line:before {
  content: '\eab8';
}
.remixicon-behance-fill:before {
  content: '\eab9';
}
.remixicon-behance-line:before {
  content: '\eaba';
}
.remixicon-bell-fill:before {
  content: '\eabb';
}
.remixicon-bell-line:before {
  content: '\eabc';
}
.remixicon-bike-fill:before {
  content: '\eabd';
}
.remixicon-bike-line:before {
  content: '\eabe';
}
.remixicon-bilibili-fill:before {
  content: '\eabf';
}
.remixicon-bilibili-line:before {
  content: '\eac0';
}
.remixicon-bill-fill:before {
  content: '\eac1';
}
.remixicon-bill-line:before {
  content: '\eac2';
}
.remixicon-billiards-fill:before {
  content: '\eac3';
}
.remixicon-billiards-line:before {
  content: '\eac4';
}
.remixicon-bit-coin-fill:before {
  content: '\eac5';
}
.remixicon-bit-coin-line:before {
  content: '\eac6';
}
.remixicon-blaze-fill:before {
  content: '\eac7';
}
.remixicon-blaze-line:before {
  content: '\eac8';
}
.remixicon-bluetooth-connect-fill:before {
  content: '\eac9';
}
.remixicon-bluetooth-connect-line:before {
  content: '\eaca';
}
.remixicon-bluetooth-fill:before {
  content: '\eacb';
}
.remixicon-bluetooth-line:before {
  content: '\eacc';
}
.remixicon-blur-off-fill:before {
  content: '\eacd';
}
.remixicon-blur-off-line:before {
  content: '\eace';
}
.remixicon-body-scan-fill:before {
  content: '\eacf';
}
.remixicon-body-scan-line:before {
  content: '\ead0';
}
.remixicon-bold:before {
  content: '\ead1';
}
.remixicon-book-2-fill:before {
  content: '\ead2';
}
.remixicon-book-2-line:before {
  content: '\ead3';
}
.remixicon-book-3-fill:before {
  content: '\ead4';
}
.remixicon-book-3-line:before {
  content: '\ead5';
}
.remixicon-book-fill:before {
  content: '\ead6';
}
.remixicon-book-line:before {
  content: '\ead7';
}
.remixicon-book-marked-fill:before {
  content: '\ead8';
}
.remixicon-book-marked-line:before {
  content: '\ead9';
}
.remixicon-book-open-fill:before {
  content: '\eada';
}
.remixicon-book-open-line:before {
  content: '\eadb';
}
.remixicon-book-read-fill:before {
  content: '\eadc';
}
.remixicon-book-read-line:before {
  content: '\eadd';
}
.remixicon-booklet-fill:before {
  content: '\eade';
}
.remixicon-booklet-line:before {
  content: '\eadf';
}
.remixicon-bookmark-2-fill:before {
  content: '\eae0';
}
.remixicon-bookmark-2-line:before {
  content: '\eae1';
}
.remixicon-bookmark-3-fill:before {
  content: '\eae2';
}
.remixicon-bookmark-3-line:before {
  content: '\eae3';
}
.remixicon-bookmark-fill:before {
  content: '\eae4';
}
.remixicon-bookmark-line:before {
  content: '\eae5';
}
.remixicon-boxing-fill:before {
  content: '\eae6';
}
.remixicon-boxing-line:before {
  content: '\eae7';
}
.remixicon-braces-fill:before {
  content: '\eae8';
}
.remixicon-braces-line:before {
  content: '\eae9';
}
.remixicon-brackets-fill:before {
  content: '\eaea';
}
.remixicon-brackets-line:before {
  content: '\eaeb';
}
.remixicon-briefcase-2-fill:before {
  content: '\eaec';
}
.remixicon-briefcase-2-line:before {
  content: '\eaed';
}
.remixicon-briefcase-3-fill:before {
  content: '\eaee';
}
.remixicon-briefcase-3-line:before {
  content: '\eaef';
}
.remixicon-briefcase-4-fill:before {
  content: '\eaf0';
}
.remixicon-briefcase-4-line:before {
  content: '\eaf1';
}
.remixicon-briefcase-5-fill:before {
  content: '\eaf2';
}
.remixicon-briefcase-5-line:before {
  content: '\eaf3';
}
.remixicon-briefcase-fill:before {
  content: '\eaf4';
}
.remixicon-briefcase-line:before {
  content: '\eaf5';
}
.remixicon-bring-forward:before {
  content: '\eaf6';
}
.remixicon-bring-to-front:before {
  content: '\eaf7';
}
.remixicon-broadcast-fill:before {
  content: '\eaf8';
}
.remixicon-broadcast-line:before {
  content: '\eaf9';
}
.remixicon-brush-2-fill:before {
  content: '\eafa';
}
.remixicon-brush-2-line:before {
  content: '\eafb';
}
.remixicon-brush-3-fill:before {
  content: '\eafc';
}
.remixicon-brush-3-line:before {
  content: '\eafd';
}
.remixicon-brush-4-fill:before {
  content: '\eafe';
}
.remixicon-brush-4-line:before {
  content: '\eaff';
}
.remixicon-brush-fill:before {
  content: '\eb00';
}
.remixicon-brush-line:before {
  content: '\eb01';
}
.remixicon-bubble-chart-fill:before {
  content: '\eb02';
}
.remixicon-bubble-chart-line:before {
  content: '\eb03';
}
.remixicon-bug-2-fill:before {
  content: '\eb04';
}
.remixicon-bug-2-line:before {
  content: '\eb05';
}
.remixicon-bug-fill:before {
  content: '\eb06';
}
.remixicon-bug-line:before {
  content: '\eb07';
}
.remixicon-building-2-fill:before {
  content: '\eb08';
}
.remixicon-building-2-line:before {
  content: '\eb09';
}
.remixicon-building-3-fill:before {
  content: '\eb0a';
}
.remixicon-building-3-line:before {
  content: '\eb0b';
}
.remixicon-building-4-fill:before {
  content: '\eb0c';
}
.remixicon-building-4-line:before {
  content: '\eb0d';
}
.remixicon-building-fill:before {
  content: '\eb0e';
}
.remixicon-building-line:before {
  content: '\eb0f';
}
.remixicon-bus-2-fill:before {
  content: '\eb10';
}
.remixicon-bus-2-line:before {
  content: '\eb11';
}
.remixicon-bus-fill:before {
  content: '\eb12';
}
.remixicon-bus-line:before {
  content: '\eb13';
}
.remixicon-bus-wifi-fill:before {
  content: '\eb14';
}
.remixicon-bus-wifi-line:before {
  content: '\eb15';
}
.remixicon-cactus-fill:before {
  content: '\eb16';
}
.remixicon-cactus-line:before {
  content: '\eb17';
}
.remixicon-cake-2-fill:before {
  content: '\eb18';
}
.remixicon-cake-2-line:before {
  content: '\eb19';
}
.remixicon-cake-3-fill:before {
  content: '\eb1a';
}
.remixicon-cake-3-line:before {
  content: '\eb1b';
}
.remixicon-cake-fill:before {
  content: '\eb1c';
}
.remixicon-cake-line:before {
  content: '\eb1d';
}
.remixicon-calculator-fill:before {
  content: '\eb1e';
}
.remixicon-calculator-line:before {
  content: '\eb1f';
}
.remixicon-calendar-2-fill:before {
  content: '\eb20';
}
.remixicon-calendar-2-line:before {
  content: '\eb21';
}
.remixicon-calendar-check-fill:before {
  content: '\eb22';
}
.remixicon-calendar-check-line:before {
  content: '\eb23';
}
.remixicon-calendar-event-fill:before {
  content: '\eb24';
}
.remixicon-calendar-event-line:before {
  content: '\eb25';
}
.remixicon-calendar-fill:before {
  content: '\eb26';
}
.remixicon-calendar-line:before {
  content: '\eb27';
}
.remixicon-calendar-todo-fill:before {
  content: '\eb28';
}
.remixicon-calendar-todo-line:before {
  content: '\eb29';
}
.remixicon-camera-2-fill:before {
  content: '\eb2a';
}
.remixicon-camera-2-line:before {
  content: '\eb2b';
}
.remixicon-camera-3-fill:before {
  content: '\eb2c';
}
.remixicon-camera-3-line:before {
  content: '\eb2d';
}
.remixicon-camera-fill:before {
  content: '\eb2e';
}
.remixicon-camera-lens-fill:before {
  content: '\eb2f';
}
.remixicon-camera-lens-line:before {
  content: '\eb30';
}
.remixicon-camera-line:before {
  content: '\eb31';
}
.remixicon-camera-off-fill:before {
  content: '\eb32';
}
.remixicon-camera-off-line:before {
  content: '\eb33';
}
.remixicon-camera-switch-fill:before {
  content: '\eb34';
}
.remixicon-camera-switch-line:before {
  content: '\eb35';
}
.remixicon-capsule-fill:before {
  content: '\eb36';
}
.remixicon-capsule-line:before {
  content: '\eb37';
}
.remixicon-car-fill:before {
  content: '\eb38';
}
.remixicon-car-line:before {
  content: '\eb39';
}
.remixicon-car-washing-fill:before {
  content: '\eb3a';
}
.remixicon-car-washing-line:before {
  content: '\eb3b';
}
.remixicon-caravan-fill:before {
  content: '\eb3c';
}
.remixicon-caravan-line:before {
  content: '\eb3d';
}
.remixicon-cast-fill:before {
  content: '\eb3e';
}
.remixicon-cast-line:before {
  content: '\eb3f';
}
.remixicon-cellphone-fill:before {
  content: '\eb40';
}
.remixicon-cellphone-line:before {
  content: '\eb41';
}
.remixicon-celsius-fill:before {
  content: '\eb42';
}
.remixicon-celsius-line:before {
  content: '\eb43';
}
.remixicon-centos-fill:before {
  content: '\eb44';
}
.remixicon-centos-line:before {
  content: '\eb45';
}
.remixicon-character-recognition-fill:before {
  content: '\eb46';
}
.remixicon-character-recognition-line:before {
  content: '\eb47';
}
.remixicon-charging-pile-2-fill:before {
  content: '\eb48';
}
.remixicon-charging-pile-2-line:before {
  content: '\eb49';
}
.remixicon-charging-pile-fill:before {
  content: '\eb4a';
}
.remixicon-charging-pile-line:before {
  content: '\eb4b';
}
.remixicon-chat-1-fill:before {
  content: '\eb4c';
}
.remixicon-chat-1-line:before {
  content: '\eb4d';
}
.remixicon-chat-2-fill:before {
  content: '\eb4e';
}
.remixicon-chat-2-line:before {
  content: '\eb4f';
}
.remixicon-chat-3-fill:before {
  content: '\eb50';
}
.remixicon-chat-3-line:before {
  content: '\eb51';
}
.remixicon-chat-4-fill:before {
  content: '\eb52';
}
.remixicon-chat-4-line:before {
  content: '\eb53';
}
.remixicon-chat-check-fill:before {
  content: '\eb54';
}
.remixicon-chat-check-line:before {
  content: '\eb55';
}
.remixicon-chat-delete-fill:before {
  content: '\eb56';
}
.remixicon-chat-delete-line:before {
  content: '\eb57';
}
.remixicon-chat-download-fill:before {
  content: '\eb58';
}
.remixicon-chat-download-line:before {
  content: '\eb59';
}
.remixicon-chat-follow-up-fill:before {
  content: '\eb5a';
}
.remixicon-chat-follow-up-line:before {
  content: '\eb5b';
}
.remixicon-chat-forward-fill:before {
  content: '\eb5c';
}
.remixicon-chat-forward-line:before {
  content: '\eb5d';
}
.remixicon-chat-heart-fill:before {
  content: '\eb5e';
}
.remixicon-chat-heart-line:before {
  content: '\eb5f';
}
.remixicon-chat-history-fill:before {
  content: '\eb60';
}
.remixicon-chat-history-line:before {
  content: '\eb61';
}
.remixicon-chat-new-fill:before {
  content: '\eb62';
}
.remixicon-chat-new-line:before {
  content: '\eb63';
}
.remixicon-chat-off-fill:before {
  content: '\eb64';
}
.remixicon-chat-off-line:before {
  content: '\eb65';
}
.remixicon-chat-poll-fill:before {
  content: '\eb66';
}
.remixicon-chat-poll-line:before {
  content: '\eb67';
}
.remixicon-chat-private-fill:before {
  content: '\eb68';
}
.remixicon-chat-private-line:before {
  content: '\eb69';
}
.remixicon-chat-quote-fill:before {
  content: '\eb6a';
}
.remixicon-chat-quote-line:before {
  content: '\eb6b';
}
.remixicon-chat-settings-fill:before {
  content: '\eb6c';
}
.remixicon-chat-settings-line:before {
  content: '\eb6d';
}
.remixicon-chat-smile-2-fill:before {
  content: '\eb6e';
}
.remixicon-chat-smile-2-line:before {
  content: '\eb6f';
}
.remixicon-chat-smile-3-fill:before {
  content: '\eb70';
}
.remixicon-chat-smile-3-line:before {
  content: '\eb71';
}
.remixicon-chat-smile-fill:before {
  content: '\eb72';
}
.remixicon-chat-smile-line:before {
  content: '\eb73';
}
.remixicon-chat-upload-fill:before {
  content: '\eb74';
}
.remixicon-chat-upload-line:before {
  content: '\eb75';
}
.remixicon-chat-voice-fill:before {
  content: '\eb76';
}
.remixicon-chat-voice-line:before {
  content: '\eb77';
}
.remixicon-check-double-fill:before {
  content: '\eb78';
}
.remixicon-check-double-line:before {
  content: '\eb79';
}
.remixicon-check-fill:before {
  content: '\eb7a';
}
.remixicon-check-line:before {
  content: '\eb7b';
}
.remixicon-checkbox-blank-circle-fill:before {
  content: '\eb7c';
}
.remixicon-checkbox-blank-circle-line:before {
  content: '\eb7d';
}
.remixicon-checkbox-blank-fill:before {
  content: '\eb7e';
}
.remixicon-checkbox-blank-line:before {
  content: '\eb7f';
}
.remixicon-checkbox-circle-fill:before {
  content: '\eb80';
}
.remixicon-checkbox-circle-line:before {
  content: '\eb81';
}
.remixicon-checkbox-fill:before {
  content: '\eb82';
}
.remixicon-checkbox-indeterminate-fill:before {
  content: '\eb83';
}
.remixicon-checkbox-indeterminate-line:before {
  content: '\eb84';
}
.remixicon-checkbox-line:before {
  content: '\eb85';
}
.remixicon-checkbox-multiple-blank-fill:before {
  content: '\eb86';
}
.remixicon-checkbox-multiple-blank-line:before {
  content: '\eb87';
}
.remixicon-checkbox-multiple-fill:before {
  content: '\eb88';
}
.remixicon-checkbox-multiple-line:before {
  content: '\eb89';
}
.remixicon-china-railway-fill:before {
  content: '\eb8a';
}
.remixicon-china-railway-line:before {
  content: '\eb8b';
}
.remixicon-chrome-fill:before {
  content: '\eb8c';
}
.remixicon-chrome-line:before {
  content: '\eb8d';
}
.remixicon-clapperboard-fill:before {
  content: '\eb8e';
}
.remixicon-clapperboard-line:before {
  content: '\eb8f';
}
.remixicon-clipboard-fill:before {
  content: '\eb90';
}
.remixicon-clipboard-line:before {
  content: '\eb91';
}
.remixicon-clockwise-2-fill:before {
  content: '\eb92';
}
.remixicon-clockwise-2-line:before {
  content: '\eb93';
}
.remixicon-clockwise-fill:before {
  content: '\eb94';
}
.remixicon-clockwise-line:before {
  content: '\eb95';
}
.remixicon-close-circle-fill:before {
  content: '\eb96';
}
.remixicon-close-circle-line:before {
  content: '\eb97';
}
.remixicon-close-fill:before {
  content: '\eb98';
}
.remixicon-close-line:before {
  content: '\eb99';
}
.remixicon-closed-captioning-fill:before {
  content: '\eb9a';
}
.remixicon-closed-captioning-line:before {
  content: '\eb9b';
}
.remixicon-cloud-fill:before {
  content: '\eb9c';
}
.remixicon-cloud-line:before {
  content: '\eb9d';
}
.remixicon-cloud-off-fill:before {
  content: '\eb9e';
}
.remixicon-cloud-off-line:before {
  content: '\eb9f';
}
.remixicon-cloud-windy-fill:before {
  content: '\eba0';
}
.remixicon-cloud-windy-line:before {
  content: '\eba1';
}
.remixicon-cloudy-2-fill:before {
  content: '\eba2';
}
.remixicon-cloudy-2-line:before {
  content: '\eba3';
}
.remixicon-cloudy-fill:before {
  content: '\eba4';
}
.remixicon-cloudy-line:before {
  content: '\eba5';
}
.remixicon-code-box-fill:before {
  content: '\eba6';
}
.remixicon-code-box-line:before {
  content: '\eba7';
}
.remixicon-code-fill:before {
  content: '\eba8';
}
.remixicon-code-line:before {
  content: '\eba9';
}
.remixicon-code-s-fill:before {
  content: '\ebaa';
}
.remixicon-code-s-line:before {
  content: '\ebab';
}
.remixicon-code-s-slash-fill:before {
  content: '\ebac';
}
.remixicon-code-s-slash-line:before {
  content: '\ebad';
}
.remixicon-code-view:before {
  content: '\ebae';
}
.remixicon-codepen-fill:before {
  content: '\ebaf';
}
.remixicon-codepen-line:before {
  content: '\ebb0';
}
.remixicon-coin-fill:before {
  content: '\ebb1';
}
.remixicon-coin-line:before {
  content: '\ebb2';
}
.remixicon-coins-fill:before {
  content: '\ebb3';
}
.remixicon-coins-line:before {
  content: '\ebb4';
}
.remixicon-collage-fill:before {
  content: '\ebb5';
}
.remixicon-collage-line:before {
  content: '\ebb6';
}
.remixicon-command-fill:before {
  content: '\ebb7';
}
.remixicon-command-line:before {
  content: '\ebb8';
}
.remixicon-community-fill:before {
  content: '\ebb9';
}
.remixicon-community-line:before {
  content: '\ebba';
}
.remixicon-compass-2-fill:before {
  content: '\ebbb';
}
.remixicon-compass-2-line:before {
  content: '\ebbc';
}
.remixicon-compass-3-fill:before {
  content: '\ebbd';
}
.remixicon-compass-3-line:before {
  content: '\ebbe';
}
.remixicon-compass-4-fill:before {
  content: '\ebbf';
}
.remixicon-compass-4-line:before {
  content: '\ebc0';
}
.remixicon-compass-discover-fill:before {
  content: '\ebc1';
}
.remixicon-compass-discover-line:before {
  content: '\ebc2';
}
.remixicon-compass-fill:before {
  content: '\ebc3';
}
.remixicon-compass-line:before {
  content: '\ebc4';
}
.remixicon-compasses-2-fill:before {
  content: '\ebc5';
}
.remixicon-compasses-2-line:before {
  content: '\ebc6';
}
.remixicon-compasses-fill:before {
  content: '\ebc7';
}
.remixicon-compasses-line:before {
  content: '\ebc8';
}
.remixicon-computer-fill:before {
  content: '\ebc9';
}
.remixicon-computer-line:before {
  content: '\ebca';
}
.remixicon-contacts-book-2-fill:before {
  content: '\ebcb';
}
.remixicon-contacts-book-2-line:before {
  content: '\ebcc';
}
.remixicon-contacts-book-fill:before {
  content: '\ebcd';
}
.remixicon-contacts-book-line:before {
  content: '\ebce';
}
.remixicon-contacts-book-upload-fill:before {
  content: '\ebcf';
}
.remixicon-contacts-book-upload-line:before {
  content: '\ebd0';
}
.remixicon-contacts-fill:before {
  content: '\ebd1';
}
.remixicon-contacts-line:before {
  content: '\ebd2';
}
.remixicon-contrast-2-fill:before {
  content: '\ebd3';
}
.remixicon-contrast-2-line:before {
  content: '\ebd4';
}
.remixicon-contrast-drop-2-fill:before {
  content: '\ebd5';
}
.remixicon-contrast-drop-2-line:before {
  content: '\ebd6';
}
.remixicon-contrast-drop-fill:before {
  content: '\ebd7';
}
.remixicon-contrast-drop-line:before {
  content: '\ebd8';
}
.remixicon-contrast-fill:before {
  content: '\ebd9';
}
.remixicon-contrast-line:before {
  content: '\ebda';
}
.remixicon-copper-coin-fill:before {
  content: '\ebdb';
}
.remixicon-copper-coin-line:before {
  content: '\ebdc';
}
.remixicon-copper-diamond-fill:before {
  content: '\ebdd';
}
.remixicon-copper-diamond-line:before {
  content: '\ebde';
}
.remixicon-copyleft-fill:before {
  content: '\ebdf';
}
.remixicon-copyleft-line:before {
  content: '\ebe0';
}
.remixicon-copyright-fill:before {
  content: '\ebe1';
}
.remixicon-copyright-line:before {
  content: '\ebe2';
}
.remixicon-coreos-fill:before {
  content: '\ebe3';
}
.remixicon-coreos-line:before {
  content: '\ebe4';
}
.remixicon-coupon-2-fill:before {
  content: '\ebe5';
}
.remixicon-coupon-2-line:before {
  content: '\ebe6';
}
.remixicon-coupon-3-fill:before {
  content: '\ebe7';
}
.remixicon-coupon-3-line:before {
  content: '\ebe8';
}
.remixicon-coupon-4-fill:before {
  content: '\ebe9';
}
.remixicon-coupon-4-line:before {
  content: '\ebea';
}
.remixicon-coupon-5-fill:before {
  content: '\ebeb';
}
.remixicon-coupon-5-line:before {
  content: '\ebec';
}
.remixicon-coupon-fill:before {
  content: '\ebed';
}
.remixicon-coupon-line:before {
  content: '\ebee';
}
.remixicon-cpu-fill:before {
  content: '\ebef';
}
.remixicon-cpu-line:before {
  content: '\ebf0';
}
.remixicon-creative-commons-by-fill:before {
  content: '\ebf1';
}
.remixicon-creative-commons-by-line:before {
  content: '\ebf2';
}
.remixicon-creative-commons-fill:before {
  content: '\ebf3';
}
.remixicon-creative-commons-line:before {
  content: '\ebf4';
}
.remixicon-creative-commons-nc-fill:before {
  content: '\ebf5';
}
.remixicon-creative-commons-nc-line:before {
  content: '\ebf6';
}
.remixicon-creative-commons-nd-fill:before {
  content: '\ebf7';
}
.remixicon-creative-commons-nd-line:before {
  content: '\ebf8';
}
.remixicon-creative-commons-sa-fill:before {
  content: '\ebf9';
}
.remixicon-creative-commons-sa-line:before {
  content: '\ebfa';
}
.remixicon-creative-commons-zero-fill:before {
  content: '\ebfb';
}
.remixicon-creative-commons-zero-line:before {
  content: '\ebfc';
}
.remixicon-criminal-fill:before {
  content: '\ebfd';
}
.remixicon-criminal-line:before {
  content: '\ebfe';
}
.remixicon-crop-2-fill:before {
  content: '\ebff';
}
.remixicon-crop-2-line:before {
  content: '\ec00';
}
.remixicon-crop-fill:before {
  content: '\ec01';
}
.remixicon-crop-line:before {
  content: '\ec02';
}
.remixicon-css3-fill:before {
  content: '\ec03';
}
.remixicon-css3-line:before {
  content: '\ec04';
}
.remixicon-cup-fill:before {
  content: '\ec05';
}
.remixicon-cup-line:before {
  content: '\ec06';
}
.remixicon-currency-fill:before {
  content: '\ec07';
}
.remixicon-currency-line:before {
  content: '\ec08';
}
.remixicon-cursor-fill:before {
  content: '\ec09';
}
.remixicon-cursor-line:before {
  content: '\ec0a';
}
.remixicon-customer-service-2-fill:before {
  content: '\ec0b';
}
.remixicon-customer-service-2-line:before {
  content: '\ec0c';
}
.remixicon-customer-service-fill:before {
  content: '\ec0d';
}
.remixicon-customer-service-line:before {
  content: '\ec0e';
}
.remixicon-dashboard-2-fill:before {
  content: '\ec0f';
}
.remixicon-dashboard-2-line:before {
  content: '\ec10';
}
.remixicon-dashboard-3-fill:before {
  content: '\ec11';
}
.remixicon-dashboard-3-line:before {
  content: '\ec12';
}
.remixicon-dashboard-fill:before {
  content: '\ec13';
}
.remixicon-dashboard-line:before {
  content: '\ec14';
}
.remixicon-database-2-fill:before {
  content: '\ec15';
}
.remixicon-database-2-line:before {
  content: '\ec16';
}
.remixicon-database-fill:before {
  content: '\ec17';
}
.remixicon-database-line:before {
  content: '\ec18';
}
.remixicon-delete-back-2-fill:before {
  content: '\ec19';
}
.remixicon-delete-back-2-line:before {
  content: '\ec1a';
}
.remixicon-delete-back-fill:before {
  content: '\ec1b';
}
.remixicon-delete-back-line:before {
  content: '\ec1c';
}
.remixicon-delete-bin-2-fill:before {
  content: '\ec1d';
}
.remixicon-delete-bin-2-line:before {
  content: '\ec1e';
}
.remixicon-delete-bin-3-fill:before {
  content: '\ec1f';
}
.remixicon-delete-bin-3-line:before {
  content: '\ec20';
}
.remixicon-delete-bin-4-fill:before {
  content: '\ec21';
}
.remixicon-delete-bin-4-line:before {
  content: '\ec22';
}
.remixicon-delete-bin-5-fill:before {
  content: '\ec23';
}
.remixicon-delete-bin-5-line:before {
  content: '\ec24';
}
.remixicon-delete-bin-6-fill:before {
  content: '\ec25';
}
.remixicon-delete-bin-6-line:before {
  content: '\ec26';
}
.remixicon-delete-bin-7-fill:before {
  content: '\ec27';
}
.remixicon-delete-bin-7-line:before {
  content: '\ec28';
}
.remixicon-delete-bin-fill:before {
  content: '\ec29';
}
.remixicon-delete-bin-line:before {
  content: '\ec2a';
}
.remixicon-delete-column:before {
  content: '\ec2b';
}
.remixicon-delete-row:before {
  content: '\ec2c';
}
.remixicon-device-fill:before {
  content: '\ec2d';
}
.remixicon-device-line:before {
  content: '\ec2e';
}
.remixicon-device-recover-fill:before {
  content: '\ec2f';
}
.remixicon-device-recover-line:before {
  content: '\ec30';
}
.remixicon-dingding-fill:before {
  content: '\ec31';
}
.remixicon-dingding-line:before {
  content: '\ec32';
}
.remixicon-direction-fill:before {
  content: '\ec33';
}
.remixicon-direction-line:before {
  content: '\ec34';
}
.remixicon-disc-fill:before {
  content: '\ec35';
}
.remixicon-disc-line:before {
  content: '\ec36';
}
.remixicon-discord-fill:before {
  content: '\ec37';
}
.remixicon-discord-line:before {
  content: '\ec38';
}
.remixicon-discuss-fill:before {
  content: '\ec39';
}
.remixicon-discuss-line:before {
  content: '\ec3a';
}
.remixicon-dislike-fill:before {
  content: '\ec3b';
}
.remixicon-dislike-line:before {
  content: '\ec3c';
}
.remixicon-disqus-fill:before {
  content: '\ec3d';
}
.remixicon-disqus-line:before {
  content: '\ec3e';
}
.remixicon-divide-fill:before {
  content: '\ec3f';
}
.remixicon-divide-line:before {
  content: '\ec40';
}
.remixicon-donut-chart-fill:before {
  content: '\ec41';
}
.remixicon-donut-chart-line:before {
  content: '\ec42';
}
.remixicon-door-closed-fill:before {
  content: '\ec43';
}
.remixicon-door-closed-line:before {
  content: '\ec44';
}
.remixicon-door-fill:before {
  content: '\ec45';
}
.remixicon-door-line:before {
  content: '\ec46';
}
.remixicon-door-lock-box-fill:before {
  content: '\ec47';
}
.remixicon-door-lock-box-line:before {
  content: '\ec48';
}
.remixicon-door-lock-fill:before {
  content: '\ec49';
}
.remixicon-door-lock-line:before {
  content: '\ec4a';
}
.remixicon-door-open-fill:before {
  content: '\ec4b';
}
.remixicon-door-open-line:before {
  content: '\ec4c';
}
.remixicon-dossier-fill:before {
  content: '\ec4d';
}
.remixicon-dossier-line:before {
  content: '\ec4e';
}
.remixicon-douban-fill:before {
  content: '\ec4f';
}
.remixicon-douban-line:before {
  content: '\ec50';
}
.remixicon-double-quotes-l:before {
  content: '\ec51';
}
.remixicon-double-quotes-r:before {
  content: '\ec52';
}
.remixicon-download-2-fill:before {
  content: '\ec53';
}
.remixicon-download-2-line:before {
  content: '\ec54';
}
.remixicon-download-cloud-2-fill:before {
  content: '\ec55';
}
.remixicon-download-cloud-2-line:before {
  content: '\ec56';
}
.remixicon-download-cloud-fill:before {
  content: '\ec57';
}
.remixicon-download-cloud-line:before {
  content: '\ec58';
}
.remixicon-download-fill:before {
  content: '\ec59';
}
.remixicon-download-line:before {
  content: '\ec5a';
}
.remixicon-draft-fill:before {
  content: '\ec5b';
}
.remixicon-draft-line:before {
  content: '\ec5c';
}
.remixicon-drag-drop-fill:before {
  content: '\ec5d';
}
.remixicon-drag-drop-line:before {
  content: '\ec5e';
}
.remixicon-drag-move-2-fill:before {
  content: '\ec5f';
}
.remixicon-drag-move-2-line:before {
  content: '\ec60';
}
.remixicon-drag-move-fill:before {
  content: '\ec61';
}
.remixicon-drag-move-line:before {
  content: '\ec62';
}
.remixicon-dribbble-fill:before {
  content: '\ec63';
}
.remixicon-dribbble-line:before {
  content: '\ec64';
}
.remixicon-drive-fill:before {
  content: '\ec65';
}
.remixicon-drive-line:before {
  content: '\ec66';
}
.remixicon-drizzle-fill:before {
  content: '\ec67';
}
.remixicon-drizzle-line:before {
  content: '\ec68';
}
.remixicon-drop-fill:before {
  content: '\ec69';
}
.remixicon-drop-line:before {
  content: '\ec6a';
}
.remixicon-dropbox-fill:before {
  content: '\ec6b';
}
.remixicon-dropbox-line:before {
  content: '\ec6c';
}
.remixicon-dual-sim-1-fill:before {
  content: '\ec6d';
}
.remixicon-dual-sim-1-line:before {
  content: '\ec6e';
}
.remixicon-dual-sim-2-fill:before {
  content: '\ec6f';
}
.remixicon-dual-sim-2-line:before {
  content: '\ec70';
}
.remixicon-dv-fill:before {
  content: '\ec71';
}
.remixicon-dv-line:before {
  content: '\ec72';
}
.remixicon-dvd-fill:before {
  content: '\ec73';
}
.remixicon-dvd-line:before {
  content: '\ec74';
}
.remixicon-e-bike-2-fill:before {
  content: '\ec75';
}
.remixicon-e-bike-2-line:before {
  content: '\ec76';
}
.remixicon-e-bike-fill:before {
  content: '\ec77';
}
.remixicon-e-bike-line:before {
  content: '\ec78';
}
.remixicon-earth-fill:before {
  content: '\ec79';
}
.remixicon-earth-line:before {
  content: '\ec7a';
}
.remixicon-earthquake-fill:before {
  content: '\ec7b';
}
.remixicon-earthquake-line:before {
  content: '\ec7c';
}
.remixicon-edge-fill:before {
  content: '\ec7d';
}
.remixicon-edge-line:before {
  content: '\ec7e';
}
.remixicon-edit-2-fill:before {
  content: '\ec7f';
}
.remixicon-edit-2-line:before {
  content: '\ec80';
}
.remixicon-edit-box-fill:before {
  content: '\ec81';
}
.remixicon-edit-box-line:before {
  content: '\ec82';
}
.remixicon-edit-circle-fill:before {
  content: '\ec83';
}
.remixicon-edit-circle-line:before {
  content: '\ec84';
}
.remixicon-edit-fill:before {
  content: '\ec85';
}
.remixicon-edit-line:before {
  content: '\ec86';
}
.remixicon-eject-fill:before {
  content: '\ec87';
}
.remixicon-eject-line:before {
  content: '\ec88';
}
.remixicon-emotion-2-fill:before {
  content: '\ec89';
}
.remixicon-emotion-2-line:before {
  content: '\ec8a';
}
.remixicon-emotion-fill:before {
  content: '\ec8b';
}
.remixicon-emotion-happy-fill:before {
  content: '\ec8c';
}
.remixicon-emotion-happy-line:before {
  content: '\ec8d';
}
.remixicon-emotion-laugh-fill:before {
  content: '\ec8e';
}
.remixicon-emotion-laugh-line:before {
  content: '\ec8f';
}
.remixicon-emotion-line:before {
  content: '\ec90';
}
.remixicon-emotion-normal-fill:before {
  content: '\ec91';
}
.remixicon-emotion-normal-line:before {
  content: '\ec92';
}
.remixicon-emotion-sad-fill:before {
  content: '\ec93';
}
.remixicon-emotion-sad-line:before {
  content: '\ec94';
}
.remixicon-emotion-unhappy-fill:before {
  content: '\ec95';
}
.remixicon-emotion-unhappy-line:before {
  content: '\ec96';
}
.remixicon-empathize-fill:before {
  content: '\ec97';
}
.remixicon-empathize-line:before {
  content: '\ec98';
}
.remixicon-emphasis-cn:before {
  content: '\ec99';
}
.remixicon-emphasis:before {
  content: '\ec9a';
}
.remixicon-english-input:before {
  content: '\ec9b';
}
.remixicon-equalizer-fill:before {
  content: '\ec9c';
}
.remixicon-equalizer-line:before {
  content: '\ec9d';
}
.remixicon-eraser-fill:before {
  content: '\ec9e';
}
.remixicon-eraser-line:before {
  content: '\ec9f';
}
.remixicon-error-warning-fill:before {
  content: '\eca0';
}
.remixicon-error-warning-line:before {
  content: '\eca1';
}
.remixicon-evernote-fill:before {
  content: '\eca2';
}
.remixicon-evernote-line:before {
  content: '\eca3';
}
.remixicon-exchange-box-fill:before {
  content: '\eca4';
}
.remixicon-exchange-box-line:before {
  content: '\eca5';
}
.remixicon-exchange-cny-fill:before {
  content: '\eca6';
}
.remixicon-exchange-cny-line:before {
  content: '\eca7';
}
.remixicon-exchange-dollar-fill:before {
  content: '\eca8';
}
.remixicon-exchange-dollar-line:before {
  content: '\eca9';
}
.remixicon-exchange-fill:before {
  content: '\ecaa';
}
.remixicon-exchange-funds-fill:before {
  content: '\ecab';
}
.remixicon-exchange-funds-line:before {
  content: '\ecac';
}
.remixicon-exchange-line:before {
  content: '\ecad';
}
.remixicon-external-link-fill:before {
  content: '\ecae';
}
.remixicon-external-link-line:before {
  content: '\ecaf';
}
.remixicon-eye-2-fill:before {
  content: '\ecb0';
}
.remixicon-eye-2-line:before {
  content: '\ecb1';
}
.remixicon-eye-close-fill:before {
  content: '\ecb2';
}
.remixicon-eye-close-line:before {
  content: '\ecb3';
}
.remixicon-eye-fill:before {
  content: '\ecb4';
}
.remixicon-eye-line:before {
  content: '\ecb5';
}
.remixicon-eye-off-fill:before {
  content: '\ecb6';
}
.remixicon-eye-off-line:before {
  content: '\ecb7';
}
.remixicon-facebook-box-fill:before {
  content: '\ecb8';
}
.remixicon-facebook-box-line:before {
  content: '\ecb9';
}
.remixicon-facebook-circle-fill:before {
  content: '\ecba';
}
.remixicon-facebook-circle-line:before {
  content: '\ecbb';
}
.remixicon-facebook-fill:before {
  content: '\ecbc';
}
.remixicon-facebook-line:before {
  content: '\ecbd';
}
.remixicon-fahrenheit-fill:before {
  content: '\ecbe';
}
.remixicon-fahrenheit-line:before {
  content: '\ecbf';
}
.remixicon-feedback-fill:before {
  content: '\ecc0';
}
.remixicon-feedback-line:before {
  content: '\ecc1';
}
.remixicon-file-2-fill:before {
  content: '\ecc2';
}
.remixicon-file-2-line:before {
  content: '\ecc3';
}
.remixicon-file-3-fill:before {
  content: '\ecc4';
}
.remixicon-file-3-line:before {
  content: '\ecc5';
}
.remixicon-file-4-fill:before {
  content: '\ecc6';
}
.remixicon-file-4-line:before {
  content: '\ecc7';
}
.remixicon-file-add-fill:before {
  content: '\ecc8';
}
.remixicon-file-add-line:before {
  content: '\ecc9';
}
.remixicon-file-chart-2-fill:before {
  content: '\ecca';
}
.remixicon-file-chart-2-line:before {
  content: '\eccb';
}
.remixicon-file-chart-fill:before {
  content: '\eccc';
}
.remixicon-file-chart-line:before {
  content: '\eccd';
}
.remixicon-file-cloud-fill:before {
  content: '\ecce';
}
.remixicon-file-cloud-line:before {
  content: '\eccf';
}
.remixicon-file-code-fill:before {
  content: '\ecd0';
}
.remixicon-file-code-line:before {
  content: '\ecd1';
}
.remixicon-file-copy-2-fill:before {
  content: '\ecd2';
}
.remixicon-file-copy-2-line:before {
  content: '\ecd3';
}
.remixicon-file-copy-fill:before {
  content: '\ecd4';
}
.remixicon-file-copy-line:before {
  content: '\ecd5';
}
.remixicon-file-damage-fill:before {
  content: '\ecd6';
}
.remixicon-file-damage-line:before {
  content: '\ecd7';
}
.remixicon-file-download-fill:before {
  content: '\ecd8';
}
.remixicon-file-download-line:before {
  content: '\ecd9';
}
.remixicon-file-edit-fill:before {
  content: '\ecda';
}
.remixicon-file-edit-line:before {
  content: '\ecdb';
}
.remixicon-file-excel-2-fill:before {
  content: '\ecdc';
}
.remixicon-file-excel-2-line:before {
  content: '\ecdd';
}
.remixicon-file-excel-fill:before {
  content: '\ecde';
}
.remixicon-file-excel-line:before {
  content: '\ecdf';
}
.remixicon-file-fill:before {
  content: '\ece0';
}
.remixicon-file-forbid-fill:before {
  content: '\ece1';
}
.remixicon-file-forbid-line:before {
  content: '\ece2';
}
.remixicon-file-gif-fill:before {
  content: '\ece3';
}
.remixicon-file-gif-line:before {
  content: '\ece4';
}
.remixicon-file-history-fill:before {
  content: '\ece5';
}
.remixicon-file-history-line:before {
  content: '\ece6';
}
.remixicon-file-hwp-fill:before {
  content: '\ece7';
}
.remixicon-file-hwp-line:before {
  content: '\ece8';
}
.remixicon-file-info-fill:before {
  content: '\ece9';
}
.remixicon-file-info-line:before {
  content: '\ecea';
}
.remixicon-file-line:before {
  content: '\eceb';
}
.remixicon-file-list-2-fill:before {
  content: '\ecec';
}
.remixicon-file-list-2-line:before {
  content: '\eced';
}
.remixicon-file-list-3-fill:before {
  content: '\ecee';
}
.remixicon-file-list-3-line:before {
  content: '\ecef';
}
.remixicon-file-list-fill:before {
  content: '\ecf0';
}
.remixicon-file-list-line:before {
  content: '\ecf1';
}
.remixicon-file-lock-fill:before {
  content: '\ecf2';
}
.remixicon-file-lock-line:before {
  content: '\ecf3';
}
.remixicon-file-marked-fill:before {
  content: '\ecf4';
}
.remixicon-file-marked-line:before {
  content: '\ecf5';
}
.remixicon-file-music-fill:before {
  content: '\ecf6';
}
.remixicon-file-music-line:before {
  content: '\ecf7';
}
.remixicon-file-paper-2-fill:before {
  content: '\ecf8';
}
.remixicon-file-paper-2-line:before {
  content: '\ecf9';
}
.remixicon-file-paper-fill:before {
  content: '\ecfa';
}
.remixicon-file-paper-line:before {
  content: '\ecfb';
}
.remixicon-file-pdf-fill:before {
  content: '\ecfc';
}
.remixicon-file-pdf-line:before {
  content: '\ecfd';
}
.remixicon-file-ppt-2-fill:before {
  content: '\ecfe';
}
.remixicon-file-ppt-2-line:before {
  content: '\ecff';
}
.remixicon-file-ppt-fill:before {
  content: '\ed00';
}
.remixicon-file-ppt-line:before {
  content: '\ed01';
}
.remixicon-file-reduce-fill:before {
  content: '\ed02';
}
.remixicon-file-reduce-line:before {
  content: '\ed03';
}
.remixicon-file-search-fill:before {
  content: '\ed04';
}
.remixicon-file-search-line:before {
  content: '\ed05';
}
.remixicon-file-settings-fill:before {
  content: '\ed06';
}
.remixicon-file-settings-line:before {
  content: '\ed07';
}
.remixicon-file-shield-2-fill:before {
  content: '\ed08';
}
.remixicon-file-shield-2-line:before {
  content: '\ed09';
}
.remixicon-file-shield-fill:before {
  content: '\ed0a';
}
.remixicon-file-shield-line:before {
  content: '\ed0b';
}
.remixicon-file-shred-fill:before {
  content: '\ed0c';
}
.remixicon-file-shred-line:before {
  content: '\ed0d';
}
.remixicon-file-text-fill:before {
  content: '\ed0e';
}
.remixicon-file-text-line:before {
  content: '\ed0f';
}
.remixicon-file-transfer-fill:before {
  content: '\ed10';
}
.remixicon-file-transfer-line:before {
  content: '\ed11';
}
.remixicon-file-unknow-fill:before {
  content: '\ed12';
}
.remixicon-file-unknow-line:before {
  content: '\ed13';
}
.remixicon-file-upload-fill:before {
  content: '\ed14';
}
.remixicon-file-upload-line:before {
  content: '\ed15';
}
.remixicon-file-user-fill:before {
  content: '\ed16';
}
.remixicon-file-user-line:before {
  content: '\ed17';
}
.remixicon-file-warning-fill:before {
  content: '\ed18';
}
.remixicon-file-warning-line:before {
  content: '\ed19';
}
.remixicon-file-word-2-fill:before {
  content: '\ed1a';
}
.remixicon-file-word-2-line:before {
  content: '\ed1b';
}
.remixicon-file-word-fill:before {
  content: '\ed1c';
}
.remixicon-file-word-line:before {
  content: '\ed1d';
}
.remixicon-file-zip-fill:before {
  content: '\ed1e';
}
.remixicon-file-zip-line:before {
  content: '\ed1f';
}
.remixicon-film-fill:before {
  content: '\ed20';
}
.remixicon-film-line:before {
  content: '\ed21';
}
.remixicon-filter-2-fill:before {
  content: '\ed22';
}
.remixicon-filter-2-line:before {
  content: '\ed23';
}
.remixicon-filter-3-fill:before {
  content: '\ed24';
}
.remixicon-filter-3-line:before {
  content: '\ed25';
}
.remixicon-filter-fill:before {
  content: '\ed26';
}
.remixicon-filter-line:before {
  content: '\ed27';
}
.remixicon-filter-off-fill:before {
  content: '\ed28';
}
.remixicon-filter-off-line:before {
  content: '\ed29';
}
.remixicon-find-replace-fill:before {
  content: '\ed2a';
}
.remixicon-find-replace-line:before {
  content: '\ed2b';
}
.remixicon-finder-fill:before {
  content: '\ed2c';
}
.remixicon-finder-line:before {
  content: '\ed2d';
}
.remixicon-fingerprint-2-fill:before {
  content: '\ed2e';
}
.remixicon-fingerprint-2-line:before {
  content: '\ed2f';
}
.remixicon-fingerprint-fill:before {
  content: '\ed30';
}
.remixicon-fingerprint-line:before {
  content: '\ed31';
}
.remixicon-fire-fill:before {
  content: '\ed32';
}
.remixicon-fire-line:before {
  content: '\ed33';
}
.remixicon-firefox-fill:before {
  content: '\ed34';
}
.remixicon-firefox-line:before {
  content: '\ed35';
}
.remixicon-first-aid-kit-fill:before {
  content: '\ed36';
}
.remixicon-first-aid-kit-line:before {
  content: '\ed37';
}
.remixicon-flag-2-fill:before {
  content: '\ed38';
}
.remixicon-flag-2-line:before {
  content: '\ed39';
}
.remixicon-flag-fill:before {
  content: '\ed3a';
}
.remixicon-flag-line:before {
  content: '\ed3b';
}
.remixicon-flashlight-fill:before {
  content: '\ed3c';
}
.remixicon-flashlight-line:before {
  content: '\ed3d';
}
.remixicon-flask-fill:before {
  content: '\ed3e';
}
.remixicon-flask-line:before {
  content: '\ed3f';
}
.remixicon-flight-land-fill:before {
  content: '\ed40';
}
.remixicon-flight-land-line:before {
  content: '\ed41';
}
.remixicon-flight-takeoff-fill:before {
  content: '\ed42';
}
.remixicon-flight-takeoff-line:before {
  content: '\ed43';
}
.remixicon-flood-fill:before {
  content: '\ed44';
}
.remixicon-flood-line:before {
  content: '\ed45';
}
.remixicon-flow-chart:before {
  content: '\ed46';
}
.remixicon-flutter-fill:before {
  content: '\ed47';
}
.remixicon-flutter-line:before {
  content: '\ed48';
}
.remixicon-focus-2-fill:before {
  content: '\ed49';
}
.remixicon-focus-2-line:before {
  content: '\ed4a';
}
.remixicon-focus-3-fill:before {
  content: '\ed4b';
}
.remixicon-focus-3-line:before {
  content: '\ed4c';
}
.remixicon-focus-fill:before {
  content: '\ed4d';
}
.remixicon-focus-line:before {
  content: '\ed4e';
}
.remixicon-foggy-fill:before {
  content: '\ed4f';
}
.remixicon-foggy-line:before {
  content: '\ed50';
}
.remixicon-folder-2-fill:before {
  content: '\ed51';
}
.remixicon-folder-2-line:before {
  content: '\ed52';
}
.remixicon-folder-3-fill:before {
  content: '\ed53';
}
.remixicon-folder-3-line:before {
  content: '\ed54';
}
.remixicon-folder-4-fill:before {
  content: '\ed55';
}
.remixicon-folder-4-line:before {
  content: '\ed56';
}
.remixicon-folder-5-fill:before {
  content: '\ed57';
}
.remixicon-folder-5-line:before {
  content: '\ed58';
}
.remixicon-folder-add-fill:before {
  content: '\ed59';
}
.remixicon-folder-add-line:before {
  content: '\ed5a';
}
.remixicon-folder-chart-2-fill:before {
  content: '\ed5b';
}
.remixicon-folder-chart-2-line:before {
  content: '\ed5c';
}
.remixicon-folder-chart-fill:before {
  content: '\ed5d';
}
.remixicon-folder-chart-line:before {
  content: '\ed5e';
}
.remixicon-folder-download-fill:before {
  content: '\ed5f';
}
.remixicon-folder-download-line:before {
  content: '\ed60';
}
.remixicon-folder-fill:before {
  content: '\ed61';
}
.remixicon-folder-forbid-fill:before {
  content: '\ed62';
}
.remixicon-folder-forbid-line:before {
  content: '\ed63';
}
.remixicon-folder-history-fill:before {
  content: '\ed64';
}
.remixicon-folder-history-line:before {
  content: '\ed65';
}
.remixicon-folder-info-fill:before {
  content: '\ed66';
}
.remixicon-folder-info-line:before {
  content: '\ed67';
}
.remixicon-folder-keyhole-fill:before {
  content: '\ed68';
}
.remixicon-folder-keyhole-line:before {
  content: '\ed69';
}
.remixicon-folder-line:before {
  content: '\ed6a';
}
.remixicon-folder-lock-fill:before {
  content: '\ed6b';
}
.remixicon-folder-lock-line:before {
  content: '\ed6c';
}
.remixicon-folder-music-fill:before {
  content: '\ed6d';
}
.remixicon-folder-music-line:before {
  content: '\ed6e';
}
.remixicon-folder-open-fill:before {
  content: '\ed6f';
}
.remixicon-folder-open-line:before {
  content: '\ed70';
}
.remixicon-folder-received-fill:before {
  content: '\ed71';
}
.remixicon-folder-received-line:before {
  content: '\ed72';
}
.remixicon-folder-reduce-fill:before {
  content: '\ed73';
}
.remixicon-folder-reduce-line:before {
  content: '\ed74';
}
.remixicon-folder-settings-fill:before {
  content: '\ed75';
}
.remixicon-folder-settings-line:before {
  content: '\ed76';
}
.remixicon-folder-shared-fill:before {
  content: '\ed77';
}
.remixicon-folder-shared-line:before {
  content: '\ed78';
}
.remixicon-folder-shield-2-fill:before {
  content: '\ed79';
}
.remixicon-folder-shield-2-line:before {
  content: '\ed7a';
}
.remixicon-folder-shield-fill:before {
  content: '\ed7b';
}
.remixicon-folder-shield-line:before {
  content: '\ed7c';
}
.remixicon-folder-transfer-fill:before {
  content: '\ed7d';
}
.remixicon-folder-transfer-line:before {
  content: '\ed7e';
}
.remixicon-folder-unknow-fill:before {
  content: '\ed7f';
}
.remixicon-folder-unknow-line:before {
  content: '\ed80';
}
.remixicon-folder-upload-fill:before {
  content: '\ed81';
}
.remixicon-folder-upload-line:before {
  content: '\ed82';
}
.remixicon-folder-user-fill:before {
  content: '\ed83';
}
.remixicon-folder-user-line:before {
  content: '\ed84';
}
.remixicon-folder-warning-fill:before {
  content: '\ed85';
}
.remixicon-folder-warning-line:before {
  content: '\ed86';
}
.remixicon-folder-zip-fill:before {
  content: '\ed87';
}
.remixicon-folder-zip-line:before {
  content: '\ed88';
}
.remixicon-folders-fill:before {
  content: '\ed89';
}
.remixicon-folders-line:before {
  content: '\ed8a';
}
.remixicon-font-color:before {
  content: '\ed8b';
}
.remixicon-font-size-2:before {
  content: '\ed8c';
}
.remixicon-font-size:before {
  content: '\ed8d';
}
.remixicon-football-fill:before {
  content: '\ed8e';
}
.remixicon-football-line:before {
  content: '\ed8f';
}
.remixicon-footprint-fill:before {
  content: '\ed90';
}
.remixicon-footprint-line:before {
  content: '\ed91';
}
.remixicon-forbid-2-fill:before {
  content: '\ed92';
}
.remixicon-forbid-2-line:before {
  content: '\ed93';
}
.remixicon-forbid-fill:before {
  content: '\ed94';
}
.remixicon-forbid-line:before {
  content: '\ed95';
}
.remixicon-format-clear:before {
  content: '\ed96';
}
.remixicon-fridge-fill:before {
  content: '\ed97';
}
.remixicon-fridge-line:before {
  content: '\ed98';
}
.remixicon-fullscreen-exit-fill:before {
  content: '\ed99';
}
.remixicon-fullscreen-exit-line:before {
  content: '\ed9a';
}
.remixicon-fullscreen-fill:before {
  content: '\ed9b';
}
.remixicon-fullscreen-line:before {
  content: '\ed9c';
}
.remixicon-function-fill:before {
  content: '\ed9d';
}
.remixicon-function-line:before {
  content: '\ed9e';
}
.remixicon-functions:before {
  content: '\ed9f';
}
.remixicon-funds-box-fill:before {
  content: '\eda0';
}
.remixicon-funds-box-line:before {
  content: '\eda1';
}
.remixicon-funds-fill:before {
  content: '\eda2';
}
.remixicon-funds-line:before {
  content: '\eda3';
}
.remixicon-gallery-fill:before {
  content: '\eda4';
}
.remixicon-gallery-line:before {
  content: '\eda5';
}
.remixicon-gallery-upload-fill:before {
  content: '\eda6';
}
.remixicon-gallery-upload-line:before {
  content: '\eda7';
}
.remixicon-game-fill:before {
  content: '\eda8';
}
.remixicon-game-line:before {
  content: '\eda9';
}
.remixicon-gamepad-fill:before {
  content: '\edaa';
}
.remixicon-gamepad-line:before {
  content: '\edab';
}
.remixicon-gas-station-fill:before {
  content: '\edac';
}
.remixicon-gas-station-line:before {
  content: '\edad';
}
.remixicon-gatsby-fill:before {
  content: '\edae';
}
.remixicon-gatsby-line:before {
  content: '\edaf';
}
.remixicon-genderless-fill:before {
  content: '\edb0';
}
.remixicon-genderless-line:before {
  content: '\edb1';
}
.remixicon-ghost-2-fill:before {
  content: '\edb2';
}
.remixicon-ghost-2-line:before {
  content: '\edb3';
}
.remixicon-ghost-fill:before {
  content: '\edb4';
}
.remixicon-ghost-line:before {
  content: '\edb5';
}
.remixicon-ghost-smile-fill:before {
  content: '\edb6';
}
.remixicon-ghost-smile-line:before {
  content: '\edb7';
}
.remixicon-gift-2-fill:before {
  content: '\edb8';
}
.remixicon-gift-2-line:before {
  content: '\edb9';
}
.remixicon-gift-fill:before {
  content: '\edba';
}
.remixicon-gift-line:before {
  content: '\edbb';
}
.remixicon-git-branch-fill:before {
  content: '\edbc';
}
.remixicon-git-branch-line:before {
  content: '\edbd';
}
.remixicon-git-commit-fill:before {
  content: '\edbe';
}
.remixicon-git-commit-line:before {
  content: '\edbf';
}
.remixicon-git-merge-fill:before {
  content: '\edc0';
}
.remixicon-git-merge-line:before {
  content: '\edc1';
}
.remixicon-git-pull-request-fill:before {
  content: '\edc2';
}
.remixicon-git-pull-request-line:before {
  content: '\edc3';
}
.remixicon-git-repository-commits-fill:before {
  content: '\edc4';
}
.remixicon-git-repository-commits-line:before {
  content: '\edc5';
}
.remixicon-git-repository-fill:before {
  content: '\edc6';
}
.remixicon-git-repository-line:before {
  content: '\edc7';
}
.remixicon-git-repository-private-fill:before {
  content: '\edc8';
}
.remixicon-git-repository-private-line:before {
  content: '\edc9';
}
.remixicon-github-fill:before {
  content: '\edca';
}
.remixicon-github-line:before {
  content: '\edcb';
}
.remixicon-gitlab-fill:before {
  content: '\edcc';
}
.remixicon-gitlab-line:before {
  content: '\edcd';
}
.remixicon-global-fill:before {
  content: '\edce';
}
.remixicon-global-line:before {
  content: '\edcf';
}
.remixicon-globe-fill:before {
  content: '\edd0';
}
.remixicon-globe-line:before {
  content: '\edd1';
}
.remixicon-goblet-fill:before {
  content: '\edd2';
}
.remixicon-goblet-line:before {
  content: '\edd3';
}
.remixicon-google-fill:before {
  content: '\edd4';
}
.remixicon-google-line:before {
  content: '\edd5';
}
.remixicon-google-play-fill:before {
  content: '\edd6';
}
.remixicon-google-play-line:before {
  content: '\edd7';
}
.remixicon-government-fill:before {
  content: '\edd8';
}
.remixicon-government-line:before {
  content: '\edd9';
}
.remixicon-gps-fill:before {
  content: '\edda';
}
.remixicon-gps-line:before {
  content: '\eddb';
}
.remixicon-gradienter-fill:before {
  content: '\eddc';
}
.remixicon-gradienter-line:before {
  content: '\eddd';
}
.remixicon-grid-fill:before {
  content: '\edde';
}
.remixicon-grid-line:before {
  content: '\eddf';
}
.remixicon-group-2-fill:before {
  content: '\ede0';
}
.remixicon-group-2-line:before {
  content: '\ede1';
}
.remixicon-group-fill:before {
  content: '\ede2';
}
.remixicon-group-line:before {
  content: '\ede3';
}
.remixicon-guide-fill:before {
  content: '\ede4';
}
.remixicon-guide-line:before {
  content: '\ede5';
}
.remixicon-h-1:before {
  content: '\ede6';
}
.remixicon-h-2:before {
  content: '\ede7';
}
.remixicon-h-3:before {
  content: '\ede8';
}
.remixicon-h-4:before {
  content: '\ede9';
}
.remixicon-h-5:before {
  content: '\edea';
}
.remixicon-h-6:before {
  content: '\edeb';
}
.remixicon-hail-fill:before {
  content: '\edec';
}
.remixicon-hail-line:before {
  content: '\eded';
}
.remixicon-hammer-fill:before {
  content: '\edee';
}
.remixicon-hammer-line:before {
  content: '\edef';
}
.remixicon-hand-coin-fill:before {
  content: '\edf0';
}
.remixicon-hand-coin-line:before {
  content: '\edf1';
}
.remixicon-hand-heart-fill:before {
  content: '\edf2';
}
.remixicon-hand-heart-line:before {
  content: '\edf3';
}
.remixicon-hand-sanitizer-fill:before {
  content: '\edf4';
}
.remixicon-hand-sanitizer-line:before {
  content: '\edf5';
}
.remixicon-handbag-fill:before {
  content: '\edf6';
}
.remixicon-handbag-line:before {
  content: '\edf7';
}
.remixicon-hard-drive-2-fill:before {
  content: '\edf8';
}
.remixicon-hard-drive-2-line:before {
  content: '\edf9';
}
.remixicon-hard-drive-fill:before {
  content: '\edfa';
}
.remixicon-hard-drive-line:before {
  content: '\edfb';
}
.remixicon-hashtag:before {
  content: '\edfc';
}
.remixicon-haze-2-fill:before {
  content: '\edfd';
}
.remixicon-haze-2-line:before {
  content: '\edfe';
}
.remixicon-haze-fill:before {
  content: '\edff';
}
.remixicon-haze-line:before {
  content: '\ee00';
}
.remixicon-hd-fill:before {
  content: '\ee01';
}
.remixicon-hd-line:before {
  content: '\ee02';
}
.remixicon-heading:before {
  content: '\ee03';
}
.remixicon-headphone-fill:before {
  content: '\ee04';
}
.remixicon-headphone-line:before {
  content: '\ee05';
}
.remixicon-health-book-fill:before {
  content: '\ee06';
}
.remixicon-health-book-line:before {
  content: '\ee07';
}
.remixicon-heart-2-fill:before {
  content: '\ee08';
}
.remixicon-heart-2-line:before {
  content: '\ee09';
}
.remixicon-heart-3-fill:before {
  content: '\ee0a';
}
.remixicon-heart-3-line:before {
  content: '\ee0b';
}
.remixicon-heart-add-fill:before {
  content: '\ee0c';
}
.remixicon-heart-add-line:before {
  content: '\ee0d';
}
.remixicon-heart-fill:before {
  content: '\ee0e';
}
.remixicon-heart-line:before {
  content: '\ee0f';
}
.remixicon-heart-pulse-fill:before {
  content: '\ee10';
}
.remixicon-heart-pulse-line:before {
  content: '\ee11';
}
.remixicon-hearts-fill:before {
  content: '\ee12';
}
.remixicon-hearts-line:before {
  content: '\ee13';
}
.remixicon-heavy-showers-fill:before {
  content: '\ee14';
}
.remixicon-heavy-showers-line:before {
  content: '\ee15';
}
.remixicon-history-fill:before {
  content: '\ee16';
}
.remixicon-history-line:before {
  content: '\ee17';
}
.remixicon-home-2-fill:before {
  content: '\ee18';
}
.remixicon-home-2-line:before {
  content: '\ee19';
}
.remixicon-home-3-fill:before {
  content: '\ee1a';
}
.remixicon-home-3-line:before {
  content: '\ee1b';
}
.remixicon-home-4-fill:before {
  content: '\ee1c';
}
.remixicon-home-4-line:before {
  content: '\ee1d';
}
.remixicon-home-5-fill:before {
  content: '\ee1e';
}
.remixicon-home-5-line:before {
  content: '\ee1f';
}
.remixicon-home-6-fill:before {
  content: '\ee20';
}
.remixicon-home-6-line:before {
  content: '\ee21';
}
.remixicon-home-7-fill:before {
  content: '\ee22';
}
.remixicon-home-7-line:before {
  content: '\ee23';
}
.remixicon-home-8-fill:before {
  content: '\ee24';
}
.remixicon-home-8-line:before {
  content: '\ee25';
}
.remixicon-home-fill:before {
  content: '\ee26';
}
.remixicon-home-gear-fill:before {
  content: '\ee27';
}
.remixicon-home-gear-line:before {
  content: '\ee28';
}
.remixicon-home-heart-fill:before {
  content: '\ee29';
}
.remixicon-home-heart-line:before {
  content: '\ee2a';
}
.remixicon-home-line:before {
  content: '\ee2b';
}
.remixicon-home-smile-2-fill:before {
  content: '\ee2c';
}
.remixicon-home-smile-2-line:before {
  content: '\ee2d';
}
.remixicon-home-smile-fill:before {
  content: '\ee2e';
}
.remixicon-home-smile-line:before {
  content: '\ee2f';
}
.remixicon-home-wifi-fill:before {
  content: '\ee30';
}
.remixicon-home-wifi-line:before {
  content: '\ee31';
}
.remixicon-honor-of-kings-fill:before {
  content: '\ee32';
}
.remixicon-honor-of-kings-line:before {
  content: '\ee33';
}
.remixicon-honour-fill:before {
  content: '\ee34';
}
.remixicon-honour-line:before {
  content: '\ee35';
}
.remixicon-hospital-fill:before {
  content: '\ee36';
}
.remixicon-hospital-line:before {
  content: '\ee37';
}
.remixicon-hotel-bed-fill:before {
  content: '\ee38';
}
.remixicon-hotel-bed-line:before {
  content: '\ee39';
}
.remixicon-hotel-fill:before {
  content: '\ee3a';
}
.remixicon-hotel-line:before {
  content: '\ee3b';
}
.remixicon-hotspot-fill:before {
  content: '\ee3c';
}
.remixicon-hotspot-line:before {
  content: '\ee3d';
}
.remixicon-hq-fill:before {
  content: '\ee3e';
}
.remixicon-hq-line:before {
  content: '\ee3f';
}
.remixicon-html5-fill:before {
  content: '\ee40';
}
.remixicon-html5-line:before {
  content: '\ee41';
}
.remixicon-ie-fill:before {
  content: '\ee42';
}
.remixicon-ie-line:before {
  content: '\ee43';
}
.remixicon-image-2-fill:before {
  content: '\ee44';
}
.remixicon-image-2-line:before {
  content: '\ee45';
}
.remixicon-image-add-fill:before {
  content: '\ee46';
}
.remixicon-image-add-line:before {
  content: '\ee47';
}
.remixicon-image-edit-fill:before {
  content: '\ee48';
}
.remixicon-image-edit-line:before {
  content: '\ee49';
}
.remixicon-image-fill:before {
  content: '\ee4a';
}
.remixicon-image-line:before {
  content: '\ee4b';
}
.remixicon-inbox-archive-fill:before {
  content: '\ee4c';
}
.remixicon-inbox-archive-line:before {
  content: '\ee4d';
}
.remixicon-inbox-fill:before {
  content: '\ee4e';
}
.remixicon-inbox-line:before {
  content: '\ee4f';
}
.remixicon-inbox-unarchive-fill:before {
  content: '\ee50';
}
.remixicon-inbox-unarchive-line:before {
  content: '\ee51';
}
.remixicon-increase-decrease-fill:before {
  content: '\ee52';
}
.remixicon-increase-decrease-line:before {
  content: '\ee53';
}
.remixicon-indent-decrease:before {
  content: '\ee54';
}
.remixicon-indent-increase:before {
  content: '\ee55';
}
.remixicon-indeterminate-circle-fill:before {
  content: '\ee56';
}
.remixicon-indeterminate-circle-line:before {
  content: '\ee57';
}
.remixicon-information-fill:before {
  content: '\ee58';
}
.remixicon-information-line:before {
  content: '\ee59';
}
.remixicon-infrared-thermometer-fill:before {
  content: '\ee5a';
}
.remixicon-infrared-thermometer-line:before {
  content: '\ee5b';
}
.remixicon-ink-bottle-fill:before {
  content: '\ee5c';
}
.remixicon-ink-bottle-line:before {
  content: '\ee5d';
}
.remixicon-input-cursor-move:before {
  content: '\ee5e';
}
.remixicon-input-method-fill:before {
  content: '\ee5f';
}
.remixicon-input-method-line:before {
  content: '\ee60';
}
.remixicon-insert-column-left:before {
  content: '\ee61';
}
.remixicon-insert-column-right:before {
  content: '\ee62';
}
.remixicon-insert-row-bottom:before {
  content: '\ee63';
}
.remixicon-insert-row-top:before {
  content: '\ee64';
}
.remixicon-instagram-fill:before {
  content: '\ee65';
}
.remixicon-instagram-line:before {
  content: '\ee66';
}
.remixicon-install-fill:before {
  content: '\ee67';
}
.remixicon-install-line:before {
  content: '\ee68';
}
.remixicon-invision-fill:before {
  content: '\ee69';
}
.remixicon-invision-line:before {
  content: '\ee6a';
}
.remixicon-italic:before {
  content: '\ee6b';
}
.remixicon-kakao-talk-fill:before {
  content: '\ee6c';
}
.remixicon-kakao-talk-line:before {
  content: '\ee6d';
}
.remixicon-key-2-fill:before {
  content: '\ee6e';
}
.remixicon-key-2-line:before {
  content: '\ee6f';
}
.remixicon-key-fill:before {
  content: '\ee70';
}
.remixicon-key-line:before {
  content: '\ee71';
}
.remixicon-keyboard-box-fill:before {
  content: '\ee72';
}
.remixicon-keyboard-box-line:before {
  content: '\ee73';
}
.remixicon-keyboard-fill:before {
  content: '\ee74';
}
.remixicon-keyboard-line:before {
  content: '\ee75';
}
.remixicon-keynote-fill:before {
  content: '\ee76';
}
.remixicon-keynote-line:before {
  content: '\ee77';
}
.remixicon-knife-blood-fill:before {
  content: '\ee78';
}
.remixicon-knife-blood-line:before {
  content: '\ee79';
}
.remixicon-knife-fill:before {
  content: '\ee7a';
}
.remixicon-knife-line:before {
  content: '\ee7b';
}
.remixicon-landscape-fill:before {
  content: '\ee7c';
}
.remixicon-landscape-line:before {
  content: '\ee7d';
}
.remixicon-layout-2-fill:before {
  content: '\ee7e';
}
.remixicon-layout-2-line:before {
  content: '\ee7f';
}
.remixicon-layout-3-fill:before {
  content: '\ee80';
}
.remixicon-layout-3-line:before {
  content: '\ee81';
}
.remixicon-layout-4-fill:before {
  content: '\ee82';
}
.remixicon-layout-4-line:before {
  content: '\ee83';
}
.remixicon-layout-5-fill:before {
  content: '\ee84';
}
.remixicon-layout-5-line:before {
  content: '\ee85';
}
.remixicon-layout-6-fill:before {
  content: '\ee86';
}
.remixicon-layout-6-line:before {
  content: '\ee87';
}
.remixicon-layout-bottom-2-fill:before {
  content: '\ee88';
}
.remixicon-layout-bottom-2-line:before {
  content: '\ee89';
}
.remixicon-layout-bottom-fill:before {
  content: '\ee8a';
}
.remixicon-layout-bottom-line:before {
  content: '\ee8b';
}
.remixicon-layout-column-fill:before {
  content: '\ee8c';
}
.remixicon-layout-column-line:before {
  content: '\ee8d';
}
.remixicon-layout-fill:before {
  content: '\ee8e';
}
.remixicon-layout-grid-fill:before {
  content: '\ee8f';
}
.remixicon-layout-grid-line:before {
  content: '\ee90';
}
.remixicon-layout-left-2-fill:before {
  content: '\ee91';
}
.remixicon-layout-left-2-line:before {
  content: '\ee92';
}
.remixicon-layout-left-fill:before {
  content: '\ee93';
}
.remixicon-layout-left-line:before {
  content: '\ee94';
}
.remixicon-layout-line:before {
  content: '\ee95';
}
.remixicon-layout-masonry-fill:before {
  content: '\ee96';
}
.remixicon-layout-masonry-line:before {
  content: '\ee97';
}
.remixicon-layout-right-2-fill:before {
  content: '\ee98';
}
.remixicon-layout-right-2-line:before {
  content: '\ee99';
}
.remixicon-layout-right-fill:before {
  content: '\ee9a';
}
.remixicon-layout-right-line:before {
  content: '\ee9b';
}
.remixicon-layout-row-fill:before {
  content: '\ee9c';
}
.remixicon-layout-row-line:before {
  content: '\ee9d';
}
.remixicon-layout-top-2-fill:before {
  content: '\ee9e';
}
.remixicon-layout-top-2-line:before {
  content: '\ee9f';
}
.remixicon-layout-top-fill:before {
  content: '\eea0';
}
.remixicon-layout-top-line:before {
  content: '\eea1';
}
.remixicon-leaf-fill:before {
  content: '\eea2';
}
.remixicon-leaf-line:before {
  content: '\eea3';
}
.remixicon-lifebuoy-fill:before {
  content: '\eea4';
}
.remixicon-lifebuoy-line:before {
  content: '\eea5';
}
.remixicon-lightbulb-fill:before {
  content: '\eea6';
}
.remixicon-lightbulb-flash-fill:before {
  content: '\eea7';
}
.remixicon-lightbulb-flash-line:before {
  content: '\eea8';
}
.remixicon-lightbulb-line:before {
  content: '\eea9';
}
.remixicon-line-chart-fill:before {
  content: '\eeaa';
}
.remixicon-line-chart-line:before {
  content: '\eeab';
}
.remixicon-line-fill:before {
  content: '\eeac';
}
.remixicon-line-height:before {
  content: '\eead';
}
.remixicon-line-line:before {
  content: '\eeae';
}
.remixicon-link-m:before {
  content: '\eeaf';
}
.remixicon-link-unlink-m:before {
  content: '\eeb0';
}
.remixicon-link-unlink:before {
  content: '\eeb1';
}
.remixicon-link:before {
  content: '\eeb2';
}
.remixicon-linkedin-box-fill:before {
  content: '\eeb3';
}
.remixicon-linkedin-box-line:before {
  content: '\eeb4';
}
.remixicon-linkedin-fill:before {
  content: '\eeb5';
}
.remixicon-linkedin-line:before {
  content: '\eeb6';
}
.remixicon-links-fill:before {
  content: '\eeb7';
}
.remixicon-links-line:before {
  content: '\eeb8';
}
.remixicon-list-check-2:before {
  content: '\eeb9';
}
.remixicon-list-check:before {
  content: '\eeba';
}
.remixicon-list-ordered:before {
  content: '\eebb';
}
.remixicon-list-settings-fill:before {
  content: '\eebc';
}
.remixicon-list-settings-line:before {
  content: '\eebd';
}
.remixicon-list-unordered:before {
  content: '\eebe';
}
.remixicon-live-fill:before {
  content: '\eebf';
}
.remixicon-live-line:before {
  content: '\eec0';
}
.remixicon-loader-2-fill:before {
  content: '\eec1';
}
.remixicon-loader-2-line:before {
  content: '\eec2';
}
.remixicon-loader-3-fill:before {
  content: '\eec3';
}
.remixicon-loader-3-line:before {
  content: '\eec4';
}
.remixicon-loader-4-fill:before {
  content: '\eec5';
}
.remixicon-loader-4-line:before {
  content: '\eec6';
}
.remixicon-loader-5-fill:before {
  content: '\eec7';
}
.remixicon-loader-5-line:before {
  content: '\eec8';
}
.remixicon-loader-fill:before {
  content: '\eec9';
}
.remixicon-loader-line:before {
  content: '\eeca';
}
.remixicon-lock-2-fill:before {
  content: '\eecb';
}
.remixicon-lock-2-line:before {
  content: '\eecc';
}
.remixicon-lock-fill:before {
  content: '\eecd';
}
.remixicon-lock-line:before {
  content: '\eece';
}
.remixicon-lock-password-fill:before {
  content: '\eecf';
}
.remixicon-lock-password-line:before {
  content: '\eed0';
}
.remixicon-lock-unlock-fill:before {
  content: '\eed1';
}
.remixicon-lock-unlock-line:before {
  content: '\eed2';
}
.remixicon-login-box-fill:before {
  content: '\eed3';
}
.remixicon-login-box-line:before {
  content: '\eed4';
}
.remixicon-login-circle-fill:before {
  content: '\eed5';
}
.remixicon-login-circle-line:before {
  content: '\eed6';
}
.remixicon-logout-box-fill:before {
  content: '\eed7';
}
.remixicon-logout-box-line:before {
  content: '\eed8';
}
.remixicon-logout-box-r-fill:before {
  content: '\eed9';
}
.remixicon-logout-box-r-line:before {
  content: '\eeda';
}
.remixicon-logout-circle-fill:before {
  content: '\eedb';
}
.remixicon-logout-circle-line:before {
  content: '\eedc';
}
.remixicon-logout-circle-r-fill:before {
  content: '\eedd';
}
.remixicon-logout-circle-r-line:before {
  content: '\eede';
}
.remixicon-luggage-cart-fill:before {
  content: '\eedf';
}
.remixicon-luggage-cart-line:before {
  content: '\eee0';
}
.remixicon-luggage-deposit-fill:before {
  content: '\eee1';
}
.remixicon-luggage-deposit-line:before {
  content: '\eee2';
}
.remixicon-lungs-fill:before {
  content: '\eee3';
}
.remixicon-lungs-line:before {
  content: '\eee4';
}
.remixicon-mac-fill:before {
  content: '\eee5';
}
.remixicon-mac-line:before {
  content: '\eee6';
}
.remixicon-macbook-fill:before {
  content: '\eee7';
}
.remixicon-macbook-line:before {
  content: '\eee8';
}
.remixicon-magic-fill:before {
  content: '\eee9';
}
.remixicon-magic-line:before {
  content: '\eeea';
}
.remixicon-mail-add-fill:before {
  content: '\eeeb';
}
.remixicon-mail-add-line:before {
  content: '\eeec';
}
.remixicon-mail-check-fill:before {
  content: '\eeed';
}
.remixicon-mail-check-line:before {
  content: '\eeee';
}
.remixicon-mail-close-fill:before {
  content: '\eeef';
}
.remixicon-mail-close-line:before {
  content: '\eef0';
}
.remixicon-mail-download-fill:before {
  content: '\eef1';
}
.remixicon-mail-download-line:before {
  content: '\eef2';
}
.remixicon-mail-fill:before {
  content: '\eef3';
}
.remixicon-mail-forbid-fill:before {
  content: '\eef4';
}
.remixicon-mail-forbid-line:before {
  content: '\eef5';
}
.remixicon-mail-line:before {
  content: '\eef6';
}
.remixicon-mail-lock-fill:before {
  content: '\eef7';
}
.remixicon-mail-lock-line:before {
  content: '\eef8';
}
.remixicon-mail-open-fill:before {
  content: '\eef9';
}
.remixicon-mail-open-line:before {
  content: '\eefa';
}
.remixicon-mail-send-fill:before {
  content: '\eefb';
}
.remixicon-mail-send-line:before {
  content: '\eefc';
}
.remixicon-mail-settings-fill:before {
  content: '\eefd';
}
.remixicon-mail-settings-line:before {
  content: '\eefe';
}
.remixicon-mail-star-fill:before {
  content: '\eeff';
}
.remixicon-mail-star-line:before {
  content: '\ef00';
}
.remixicon-mail-unread-fill:before {
  content: '\ef01';
}
.remixicon-mail-unread-line:before {
  content: '\ef02';
}
.remixicon-mail-volume-fill:before {
  content: '\ef03';
}
.remixicon-mail-volume-line:before {
  content: '\ef04';
}
.remixicon-map-2-fill:before {
  content: '\ef05';
}
.remixicon-map-2-line:before {
  content: '\ef06';
}
.remixicon-map-fill:before {
  content: '\ef07';
}
.remixicon-map-line:before {
  content: '\ef08';
}
.remixicon-map-pin-2-fill:before {
  content: '\ef09';
}
.remixicon-map-pin-2-line:before {
  content: '\ef0a';
}
.remixicon-map-pin-3-fill:before {
  content: '\ef0b';
}
.remixicon-map-pin-3-line:before {
  content: '\ef0c';
}
.remixicon-map-pin-4-fill:before {
  content: '\ef0d';
}
.remixicon-map-pin-4-line:before {
  content: '\ef0e';
}
.remixicon-map-pin-5-fill:before {
  content: '\ef0f';
}
.remixicon-map-pin-5-line:before {
  content: '\ef10';
}
.remixicon-map-pin-add-fill:before {
  content: '\ef11';
}
.remixicon-map-pin-add-line:before {
  content: '\ef12';
}
.remixicon-map-pin-fill:before {
  content: '\ef13';
}
.remixicon-map-pin-line:before {
  content: '\ef14';
}
.remixicon-map-pin-range-fill:before {
  content: '\ef15';
}
.remixicon-map-pin-range-line:before {
  content: '\ef16';
}
.remixicon-map-pin-time-fill:before {
  content: '\ef17';
}
.remixicon-map-pin-time-line:before {
  content: '\ef18';
}
.remixicon-map-pin-user-fill:before {
  content: '\ef19';
}
.remixicon-map-pin-user-line:before {
  content: '\ef1a';
}
.remixicon-mark-pen-fill:before {
  content: '\ef1b';
}
.remixicon-mark-pen-line:before {
  content: '\ef1c';
}
.remixicon-markdown-fill:before {
  content: '\ef1d';
}
.remixicon-markdown-line:before {
  content: '\ef1e';
}
.remixicon-markup-fill:before {
  content: '\ef1f';
}
.remixicon-markup-line:before {
  content: '\ef20';
}
.remixicon-mastercard-fill:before {
  content: '\ef21';
}
.remixicon-mastercard-line:before {
  content: '\ef22';
}
.remixicon-mastodon-fill:before {
  content: '\ef23';
}
.remixicon-mastodon-line:before {
  content: '\ef24';
}
.remixicon-medal-2-fill:before {
  content: '\ef25';
}
.remixicon-medal-2-line:before {
  content: '\ef26';
}
.remixicon-medal-fill:before {
  content: '\ef27';
}
.remixicon-medal-line:before {
  content: '\ef28';
}
.remixicon-medicine-bottle-fill:before {
  content: '\ef29';
}
.remixicon-medicine-bottle-line:before {
  content: '\ef2a';
}
.remixicon-medium-fill:before {
  content: '\ef2b';
}
.remixicon-medium-line:before {
  content: '\ef2c';
}
.remixicon-men-fill:before {
  content: '\ef2d';
}
.remixicon-men-line:before {
  content: '\ef2e';
}
.remixicon-mental-health-fill:before {
  content: '\ef2f';
}
.remixicon-mental-health-line:before {
  content: '\ef30';
}
.remixicon-menu-2-fill:before {
  content: '\ef31';
}
.remixicon-menu-2-line:before {
  content: '\ef32';
}
.remixicon-menu-3-fill:before {
  content: '\ef33';
}
.remixicon-menu-3-line:before {
  content: '\ef34';
}
.remixicon-menu-4-fill:before {
  content: '\ef35';
}
.remixicon-menu-4-line:before {
  content: '\ef36';
}
.remixicon-menu-5-fill:before {
  content: '\ef37';
}
.remixicon-menu-5-line:before {
  content: '\ef38';
}
.remixicon-menu-add-fill:before {
  content: '\ef39';
}
.remixicon-menu-add-line:before {
  content: '\ef3a';
}
.remixicon-menu-fill:before {
  content: '\ef3b';
}
.remixicon-menu-fold-fill:before {
  content: '\ef3c';
}
.remixicon-menu-fold-line:before {
  content: '\ef3d';
}
.remixicon-menu-line:before {
  content: '\ef3e';
}
.remixicon-menu-unfold-fill:before {
  content: '\ef3f';
}
.remixicon-menu-unfold-line:before {
  content: '\ef40';
}
.remixicon-merge-cells-horizontal:before {
  content: '\ef41';
}
.remixicon-merge-cells-vertical:before {
  content: '\ef42';
}
.remixicon-message-2-fill:before {
  content: '\ef43';
}
.remixicon-message-2-line:before {
  content: '\ef44';
}
.remixicon-message-3-fill:before {
  content: '\ef45';
}
.remixicon-message-3-line:before {
  content: '\ef46';
}
.remixicon-message-fill:before {
  content: '\ef47';
}
.remixicon-message-line:before {
  content: '\ef48';
}
.remixicon-messenger-fill:before {
  content: '\ef49';
}
.remixicon-messenger-line:before {
  content: '\ef4a';
}
.remixicon-meteor-fill:before {
  content: '\ef4b';
}
.remixicon-meteor-line:before {
  content: '\ef4c';
}
.remixicon-mic-2-fill:before {
  content: '\ef4d';
}
.remixicon-mic-2-line:before {
  content: '\ef4e';
}
.remixicon-mic-fill:before {
  content: '\ef4f';
}
.remixicon-mic-line:before {
  content: '\ef50';
}
.remixicon-mic-off-fill:before {
  content: '\ef51';
}
.remixicon-mic-off-line:before {
  content: '\ef52';
}
.remixicon-mickey-fill:before {
  content: '\ef53';
}
.remixicon-mickey-line:before {
  content: '\ef54';
}
.remixicon-microscope-fill:before {
  content: '\ef55';
}
.remixicon-microscope-line:before {
  content: '\ef56';
}
.remixicon-microsoft-fill:before {
  content: '\ef57';
}
.remixicon-microsoft-line:before {
  content: '\ef58';
}
.remixicon-mind-map:before {
  content: '\ef59';
}
.remixicon-mini-program-fill:before {
  content: '\ef5a';
}
.remixicon-mini-program-line:before {
  content: '\ef5b';
}
.remixicon-mist-fill:before {
  content: '\ef5c';
}
.remixicon-mist-line:before {
  content: '\ef5d';
}
.remixicon-money-cny-box-fill:before {
  content: '\ef5e';
}
.remixicon-money-cny-box-line:before {
  content: '\ef5f';
}
.remixicon-money-cny-circle-fill:before {
  content: '\ef60';
}
.remixicon-money-cny-circle-line:before {
  content: '\ef61';
}
.remixicon-money-dollar-box-fill:before {
  content: '\ef62';
}
.remixicon-money-dollar-box-line:before {
  content: '\ef63';
}
.remixicon-money-dollar-circle-fill:before {
  content: '\ef64';
}
.remixicon-money-dollar-circle-line:before {
  content: '\ef65';
}
.remixicon-money-euro-box-fill:before {
  content: '\ef66';
}
.remixicon-money-euro-box-line:before {
  content: '\ef67';
}
.remixicon-money-euro-circle-fill:before {
  content: '\ef68';
}
.remixicon-money-euro-circle-line:before {
  content: '\ef69';
}
.remixicon-money-pound-box-fill:before {
  content: '\ef6a';
}
.remixicon-money-pound-box-line:before {
  content: '\ef6b';
}
.remixicon-money-pound-circle-fill:before {
  content: '\ef6c';
}
.remixicon-money-pound-circle-line:before {
  content: '\ef6d';
}
.remixicon-moon-clear-fill:before {
  content: '\ef6e';
}
.remixicon-moon-clear-line:before {
  content: '\ef6f';
}
.remixicon-moon-cloudy-fill:before {
  content: '\ef70';
}
.remixicon-moon-cloudy-line:before {
  content: '\ef71';
}
.remixicon-moon-fill:before {
  content: '\ef72';
}
.remixicon-moon-foggy-fill:before {
  content: '\ef73';
}
.remixicon-moon-foggy-line:before {
  content: '\ef74';
}
.remixicon-moon-line:before {
  content: '\ef75';
}
.remixicon-more-2-fill:before {
  content: '\ef76';
}
.remixicon-more-2-line:before {
  content: '\ef77';
}
.remixicon-more-fill:before {
  content: '\ef78';
}
.remixicon-more-line:before {
  content: '\ef79';
}
.remixicon-motorbike-fill:before {
  content: '\ef7a';
}
.remixicon-motorbike-line:before {
  content: '\ef7b';
}
.remixicon-mouse-fill:before {
  content: '\ef7c';
}
.remixicon-mouse-line:before {
  content: '\ef7d';
}
.remixicon-movie-2-fill:before {
  content: '\ef7e';
}
.remixicon-movie-2-line:before {
  content: '\ef7f';
}
.remixicon-movie-fill:before {
  content: '\ef80';
}
.remixicon-movie-line:before {
  content: '\ef81';
}
.remixicon-music-2-fill:before {
  content: '\ef82';
}
.remixicon-music-2-line:before {
  content: '\ef83';
}
.remixicon-music-fill:before {
  content: '\ef84';
}
.remixicon-music-line:before {
  content: '\ef85';
}
.remixicon-mv-fill:before {
  content: '\ef86';
}
.remixicon-mv-line:before {
  content: '\ef87';
}
.remixicon-navigation-fill:before {
  content: '\ef88';
}
.remixicon-navigation-line:before {
  content: '\ef89';
}
.remixicon-netease-cloud-music-fill:before {
  content: '\ef8a';
}
.remixicon-netease-cloud-music-line:before {
  content: '\ef8b';
}
.remixicon-netflix-fill:before {
  content: '\ef8c';
}
.remixicon-netflix-line:before {
  content: '\ef8d';
}
.remixicon-newspaper-fill:before {
  content: '\ef8e';
}
.remixicon-newspaper-line:before {
  content: '\ef8f';
}
.remixicon-node-tree:before {
  content: '\ef90';
}
.remixicon-notification-2-fill:before {
  content: '\ef91';
}
.remixicon-notification-2-line:before {
  content: '\ef92';
}
.remixicon-notification-3-fill:before {
  content: '\ef93';
}
.remixicon-notification-3-line:before {
  content: '\ef94';
}
.remixicon-notification-4-fill:before {
  content: '\ef95';
}
.remixicon-notification-4-line:before {
  content: '\ef96';
}
.remixicon-notification-badge-fill:before {
  content: '\ef97';
}
.remixicon-notification-badge-line:before {
  content: '\ef98';
}
.remixicon-notification-fill:before {
  content: '\ef99';
}
.remixicon-notification-line:before {
  content: '\ef9a';
}
.remixicon-notification-off-fill:before {
  content: '\ef9b';
}
.remixicon-notification-off-line:before {
  content: '\ef9c';
}
.remixicon-npmjs-fill:before {
  content: '\ef9d';
}
.remixicon-npmjs-line:before {
  content: '\ef9e';
}
.remixicon-number-0:before {
  content: '\ef9f';
}
.remixicon-number-1:before {
  content: '\efa0';
}
.remixicon-number-2:before {
  content: '\efa1';
}
.remixicon-number-3:before {
  content: '\efa2';
}
.remixicon-number-4:before {
  content: '\efa3';
}
.remixicon-number-5:before {
  content: '\efa4';
}
.remixicon-number-6:before {
  content: '\efa5';
}
.remixicon-number-7:before {
  content: '\efa6';
}
.remixicon-number-8:before {
  content: '\efa7';
}
.remixicon-number-9:before {
  content: '\efa8';
}
.remixicon-numbers-fill:before {
  content: '\efa9';
}
.remixicon-numbers-line:before {
  content: '\efaa';
}
.remixicon-nurse-fill:before {
  content: '\efab';
}
.remixicon-nurse-line:before {
  content: '\efac';
}
.remixicon-oil-fill:before {
  content: '\efad';
}
.remixicon-oil-line:before {
  content: '\efae';
}
.remixicon-omega:before {
  content: '\efaf';
}
.remixicon-open-arm-fill:before {
  content: '\efb0';
}
.remixicon-open-arm-line:before {
  content: '\efb1';
}
.remixicon-open-source-fill:before {
  content: '\efb2';
}
.remixicon-open-source-line:before {
  content: '\efb3';
}
.remixicon-opera-fill:before {
  content: '\efb4';
}
.remixicon-opera-line:before {
  content: '\efb5';
}
.remixicon-order-play-fill:before {
  content: '\efb6';
}
.remixicon-order-play-line:before {
  content: '\efb7';
}
.remixicon-organization-chart:before {
  content: '\efb8';
}
.remixicon-outlet-2-fill:before {
  content: '\efb9';
}
.remixicon-outlet-2-line:before {
  content: '\efba';
}
.remixicon-outlet-fill:before {
  content: '\efbb';
}
.remixicon-outlet-line:before {
  content: '\efbc';
}
.remixicon-page-separator:before {
  content: '\efbd';
}
.remixicon-pages-fill:before {
  content: '\efbe';
}
.remixicon-pages-line:before {
  content: '\efbf';
}
.remixicon-paint-brush-fill:before {
  content: '\efc0';
}
.remixicon-paint-brush-line:before {
  content: '\efc1';
}
.remixicon-paint-fill:before {
  content: '\efc2';
}
.remixicon-paint-line:before {
  content: '\efc3';
}
.remixicon-palette-fill:before {
  content: '\efc4';
}
.remixicon-palette-line:before {
  content: '\efc5';
}
.remixicon-pantone-fill:before {
  content: '\efc6';
}
.remixicon-pantone-line:before {
  content: '\efc7';
}
.remixicon-paragraph:before {
  content: '\efc8';
}
.remixicon-parent-fill:before {
  content: '\efc9';
}
.remixicon-parent-line:before {
  content: '\efca';
}
.remixicon-parentheses-fill:before {
  content: '\efcb';
}
.remixicon-parentheses-line:before {
  content: '\efcc';
}
.remixicon-parking-box-fill:before {
  content: '\efcd';
}
.remixicon-parking-box-line:before {
  content: '\efce';
}
.remixicon-parking-fill:before {
  content: '\efcf';
}
.remixicon-parking-line:before {
  content: '\efd0';
}
.remixicon-passport-fill:before {
  content: '\efd1';
}
.remixicon-passport-line:before {
  content: '\efd2';
}
.remixicon-patreon-fill:before {
  content: '\efd3';
}
.remixicon-patreon-line:before {
  content: '\efd4';
}
.remixicon-pause-circle-fill:before {
  content: '\efd5';
}
.remixicon-pause-circle-line:before {
  content: '\efd6';
}
.remixicon-pause-fill:before {
  content: '\efd7';
}
.remixicon-pause-line:before {
  content: '\efd8';
}
.remixicon-pause-mini-fill:before {
  content: '\efd9';
}
.remixicon-pause-mini-line:before {
  content: '\efda';
}
.remixicon-paypal-fill:before {
  content: '\efdb';
}
.remixicon-paypal-line:before {
  content: '\efdc';
}
.remixicon-pen-nib-fill:before {
  content: '\efdd';
}
.remixicon-pen-nib-line:before {
  content: '\efde';
}
.remixicon-pencil-fill:before {
  content: '\efdf';
}
.remixicon-pencil-line:before {
  content: '\efe0';
}
.remixicon-pencil-ruler-2-fill:before {
  content: '\efe1';
}
.remixicon-pencil-ruler-2-line:before {
  content: '\efe2';
}
.remixicon-pencil-ruler-fill:before {
  content: '\efe3';
}
.remixicon-pencil-ruler-line:before {
  content: '\efe4';
}
.remixicon-percent-fill:before {
  content: '\efe5';
}
.remixicon-percent-line:before {
  content: '\efe6';
}
.remixicon-phone-camera-fill:before {
  content: '\efe7';
}
.remixicon-phone-camera-line:before {
  content: '\efe8';
}
.remixicon-phone-fill:before {
  content: '\efe9';
}
.remixicon-phone-find-fill:before {
  content: '\efea';
}
.remixicon-phone-find-line:before {
  content: '\efeb';
}
.remixicon-phone-line:before {
  content: '\efec';
}
.remixicon-phone-lock-fill:before {
  content: '\efed';
}
.remixicon-phone-lock-line:before {
  content: '\efee';
}
.remixicon-picture-in-picture-2-fill:before {
  content: '\efef';
}
.remixicon-picture-in-picture-2-line:before {
  content: '\eff0';
}
.remixicon-picture-in-picture-exit-fill:before {
  content: '\eff1';
}
.remixicon-picture-in-picture-exit-line:before {
  content: '\eff2';
}
.remixicon-picture-in-picture-fill:before {
  content: '\eff3';
}
.remixicon-picture-in-picture-line:before {
  content: '\eff4';
}
.remixicon-pie-chart-2-fill:before {
  content: '\eff5';
}
.remixicon-pie-chart-2-line:before {
  content: '\eff6';
}
.remixicon-pie-chart-box-fill:before {
  content: '\eff7';
}
.remixicon-pie-chart-box-line:before {
  content: '\eff8';
}
.remixicon-pie-chart-fill:before {
  content: '\eff9';
}
.remixicon-pie-chart-line:before {
  content: '\effa';
}
.remixicon-pin-distance-fill:before {
  content: '\effb';
}
.remixicon-pin-distance-line:before {
  content: '\effc';
}
.remixicon-ping-pong-fill:before {
  content: '\effd';
}
.remixicon-ping-pong-line:before {
  content: '\effe';
}
.remixicon-pinterest-fill:before {
  content: '\efff';
}
.remixicon-pinterest-line:before {
  content: '\f000';
}
.remixicon-pinyin-input:before {
  content: '\f001';
}
.remixicon-pixelfed-fill:before {
  content: '\f002';
}
.remixicon-pixelfed-line:before {
  content: '\f003';
}
.remixicon-plane-fill:before {
  content: '\f004';
}
.remixicon-plane-line:before {
  content: '\f005';
}
.remixicon-plant-fill:before {
  content: '\f006';
}
.remixicon-plant-line:before {
  content: '\f007';
}
.remixicon-play-circle-fill:before {
  content: '\f008';
}
.remixicon-play-circle-line:before {
  content: '\f009';
}
.remixicon-play-fill:before {
  content: '\f00a';
}
.remixicon-play-line:before {
  content: '\f00b';
}
.remixicon-play-list-2-fill:before {
  content: '\f00c';
}
.remixicon-play-list-2-line:before {
  content: '\f00d';
}
.remixicon-play-list-add-fill:before {
  content: '\f00e';
}
.remixicon-play-list-add-line:before {
  content: '\f00f';
}
.remixicon-play-list-fill:before {
  content: '\f010';
}
.remixicon-play-list-line:before {
  content: '\f011';
}
.remixicon-play-mini-fill:before {
  content: '\f012';
}
.remixicon-play-mini-line:before {
  content: '\f013';
}
.remixicon-playstation-fill:before {
  content: '\f014';
}
.remixicon-playstation-line:before {
  content: '\f015';
}
.remixicon-plug-2-fill:before {
  content: '\f016';
}
.remixicon-plug-2-line:before {
  content: '\f017';
}
.remixicon-plug-fill:before {
  content: '\f018';
}
.remixicon-plug-line:before {
  content: '\f019';
}
.remixicon-polaroid-2-fill:before {
  content: '\f01a';
}
.remixicon-polaroid-2-line:before {
  content: '\f01b';
}
.remixicon-polaroid-fill:before {
  content: '\f01c';
}
.remixicon-polaroid-line:before {
  content: '\f01d';
}
.remixicon-police-car-fill:before {
  content: '\f01e';
}
.remixicon-police-car-line:before {
  content: '\f01f';
}
.remixicon-price-tag-2-fill:before {
  content: '\f020';
}
.remixicon-price-tag-2-line:before {
  content: '\f021';
}
.remixicon-price-tag-3-fill:before {
  content: '\f022';
}
.remixicon-price-tag-3-line:before {
  content: '\f023';
}
.remixicon-price-tag-fill:before {
  content: '\f024';
}
.remixicon-price-tag-line:before {
  content: '\f025';
}
.remixicon-printer-cloud-fill:before {
  content: '\f026';
}
.remixicon-printer-cloud-line:before {
  content: '\f027';
}
.remixicon-printer-fill:before {
  content: '\f028';
}
.remixicon-printer-line:before {
  content: '\f029';
}
.remixicon-product-hunt-fill:before {
  content: '\f02a';
}
.remixicon-product-hunt-line:before {
  content: '\f02b';
}
.remixicon-profile-fill:before {
  content: '\f02c';
}
.remixicon-profile-line:before {
  content: '\f02d';
}
.remixicon-projector-2-fill:before {
  content: '\f02e';
}
.remixicon-projector-2-line:before {
  content: '\f02f';
}
.remixicon-projector-fill:before {
  content: '\f030';
}
.remixicon-projector-line:before {
  content: '\f031';
}
.remixicon-psychotherapy-fill:before {
  content: '\f032';
}
.remixicon-psychotherapy-line:before {
  content: '\f033';
}
.remixicon-pulse-fill:before {
  content: '\f034';
}
.remixicon-pulse-line:before {
  content: '\f035';
}
.remixicon-pushpin-2-fill:before {
  content: '\f036';
}
.remixicon-pushpin-2-line:before {
  content: '\f037';
}
.remixicon-pushpin-fill:before {
  content: '\f038';
}
.remixicon-pushpin-line:before {
  content: '\f039';
}
.remixicon-qq-fill:before {
  content: '\f03a';
}
.remixicon-qq-line:before {
  content: '\f03b';
}
.remixicon-qr-code-fill:before {
  content: '\f03c';
}
.remixicon-qr-code-line:before {
  content: '\f03d';
}
.remixicon-qr-scan-2-fill:before {
  content: '\f03e';
}
.remixicon-qr-scan-2-line:before {
  content: '\f03f';
}
.remixicon-qr-scan-fill:before {
  content: '\f040';
}
.remixicon-qr-scan-line:before {
  content: '\f041';
}
.remixicon-question-answer-fill:before {
  content: '\f042';
}
.remixicon-question-answer-line:before {
  content: '\f043';
}
.remixicon-question-fill:before {
  content: '\f044';
}
.remixicon-question-line:before {
  content: '\f045';
}
.remixicon-question-mark:before {
  content: '\f046';
}
.remixicon-questionnaire-fill:before {
  content: '\f047';
}
.remixicon-questionnaire-line:before {
  content: '\f048';
}
.remixicon-quill-pen-fill:before {
  content: '\f049';
}
.remixicon-quill-pen-line:before {
  content: '\f04a';
}
.remixicon-radar-fill:before {
  content: '\f04b';
}
.remixicon-radar-line:before {
  content: '\f04c';
}
.remixicon-radio-2-fill:before {
  content: '\f04d';
}
.remixicon-radio-2-line:before {
  content: '\f04e';
}
.remixicon-radio-button-fill:before {
  content: '\f04f';
}
.remixicon-radio-button-line:before {
  content: '\f050';
}
.remixicon-radio-fill:before {
  content: '\f051';
}
.remixicon-radio-line:before {
  content: '\f052';
}
.remixicon-rainbow-fill:before {
  content: '\f053';
}
.remixicon-rainbow-line:before {
  content: '\f054';
}
.remixicon-rainy-fill:before {
  content: '\f055';
}
.remixicon-rainy-line:before {
  content: '\f056';
}
.remixicon-reactjs-fill:before {
  content: '\f057';
}
.remixicon-reactjs-line:before {
  content: '\f058';
}
.remixicon-record-circle-fill:before {
  content: '\f059';
}
.remixicon-record-circle-line:before {
  content: '\f05a';
}
.remixicon-record-mail-fill:before {
  content: '\f05b';
}
.remixicon-record-mail-line:before {
  content: '\f05c';
}
.remixicon-recycle-fill:before {
  content: '\f05d';
}
.remixicon-recycle-line:before {
  content: '\f05e';
}
.remixicon-red-packet-fill:before {
  content: '\f05f';
}
.remixicon-red-packet-line:before {
  content: '\f060';
}
.remixicon-reddit-fill:before {
  content: '\f061';
}
.remixicon-reddit-line:before {
  content: '\f062';
}
.remixicon-refresh-fill:before {
  content: '\f063';
}
.remixicon-refresh-line:before {
  content: '\f064';
}
.remixicon-refund-2-fill:before {
  content: '\f065';
}
.remixicon-refund-2-line:before {
  content: '\f066';
}
.remixicon-refund-fill:before {
  content: '\f067';
}
.remixicon-refund-line:before {
  content: '\f068';
}
.remixicon-registered-fill:before {
  content: '\f069';
}
.remixicon-registered-line:before {
  content: '\f06a';
}
.remixicon-remixicon-fill:before {
  content: '\f06b';
}
.remixicon-remixicon-line:before {
  content: '\f06c';
}
.remixicon-remote-control-2-fill:before {
  content: '\f06d';
}
.remixicon-remote-control-2-line:before {
  content: '\f06e';
}
.remixicon-remote-control-fill:before {
  content: '\f06f';
}
.remixicon-remote-control-line:before {
  content: '\f070';
}
.remixicon-repeat-2-fill:before {
  content: '\f071';
}
.remixicon-repeat-2-line:before {
  content: '\f072';
}
.remixicon-repeat-fill:before {
  content: '\f073';
}
.remixicon-repeat-line:before {
  content: '\f074';
}
.remixicon-repeat-one-fill:before {
  content: '\f075';
}
.remixicon-repeat-one-line:before {
  content: '\f076';
}
.remixicon-reply-all-fill:before {
  content: '\f077';
}
.remixicon-reply-all-line:before {
  content: '\f078';
}
.remixicon-reply-fill:before {
  content: '\f079';
}
.remixicon-reply-line:before {
  content: '\f07a';
}
.remixicon-reserved-fill:before {
  content: '\f07b';
}
.remixicon-reserved-line:before {
  content: '\f07c';
}
.remixicon-rest-time-fill:before {
  content: '\f07d';
}
.remixicon-rest-time-line:before {
  content: '\f07e';
}
.remixicon-restart-fill:before {
  content: '\f07f';
}
.remixicon-restart-line:before {
  content: '\f080';
}
.remixicon-restaurant-2-fill:before {
  content: '\f081';
}
.remixicon-restaurant-2-line:before {
  content: '\f082';
}
.remixicon-restaurant-fill:before {
  content: '\f083';
}
.remixicon-restaurant-line:before {
  content: '\f084';
}
.remixicon-rewind-fill:before {
  content: '\f085';
}
.remixicon-rewind-line:before {
  content: '\f086';
}
.remixicon-rewind-mini-fill:before {
  content: '\f087';
}
.remixicon-rewind-mini-line:before {
  content: '\f088';
}
.remixicon-rhythm-fill:before {
  content: '\f089';
}
.remixicon-rhythm-line:before {
  content: '\f08a';
}
.remixicon-riding-fill:before {
  content: '\f08b';
}
.remixicon-riding-line:before {
  content: '\f08c';
}
.remixicon-road-map-fill:before {
  content: '\f08d';
}
.remixicon-road-map-line:before {
  content: '\f08e';
}
.remixicon-roadster-fill:before {
  content: '\f08f';
}
.remixicon-roadster-line:before {
  content: '\f090';
}
.remixicon-robot-fill:before {
  content: '\f091';
}
.remixicon-robot-line:before {
  content: '\f092';
}
.remixicon-rocket-2-fill:before {
  content: '\f093';
}
.remixicon-rocket-2-line:before {
  content: '\f094';
}
.remixicon-rocket-fill:before {
  content: '\f095';
}
.remixicon-rocket-line:before {
  content: '\f096';
}
.remixicon-rotate-lock-fill:before {
  content: '\f097';
}
.remixicon-rotate-lock-line:before {
  content: '\f098';
}
.remixicon-rounded-corner:before {
  content: '\f099';
}
.remixicon-route-fill:before {
  content: '\f09a';
}
.remixicon-route-line:before {
  content: '\f09b';
}
.remixicon-router-fill:before {
  content: '\f09c';
}
.remixicon-router-line:before {
  content: '\f09d';
}
.remixicon-rss-fill:before {
  content: '\f09e';
}
.remixicon-rss-line:before {
  content: '\f09f';
}
.remixicon-ruler-2-fill:before {
  content: '\f0a0';
}
.remixicon-ruler-2-line:before {
  content: '\f0a1';
}
.remixicon-ruler-fill:before {
  content: '\f0a2';
}
.remixicon-ruler-line:before {
  content: '\f0a3';
}
.remixicon-run-fill:before {
  content: '\f0a4';
}
.remixicon-run-line:before {
  content: '\f0a5';
}
.remixicon-safari-fill:before {
  content: '\f0a6';
}
.remixicon-safari-line:before {
  content: '\f0a7';
}
.remixicon-safe-2-fill:before {
  content: '\f0a8';
}
.remixicon-safe-2-line:before {
  content: '\f0a9';
}
.remixicon-safe-fill:before {
  content: '\f0aa';
}
.remixicon-safe-line:before {
  content: '\f0ab';
}
.remixicon-sailboat-fill:before {
  content: '\f0ac';
}
.remixicon-sailboat-line:before {
  content: '\f0ad';
}
.remixicon-save-2-fill:before {
  content: '\f0ae';
}
.remixicon-save-2-line:before {
  content: '\f0af';
}
.remixicon-save-3-fill:before {
  content: '\f0b0';
}
.remixicon-save-3-line:before {
  content: '\f0b1';
}
.remixicon-save-fill:before {
  content: '\f0b2';
}
.remixicon-save-line:before {
  content: '\f0b3';
}
.remixicon-scales-2-fill:before {
  content: '\f0b4';
}
.remixicon-scales-2-line:before {
  content: '\f0b5';
}
.remixicon-scales-3-fill:before {
  content: '\f0b6';
}
.remixicon-scales-3-line:before {
  content: '\f0b7';
}
.remixicon-scales-fill:before {
  content: '\f0b8';
}
.remixicon-scales-line:before {
  content: '\f0b9';
}
.remixicon-scan-2-fill:before {
  content: '\f0ba';
}
.remixicon-scan-2-line:before {
  content: '\f0bb';
}
.remixicon-scan-fill:before {
  content: '\f0bc';
}
.remixicon-scan-line:before {
  content: '\f0bd';
}
.remixicon-scissors-2-fill:before {
  content: '\f0be';
}
.remixicon-scissors-2-line:before {
  content: '\f0bf';
}
.remixicon-scissors-cut-fill:before {
  content: '\f0c0';
}
.remixicon-scissors-cut-line:before {
  content: '\f0c1';
}
.remixicon-scissors-fill:before {
  content: '\f0c2';
}
.remixicon-scissors-line:before {
  content: '\f0c3';
}
.remixicon-screenshot-2-fill:before {
  content: '\f0c4';
}
.remixicon-screenshot-2-line:before {
  content: '\f0c5';
}
.remixicon-screenshot-fill:before {
  content: '\f0c6';
}
.remixicon-screenshot-line:before {
  content: '\f0c7';
}
.remixicon-sd-card-fill:before {
  content: '\f0c8';
}
.remixicon-sd-card-line:before {
  content: '\f0c9';
}
.remixicon-sd-card-mini-fill:before {
  content: '\f0ca';
}
.remixicon-sd-card-mini-line:before {
  content: '\f0cb';
}
.remixicon-search-2-fill:before {
  content: '\f0cc';
}
.remixicon-search-2-line:before {
  content: '\f0cd';
}
.remixicon-search-eye-fill:before {
  content: '\f0ce';
}
.remixicon-search-eye-line:before {
  content: '\f0cf';
}
.remixicon-search-fill:before {
  content: '\f0d0';
}
.remixicon-search-line:before {
  content: '\f0d1';
}
.remixicon-secure-payment-fill:before {
  content: '\f0d2';
}
.remixicon-secure-payment-line:before {
  content: '\f0d3';
}
.remixicon-seedling-fill:before {
  content: '\f0d4';
}
.remixicon-seedling-line:before {
  content: '\f0d5';
}
.remixicon-send-backward:before {
  content: '\f0d6';
}
.remixicon-send-plane-2-fill:before {
  content: '\f0d7';
}
.remixicon-send-plane-2-line:before {
  content: '\f0d8';
}
.remixicon-send-plane-fill:before {
  content: '\f0d9';
}
.remixicon-send-plane-line:before {
  content: '\f0da';
}
.remixicon-send-to-back:before {
  content: '\f0db';
}
.remixicon-sensor-fill:before {
  content: '\f0dc';
}
.remixicon-sensor-line:before {
  content: '\f0dd';
}
.remixicon-separator:before {
  content: '\f0de';
}
.remixicon-server-fill:before {
  content: '\f0df';
}
.remixicon-server-line:before {
  content: '\f0e0';
}
.remixicon-service-fill:before {
  content: '\f0e1';
}
.remixicon-service-line:before {
  content: '\f0e2';
}
.remixicon-settings-2-fill:before {
  content: '\f0e3';
}
.remixicon-settings-2-line:before {
  content: '\f0e4';
}
.remixicon-settings-3-fill:before {
  content: '\f0e5';
}
.remixicon-settings-3-line:before {
  content: '\f0e6';
}
.remixicon-settings-4-fill:before {
  content: '\f0e7';
}
.remixicon-settings-4-line:before {
  content: '\f0e8';
}
.remixicon-settings-5-fill:before {
  content: '\f0e9';
}
.remixicon-settings-5-line:before {
  content: '\f0ea';
}
.remixicon-settings-6-fill:before {
  content: '\f0eb';
}
.remixicon-settings-6-line:before {
  content: '\f0ec';
}
.remixicon-settings-fill:before {
  content: '\f0ed';
}
.remixicon-settings-line:before {
  content: '\f0ee';
}
.remixicon-shape-2-fill:before {
  content: '\f0ef';
}
.remixicon-shape-2-line:before {
  content: '\f0f0';
}
.remixicon-shape-fill:before {
  content: '\f0f1';
}
.remixicon-shape-line:before {
  content: '\f0f2';
}
.remixicon-share-box-fill:before {
  content: '\f0f3';
}
.remixicon-share-box-line:before {
  content: '\f0f4';
}
.remixicon-share-circle-fill:before {
  content: '\f0f5';
}
.remixicon-share-circle-line:before {
  content: '\f0f6';
}
.remixicon-share-fill:before {
  content: '\f0f7';
}
.remixicon-share-forward-2-fill:before {
  content: '\f0f8';
}
.remixicon-share-forward-2-line:before {
  content: '\f0f9';
}
.remixicon-share-forward-box-fill:before {
  content: '\f0fa';
}
.remixicon-share-forward-box-line:before {
  content: '\f0fb';
}
.remixicon-share-forward-fill:before {
  content: '\f0fc';
}
.remixicon-share-forward-line:before {
  content: '\f0fd';
}
.remixicon-share-line:before {
  content: '\f0fe';
}
.remixicon-shield-check-fill:before {
  content: '\f0ff';
}
.remixicon-shield-check-line:before {
  content: '\f100';
}
.remixicon-shield-cross-fill:before {
  content: '\f101';
}
.remixicon-shield-cross-line:before {
  content: '\f102';
}
.remixicon-shield-fill:before {
  content: '\f103';
}
.remixicon-shield-flash-fill:before {
  content: '\f104';
}
.remixicon-shield-flash-line:before {
  content: '\f105';
}
.remixicon-shield-keyhole-fill:before {
  content: '\f106';
}
.remixicon-shield-keyhole-line:before {
  content: '\f107';
}
.remixicon-shield-line:before {
  content: '\f108';
}
.remixicon-shield-star-fill:before {
  content: '\f109';
}
.remixicon-shield-star-line:before {
  content: '\f10a';
}
.remixicon-shield-user-fill:before {
  content: '\f10b';
}
.remixicon-shield-user-line:before {
  content: '\f10c';
}
.remixicon-ship-2-fill:before {
  content: '\f10d';
}
.remixicon-ship-2-line:before {
  content: '\f10e';
}
.remixicon-ship-fill:before {
  content: '\f10f';
}
.remixicon-ship-line:before {
  content: '\f110';
}
.remixicon-shirt-fill:before {
  content: '\f111';
}
.remixicon-shirt-line:before {
  content: '\f112';
}
.remixicon-shopping-bag-2-fill:before {
  content: '\f113';
}
.remixicon-shopping-bag-2-line:before {
  content: '\f114';
}
.remixicon-shopping-bag-3-fill:before {
  content: '\f115';
}
.remixicon-shopping-bag-3-line:before {
  content: '\f116';
}
.remixicon-shopping-bag-fill:before {
  content: '\f117';
}
.remixicon-shopping-bag-line:before {
  content: '\f118';
}
.remixicon-shopping-basket-2-fill:before {
  content: '\f119';
}
.remixicon-shopping-basket-2-line:before {
  content: '\f11a';
}
.remixicon-shopping-basket-fill:before {
  content: '\f11b';
}
.remixicon-shopping-basket-line:before {
  content: '\f11c';
}
.remixicon-shopping-cart-2-fill:before {
  content: '\f11d';
}
.remixicon-shopping-cart-2-line:before {
  content: '\f11e';
}
.remixicon-shopping-cart-fill:before {
  content: '\f11f';
}
.remixicon-shopping-cart-line:before {
  content: '\f120';
}
.remixicon-showers-fill:before {
  content: '\f121';
}
.remixicon-showers-line:before {
  content: '\f122';
}
.remixicon-shuffle-fill:before {
  content: '\f123';
}
.remixicon-shuffle-line:before {
  content: '\f124';
}
.remixicon-shut-down-fill:before {
  content: '\f125';
}
.remixicon-shut-down-line:before {
  content: '\f126';
}
.remixicon-side-bar-fill:before {
  content: '\f127';
}
.remixicon-side-bar-line:before {
  content: '\f128';
}
.remixicon-signal-tower-fill:before {
  content: '\f129';
}
.remixicon-signal-tower-line:before {
  content: '\f12a';
}
.remixicon-signal-wifi-1-fill:before {
  content: '\f12b';
}
.remixicon-signal-wifi-1-line:before {
  content: '\f12c';
}
.remixicon-signal-wifi-2-fill:before {
  content: '\f12d';
}
.remixicon-signal-wifi-2-line:before {
  content: '\f12e';
}
.remixicon-signal-wifi-3-fill:before {
  content: '\f12f';
}
.remixicon-signal-wifi-3-line:before {
  content: '\f130';
}
.remixicon-signal-wifi-error-fill:before {
  content: '\f131';
}
.remixicon-signal-wifi-error-line:before {
  content: '\f132';
}
.remixicon-signal-wifi-fill:before {
  content: '\f133';
}
.remixicon-signal-wifi-line:before {
  content: '\f134';
}
.remixicon-signal-wifi-off-fill:before {
  content: '\f135';
}
.remixicon-signal-wifi-off-line:before {
  content: '\f136';
}
.remixicon-sim-card-2-fill:before {
  content: '\f137';
}
.remixicon-sim-card-2-line:before {
  content: '\f138';
}
.remixicon-sim-card-fill:before {
  content: '\f139';
}
.remixicon-sim-card-line:before {
  content: '\f13a';
}
.remixicon-single-quotes-l:before {
  content: '\f13b';
}
.remixicon-single-quotes-r:before {
  content: '\f13c';
}
.remixicon-sip-fill:before {
  content: '\f13d';
}
.remixicon-sip-line:before {
  content: '\f13e';
}
.remixicon-skip-back-fill:before {
  content: '\f13f';
}
.remixicon-skip-back-line:before {
  content: '\f140';
}
.remixicon-skip-back-mini-fill:before {
  content: '\f141';
}
.remixicon-skip-back-mini-line:before {
  content: '\f142';
}
.remixicon-skip-forward-fill:before {
  content: '\f143';
}
.remixicon-skip-forward-line:before {
  content: '\f144';
}
.remixicon-skip-forward-mini-fill:before {
  content: '\f145';
}
.remixicon-skip-forward-mini-line:before {
  content: '\f146';
}
.remixicon-skull-2-fill:before {
  content: '\f147';
}
.remixicon-skull-2-line:before {
  content: '\f148';
}
.remixicon-skull-fill:before {
  content: '\f149';
}
.remixicon-skull-line:before {
  content: '\f14a';
}
.remixicon-skype-fill:before {
  content: '\f14b';
}
.remixicon-skype-line:before {
  content: '\f14c';
}
.remixicon-slack-fill:before {
  content: '\f14d';
}
.remixicon-slack-line:before {
  content: '\f14e';
}
.remixicon-slice-fill:before {
  content: '\f14f';
}
.remixicon-slice-line:before {
  content: '\f150';
}
.remixicon-slideshow-2-fill:before {
  content: '\f151';
}
.remixicon-slideshow-2-line:before {
  content: '\f152';
}
.remixicon-slideshow-3-fill:before {
  content: '\f153';
}
.remixicon-slideshow-3-line:before {
  content: '\f154';
}
.remixicon-slideshow-4-fill:before {
  content: '\f155';
}
.remixicon-slideshow-4-line:before {
  content: '\f156';
}
.remixicon-slideshow-fill:before {
  content: '\f157';
}
.remixicon-slideshow-line:before {
  content: '\f158';
}
.remixicon-smartphone-fill:before {
  content: '\f159';
}
.remixicon-smartphone-line:before {
  content: '\f15a';
}
.remixicon-snapchat-fill:before {
  content: '\f15b';
}
.remixicon-snapchat-line:before {
  content: '\f15c';
}
.remixicon-snowy-fill:before {
  content: '\f15d';
}
.remixicon-snowy-line:before {
  content: '\f15e';
}
.remixicon-sort-asc:before {
  content: '\f15f';
}
.remixicon-sort-desc:before {
  content: '\f160';
}
.remixicon-sound-module-fill:before {
  content: '\f161';
}
.remixicon-sound-module-line:before {
  content: '\f162';
}
.remixicon-soundcloud-fill:before {
  content: '\f163';
}
.remixicon-soundcloud-line:before {
  content: '\f164';
}
.remixicon-space-ship-fill:before {
  content: '\f165';
}
.remixicon-space-ship-line:before {
  content: '\f166';
}
.remixicon-space:before {
  content: '\f167';
}
.remixicon-spam-2-fill:before {
  content: '\f168';
}
.remixicon-spam-2-line:before {
  content: '\f169';
}
.remixicon-spam-3-fill:before {
  content: '\f16a';
}
.remixicon-spam-3-line:before {
  content: '\f16b';
}
.remixicon-spam-fill:before {
  content: '\f16c';
}
.remixicon-spam-line:before {
  content: '\f16d';
}
.remixicon-speaker-2-fill:before {
  content: '\f16e';
}
.remixicon-speaker-2-line:before {
  content: '\f16f';
}
.remixicon-speaker-3-fill:before {
  content: '\f170';
}
.remixicon-speaker-3-line:before {
  content: '\f171';
}
.remixicon-speaker-fill:before {
  content: '\f172';
}
.remixicon-speaker-line:before {
  content: '\f173';
}
.remixicon-spectrum-fill:before {
  content: '\f174';
}
.remixicon-spectrum-line:before {
  content: '\f175';
}
.remixicon-speed-fill:before {
  content: '\f176';
}
.remixicon-speed-line:before {
  content: '\f177';
}
.remixicon-speed-mini-fill:before {
  content: '\f178';
}
.remixicon-speed-mini-line:before {
  content: '\f179';
}
.remixicon-split-cells-horizontal:before {
  content: '\f17a';
}
.remixicon-split-cells-vertical:before {
  content: '\f17b';
}
.remixicon-spotify-fill:before {
  content: '\f17c';
}
.remixicon-spotify-line:before {
  content: '\f17d';
}
.remixicon-spy-fill:before {
  content: '\f17e';
}
.remixicon-spy-line:before {
  content: '\f17f';
}
.remixicon-stack-fill:before {
  content: '\f180';
}
.remixicon-stack-line:before {
  content: '\f181';
}
.remixicon-stack-overflow-fill:before {
  content: '\f182';
}
.remixicon-stack-overflow-line:before {
  content: '\f183';
}
.remixicon-stackshare-fill:before {
  content: '\f184';
}
.remixicon-stackshare-line:before {
  content: '\f185';
}
.remixicon-star-fill:before {
  content: '\f186';
}
.remixicon-star-half-fill:before {
  content: '\f187';
}
.remixicon-star-half-line:before {
  content: '\f188';
}
.remixicon-star-half-s-fill:before {
  content: '\f189';
}
.remixicon-star-half-s-line:before {
  content: '\f18a';
}
.remixicon-star-line:before {
  content: '\f18b';
}
.remixicon-star-s-fill:before {
  content: '\f18c';
}
.remixicon-star-s-line:before {
  content: '\f18d';
}
.remixicon-star-smile-fill:before {
  content: '\f18e';
}
.remixicon-star-smile-line:before {
  content: '\f18f';
}
.remixicon-steam-fill:before {
  content: '\f190';
}
.remixicon-steam-line:before {
  content: '\f191';
}
.remixicon-steering-2-fill:before {
  content: '\f192';
}
.remixicon-steering-2-line:before {
  content: '\f193';
}
.remixicon-steering-fill:before {
  content: '\f194';
}
.remixicon-steering-line:before {
  content: '\f195';
}
.remixicon-stethoscope-fill:before {
  content: '\f196';
}
.remixicon-stethoscope-line:before {
  content: '\f197';
}
.remixicon-sticky-note-2-fill:before {
  content: '\f198';
}
.remixicon-sticky-note-2-line:before {
  content: '\f199';
}
.remixicon-sticky-note-fill:before {
  content: '\f19a';
}
.remixicon-sticky-note-line:before {
  content: '\f19b';
}
.remixicon-stock-fill:before {
  content: '\f19c';
}
.remixicon-stock-line:before {
  content: '\f19d';
}
.remixicon-stop-circle-fill:before {
  content: '\f19e';
}
.remixicon-stop-circle-line:before {
  content: '\f19f';
}
.remixicon-stop-fill:before {
  content: '\f1a0';
}
.remixicon-stop-line:before {
  content: '\f1a1';
}
.remixicon-stop-mini-fill:before {
  content: '\f1a2';
}
.remixicon-stop-mini-line:before {
  content: '\f1a3';
}
.remixicon-store-2-fill:before {
  content: '\f1a4';
}
.remixicon-store-2-line:before {
  content: '\f1a5';
}
.remixicon-store-3-fill:before {
  content: '\f1a6';
}
.remixicon-store-3-line:before {
  content: '\f1a7';
}
.remixicon-store-fill:before {
  content: '\f1a8';
}
.remixicon-store-line:before {
  content: '\f1a9';
}
.remixicon-strikethrough-2:before {
  content: '\f1aa';
}
.remixicon-strikethrough:before {
  content: '\f1ab';
}
.remixicon-subscript-2:before {
  content: '\f1ac';
}
.remixicon-subscript:before {
  content: '\f1ad';
}
.remixicon-subtract-fill:before {
  content: '\f1ae';
}
.remixicon-subtract-line:before {
  content: '\f1af';
}
.remixicon-subway-fill:before {
  content: '\f1b0';
}
.remixicon-subway-line:before {
  content: '\f1b1';
}
.remixicon-subway-wifi-fill:before {
  content: '\f1b2';
}
.remixicon-subway-wifi-line:before {
  content: '\f1b3';
}
.remixicon-suitcase-2-fill:before {
  content: '\f1b4';
}
.remixicon-suitcase-2-line:before {
  content: '\f1b5';
}
.remixicon-suitcase-3-fill:before {
  content: '\f1b6';
}
.remixicon-suitcase-3-line:before {
  content: '\f1b7';
}
.remixicon-suitcase-fill:before {
  content: '\f1b8';
}
.remixicon-suitcase-line:before {
  content: '\f1b9';
}
.remixicon-sun-cloudy-fill:before {
  content: '\f1ba';
}
.remixicon-sun-cloudy-line:before {
  content: '\f1bb';
}
.remixicon-sun-fill:before {
  content: '\f1bc';
}
.remixicon-sun-foggy-fill:before {
  content: '\f1bd';
}
.remixicon-sun-foggy-line:before {
  content: '\f1be';
}
.remixicon-sun-line:before {
  content: '\f1bf';
}
.remixicon-superscript-2:before {
  content: '\f1c0';
}
.remixicon-superscript:before {
  content: '\f1c1';
}
.remixicon-surgical-mask-fill:before {
  content: '\f1c2';
}
.remixicon-surgical-mask-line:before {
  content: '\f1c3';
}
.remixicon-surround-sound-fill:before {
  content: '\f1c4';
}
.remixicon-surround-sound-line:before {
  content: '\f1c5';
}
.remixicon-survey-fill:before {
  content: '\f1c6';
}
.remixicon-survey-line:before {
  content: '\f1c7';
}
.remixicon-swap-box-fill:before {
  content: '\f1c8';
}
.remixicon-swap-box-line:before {
  content: '\f1c9';
}
.remixicon-swap-fill:before {
  content: '\f1ca';
}
.remixicon-swap-line:before {
  content: '\f1cb';
}
.remixicon-switch-fill:before {
  content: '\f1cc';
}
.remixicon-switch-line:before {
  content: '\f1cd';
}
.remixicon-sword-fill:before {
  content: '\f1ce';
}
.remixicon-sword-line:before {
  content: '\f1cf';
}
.remixicon-syringe-fill:before {
  content: '\f1d0';
}
.remixicon-syringe-line:before {
  content: '\f1d1';
}
.remixicon-t-box-fill:before {
  content: '\f1d2';
}
.remixicon-t-box-line:before {
  content: '\f1d3';
}
.remixicon-t-shirt-2-fill:before {
  content: '\f1d4';
}
.remixicon-t-shirt-2-line:before {
  content: '\f1d5';
}
.remixicon-t-shirt-air-fill:before {
  content: '\f1d6';
}
.remixicon-t-shirt-air-line:before {
  content: '\f1d7';
}
.remixicon-t-shirt-fill:before {
  content: '\f1d8';
}
.remixicon-t-shirt-line:before {
  content: '\f1d9';
}
.remixicon-table-2:before {
  content: '\f1da';
}
.remixicon-table-alt-fill:before {
  content: '\f1db';
}
.remixicon-table-alt-line:before {
  content: '\f1dc';
}
.remixicon-table-fill:before {
  content: '\f1dd';
}
.remixicon-table-line:before {
  content: '\f1de';
}
.remixicon-tablet-fill:before {
  content: '\f1df';
}
.remixicon-tablet-line:before {
  content: '\f1e0';
}
.remixicon-takeaway-fill:before {
  content: '\f1e1';
}
.remixicon-takeaway-line:before {
  content: '\f1e2';
}
.remixicon-taobao-fill:before {
  content: '\f1e3';
}
.remixicon-taobao-line:before {
  content: '\f1e4';
}
.remixicon-tape-fill:before {
  content: '\f1e5';
}
.remixicon-tape-line:before {
  content: '\f1e6';
}
.remixicon-task-fill:before {
  content: '\f1e7';
}
.remixicon-task-line:before {
  content: '\f1e8';
}
.remixicon-taxi-fill:before {
  content: '\f1e9';
}
.remixicon-taxi-line:before {
  content: '\f1ea';
}
.remixicon-taxi-wifi-fill:before {
  content: '\f1eb';
}
.remixicon-taxi-wifi-line:before {
  content: '\f1ec';
}
.remixicon-team-fill:before {
  content: '\f1ed';
}
.remixicon-team-line:before {
  content: '\f1ee';
}
.remixicon-telegram-fill:before {
  content: '\f1ef';
}
.remixicon-telegram-line:before {
  content: '\f1f0';
}
.remixicon-temp-cold-fill:before {
  content: '\f1f1';
}
.remixicon-temp-cold-line:before {
  content: '\f1f2';
}
.remixicon-temp-hot-fill:before {
  content: '\f1f3';
}
.remixicon-temp-hot-line:before {
  content: '\f1f4';
}
.remixicon-terminal-box-fill:before {
  content: '\f1f5';
}
.remixicon-terminal-box-line:before {
  content: '\f1f6';
}
.remixicon-terminal-fill:before {
  content: '\f1f7';
}
.remixicon-terminal-line:before {
  content: '\f1f8';
}
.remixicon-terminal-window-fill:before {
  content: '\f1f9';
}
.remixicon-terminal-window-line:before {
  content: '\f1fa';
}
.remixicon-test-tube-fill:before {
  content: '\f1fb';
}
.remixicon-test-tube-line:before {
  content: '\f1fc';
}
.remixicon-text-direction-l:before {
  content: '\f1fd';
}
.remixicon-text-direction-r:before {
  content: '\f1fe';
}
.remixicon-text-spacing:before {
  content: '\f1ff';
}
.remixicon-text-wrap:before {
  content: '\f200';
}
.remixicon-text:before {
  content: '\f201';
}
.remixicon-thermometer-fill:before {
  content: '\f202';
}
.remixicon-thermometer-line:before {
  content: '\f203';
}
.remixicon-thumb-down-fill:before {
  content: '\f204';
}
.remixicon-thumb-down-line:before {
  content: '\f205';
}
.remixicon-thumb-up-fill:before {
  content: '\f206';
}
.remixicon-thumb-up-line:before {
  content: '\f207';
}
.remixicon-thunderstorms-fill:before {
  content: '\f208';
}
.remixicon-thunderstorms-line:before {
  content: '\f209';
}
.remixicon-ticket-2-fill:before {
  content: '\f20a';
}
.remixicon-ticket-2-line:before {
  content: '\f20b';
}
.remixicon-ticket-fill:before {
  content: '\f20c';
}
.remixicon-ticket-line:before {
  content: '\f20d';
}
.remixicon-time-fill:before {
  content: '\f20e';
}
.remixicon-time-line:before {
  content: '\f20f';
}
.remixicon-timer-2-fill:before {
  content: '\f210';
}
.remixicon-timer-2-line:before {
  content: '\f211';
}
.remixicon-timer-fill:before {
  content: '\f212';
}
.remixicon-timer-flash-fill:before {
  content: '\f213';
}
.remixicon-timer-flash-line:before {
  content: '\f214';
}
.remixicon-timer-line:before {
  content: '\f215';
}
.remixicon-todo-fill:before {
  content: '\f216';
}
.remixicon-todo-line:before {
  content: '\f217';
}
.remixicon-toggle-fill:before {
  content: '\f218';
}
.remixicon-toggle-line:before {
  content: '\f219';
}
.remixicon-tools-fill:before {
  content: '\f21a';
}
.remixicon-tools-line:before {
  content: '\f21b';
}
.remixicon-tornado-fill:before {
  content: '\f21c';
}
.remixicon-tornado-line:before {
  content: '\f21d';
}
.remixicon-trademark-fill:before {
  content: '\f21e';
}
.remixicon-trademark-line:before {
  content: '\f21f';
}
.remixicon-traffic-light-fill:before {
  content: '\f220';
}
.remixicon-traffic-light-line:before {
  content: '\f221';
}
.remixicon-train-fill:before {
  content: '\f222';
}
.remixicon-train-line:before {
  content: '\f223';
}
.remixicon-train-wifi-fill:before {
  content: '\f224';
}
.remixicon-train-wifi-line:before {
  content: '\f225';
}
.remixicon-translate-2:before {
  content: '\f226';
}
.remixicon-translate:before {
  content: '\f227';
}
.remixicon-travesti-fill:before {
  content: '\f228';
}
.remixicon-travesti-line:before {
  content: '\f229';
}
.remixicon-treasure-map-fill:before {
  content: '\f22a';
}
.remixicon-treasure-map-line:before {
  content: '\f22b';
}
.remixicon-trello-fill:before {
  content: '\f22c';
}
.remixicon-trello-line:before {
  content: '\f22d';
}
.remixicon-trophy-fill:before {
  content: '\f22e';
}
.remixicon-trophy-line:before {
  content: '\f22f';
}
.remixicon-truck-fill:before {
  content: '\f230';
}
.remixicon-truck-line:before {
  content: '\f231';
}
.remixicon-tumblr-fill:before {
  content: '\f232';
}
.remixicon-tumblr-line:before {
  content: '\f233';
}
.remixicon-tv-2-fill:before {
  content: '\f234';
}
.remixicon-tv-2-line:before {
  content: '\f235';
}
.remixicon-tv-fill:before {
  content: '\f236';
}
.remixicon-tv-line:before {
  content: '\f237';
}
.remixicon-twitch-fill:before {
  content: '\f238';
}
.remixicon-twitch-line:before {
  content: '\f239';
}
.remixicon-twitter-fill:before {
  content: '\f23a';
}
.remixicon-twitter-line:before {
  content: '\f23b';
}
.remixicon-typhoon-fill:before {
  content: '\f23c';
}
.remixicon-typhoon-line:before {
  content: '\f23d';
}
.remixicon-u-disk-fill:before {
  content: '\f23e';
}
.remixicon-u-disk-line:before {
  content: '\f23f';
}
.remixicon-ubuntu-fill:before {
  content: '\f240';
}
.remixicon-ubuntu-line:before {
  content: '\f241';
}
.remixicon-umbrella-fill:before {
  content: '\f242';
}
.remixicon-umbrella-line:before {
  content: '\f243';
}
.remixicon-underline:before {
  content: '\f244';
}
.remixicon-uninstall-fill:before {
  content: '\f245';
}
.remixicon-uninstall-line:before {
  content: '\f246';
}
.remixicon-unsplash-fill:before {
  content: '\f247';
}
.remixicon-unsplash-line:before {
  content: '\f248';
}
.remixicon-upload-2-fill:before {
  content: '\f249';
}
.remixicon-upload-2-line:before {
  content: '\f24a';
}
.remixicon-upload-cloud-2-fill:before {
  content: '\f24b';
}
.remixicon-upload-cloud-2-line:before {
  content: '\f24c';
}
.remixicon-upload-cloud-fill:before {
  content: '\f24d';
}
.remixicon-upload-cloud-line:before {
  content: '\f24e';
}
.remixicon-upload-fill:before {
  content: '\f24f';
}
.remixicon-upload-line:before {
  content: '\f250';
}
.remixicon-usb-fill:before {
  content: '\f251';
}
.remixicon-usb-line:before {
  content: '\f252';
}
.remixicon-user-2-fill:before {
  content: '\f253';
}
.remixicon-user-2-line:before {
  content: '\f254';
}
.remixicon-user-3-fill:before {
  content: '\f255';
}
.remixicon-user-3-line:before {
  content: '\f256';
}
.remixicon-user-4-fill:before {
  content: '\f257';
}
.remixicon-user-4-line:before {
  content: '\f258';
}
.remixicon-user-5-fill:before {
  content: '\f259';
}
.remixicon-user-5-line:before {
  content: '\f25a';
}
.remixicon-user-6-fill:before {
  content: '\f25b';
}
.remixicon-user-6-line:before {
  content: '\f25c';
}
.remixicon-user-add-fill:before {
  content: '\f25d';
}
.remixicon-user-add-line:before {
  content: '\f25e';
}
.remixicon-user-fill:before {
  content: '\f25f';
}
.remixicon-user-follow-fill:before {
  content: '\f260';
}
.remixicon-user-follow-line:before {
  content: '\f261';
}
.remixicon-user-heart-fill:before {
  content: '\f262';
}
.remixicon-user-heart-line:before {
  content: '\f263';
}
.remixicon-user-line:before {
  content: '\f264';
}
.remixicon-user-location-fill:before {
  content: '\f265';
}
.remixicon-user-location-line:before {
  content: '\f266';
}
.remixicon-user-received-2-fill:before {
  content: '\f267';
}
.remixicon-user-received-2-line:before {
  content: '\f268';
}
.remixicon-user-received-fill:before {
  content: '\f269';
}
.remixicon-user-received-line:before {
  content: '\f26a';
}
.remixicon-user-search-fill:before {
  content: '\f26b';
}
.remixicon-user-search-line:before {
  content: '\f26c';
}
.remixicon-user-settings-fill:before {
  content: '\f26d';
}
.remixicon-user-settings-line:before {
  content: '\f26e';
}
.remixicon-user-shared-2-fill:before {
  content: '\f26f';
}
.remixicon-user-shared-2-line:before {
  content: '\f270';
}
.remixicon-user-shared-fill:before {
  content: '\f271';
}
.remixicon-user-shared-line:before {
  content: '\f272';
}
.remixicon-user-smile-fill:before {
  content: '\f273';
}
.remixicon-user-smile-line:before {
  content: '\f274';
}
.remixicon-user-star-fill:before {
  content: '\f275';
}
.remixicon-user-star-line:before {
  content: '\f276';
}
.remixicon-user-unfollow-fill:before {
  content: '\f277';
}
.remixicon-user-unfollow-line:before {
  content: '\f278';
}
.remixicon-user-voice-fill:before {
  content: '\f279';
}
.remixicon-user-voice-line:before {
  content: '\f27a';
}
.remixicon-video-add-fill:before {
  content: '\f27b';
}
.remixicon-video-add-line:before {
  content: '\f27c';
}
.remixicon-video-chat-fill:before {
  content: '\f27d';
}
.remixicon-video-chat-line:before {
  content: '\f27e';
}
.remixicon-video-download-fill:before {
  content: '\f27f';
}
.remixicon-video-download-line:before {
  content: '\f280';
}
.remixicon-video-fill:before {
  content: '\f281';
}
.remixicon-video-line:before {
  content: '\f282';
}
.remixicon-video-upload-fill:before {
  content: '\f283';
}
.remixicon-video-upload-line:before {
  content: '\f284';
}
.remixicon-vidicon-2-fill:before {
  content: '\f285';
}
.remixicon-vidicon-2-line:before {
  content: '\f286';
}
.remixicon-vidicon-fill:before {
  content: '\f287';
}
.remixicon-vidicon-line:before {
  content: '\f288';
}
.remixicon-vimeo-fill:before {
  content: '\f289';
}
.remixicon-vimeo-line:before {
  content: '\f28a';
}
.remixicon-vip-crown-2-fill:before {
  content: '\f28b';
}
.remixicon-vip-crown-2-line:before {
  content: '\f28c';
}
.remixicon-vip-crown-fill:before {
  content: '\f28d';
}
.remixicon-vip-crown-line:before {
  content: '\f28e';
}
.remixicon-vip-diamond-fill:before {
  content: '\f28f';
}
.remixicon-vip-diamond-line:before {
  content: '\f290';
}
.remixicon-vip-fill:before {
  content: '\f291';
}
.remixicon-vip-line:before {
  content: '\f292';
}
.remixicon-virus-fill:before {
  content: '\f293';
}
.remixicon-virus-line:before {
  content: '\f294';
}
.remixicon-visa-fill:before {
  content: '\f295';
}
.remixicon-visa-line:before {
  content: '\f296';
}
.remixicon-voice-recognition-fill:before {
  content: '\f297';
}
.remixicon-voice-recognition-line:before {
  content: '\f298';
}
.remixicon-voiceprint-fill:before {
  content: '\f299';
}
.remixicon-voiceprint-line:before {
  content: '\f29a';
}
.remixicon-volume-down-fill:before {
  content: '\f29b';
}
.remixicon-volume-down-line:before {
  content: '\f29c';
}
.remixicon-volume-mute-fill:before {
  content: '\f29d';
}
.remixicon-volume-mute-line:before {
  content: '\f29e';
}
.remixicon-volume-off-vibrate-fill:before {
  content: '\f29f';
}
.remixicon-volume-off-vibrate-line:before {
  content: '\f2a0';
}
.remixicon-volume-up-fill:before {
  content: '\f2a1';
}
.remixicon-volume-up-line:before {
  content: '\f2a2';
}
.remixicon-volume-vibrate-fill:before {
  content: '\f2a3';
}
.remixicon-volume-vibrate-line:before {
  content: '\f2a4';
}
.remixicon-vuejs-fill:before {
  content: '\f2a5';
}
.remixicon-vuejs-line:before {
  content: '\f2a6';
}
.remixicon-walk-fill:before {
  content: '\f2a7';
}
.remixicon-walk-line:before {
  content: '\f2a8';
}
.remixicon-wallet-2-fill:before {
  content: '\f2a9';
}
.remixicon-wallet-2-line:before {
  content: '\f2aa';
}
.remixicon-wallet-3-fill:before {
  content: '\f2ab';
}
.remixicon-wallet-3-line:before {
  content: '\f2ac';
}
.remixicon-wallet-fill:before {
  content: '\f2ad';
}
.remixicon-wallet-line:before {
  content: '\f2ae';
}
.remixicon-water-flash-fill:before {
  content: '\f2af';
}
.remixicon-water-flash-line:before {
  content: '\f2b0';
}
.remixicon-webcam-fill:before {
  content: '\f2b1';
}
.remixicon-webcam-line:before {
  content: '\f2b2';
}
.remixicon-wechat-2-fill:before {
  content: '\f2b3';
}
.remixicon-wechat-2-line:before {
  content: '\f2b4';
}
.remixicon-wechat-fill:before {
  content: '\f2b5';
}
.remixicon-wechat-line:before {
  content: '\f2b6';
}
.remixicon-wechat-pay-fill:before {
  content: '\f2b7';
}
.remixicon-wechat-pay-line:before {
  content: '\f2b8';
}
.remixicon-weibo-fill:before {
  content: '\f2b9';
}
.remixicon-weibo-line:before {
  content: '\f2ba';
}
.remixicon-whatsapp-fill:before {
  content: '\f2bb';
}
.remixicon-whatsapp-line:before {
  content: '\f2bc';
}
.remixicon-wheelchair-fill:before {
  content: '\f2bd';
}
.remixicon-wheelchair-line:before {
  content: '\f2be';
}
.remixicon-wifi-fill:before {
  content: '\f2bf';
}
.remixicon-wifi-line:before {
  content: '\f2c0';
}
.remixicon-wifi-off-fill:before {
  content: '\f2c1';
}
.remixicon-wifi-off-line:before {
  content: '\f2c2';
}
.remixicon-window-2-fill:before {
  content: '\f2c3';
}
.remixicon-window-2-line:before {
  content: '\f2c4';
}
.remixicon-window-fill:before {
  content: '\f2c5';
}
.remixicon-window-line:before {
  content: '\f2c6';
}
.remixicon-windows-fill:before {
  content: '\f2c7';
}
.remixicon-windows-line:before {
  content: '\f2c8';
}
.remixicon-windy-fill:before {
  content: '\f2c9';
}
.remixicon-windy-line:before {
  content: '\f2ca';
}
.remixicon-wireless-charging-fill:before {
  content: '\f2cb';
}
.remixicon-wireless-charging-line:before {
  content: '\f2cc';
}
.remixicon-women-fill:before {
  content: '\f2cd';
}
.remixicon-women-line:before {
  content: '\f2ce';
}
.remixicon-wubi-input:before {
  content: '\f2cf';
}
.remixicon-xbox-fill:before {
  content: '\f2d0';
}
.remixicon-xbox-line:before {
  content: '\f2d1';
}
.remixicon-xing-fill:before {
  content: '\f2d2';
}
.remixicon-xing-line:before {
  content: '\f2d3';
}
.remixicon-youtube-fill:before {
  content: '\f2d4';
}
.remixicon-youtube-line:before {
  content: '\f2d5';
}
.remixicon-zcool-fill:before {
  content: '\f2d6';
}
.remixicon-zcool-line:before {
  content: '\f2d7';
}
.remixicon-zhihu-fill:before {
  content: '\f2d8';
}
.remixicon-zhihu-line:before {
  content: '\f2d9';
}
.remixicon-zoom-in-fill:before {
  content: '\f2da';
}
.remixicon-zoom-in-line:before {
  content: '\f2db';
}
.remixicon-zoom-out-fill:before {
  content: '\f2dc';
}
.remixicon-zoom-out-line:before {
  content: '\f2dd';
}
.remixicon-zzz-fill:before {
  content: '\f2de';
}
.remixicon-zzz-line:before {
  content: '\f2df';
}
.remixicon-arrow-down-double-fill:before {
  content: '\f2e0';
}
.remixicon-arrow-down-double-line:before {
  content: '\f2e1';
}
.remixicon-arrow-left-double-fill:before {
  content: '\f2e2';
}
.remixicon-arrow-left-double-line:before {
  content: '\f2e3';
}
.remixicon-arrow-right-double-fill:before {
  content: '\f2e4';
}
.remixicon-arrow-right-double-line:before {
  content: '\f2e5';
}
.remixicon-arrow-turn-back-fill:before {
  content: '\f2e6';
}
.remixicon-arrow-turn-back-line:before {
  content: '\f2e7';
}
.remixicon-arrow-turn-forward-fill:before {
  content: '\f2e8';
}
.remixicon-arrow-turn-forward-line:before {
  content: '\f2e9';
}
.remixicon-arrow-up-double-fill:before {
  content: '\f2ea';
}
.remixicon-arrow-up-double-line:before {
  content: '\f2eb';
}
.remixicon-bard-fill:before {
  content: '\f2ec';
}
.remixicon-bard-line:before {
  content: '\f2ed';
}
.remixicon-bootstrap-fill:before {
  content: '\f2ee';
}
.remixicon-bootstrap-line:before {
  content: '\f2ef';
}
.remixicon-box-1-fill:before {
  content: '\f2f0';
}
.remixicon-box-1-line:before {
  content: '\f2f1';
}
.remixicon-box-2-fill:before {
  content: '\f2f2';
}
.remixicon-box-2-line:before {
  content: '\f2f3';
}
.remixicon-box-3-fill:before {
  content: '\f2f4';
}
.remixicon-box-3-line:before {
  content: '\f2f5';
}
.remixicon-brain-fill:before {
  content: '\f2f6';
}
.remixicon-brain-line:before {
  content: '\f2f7';
}
.remixicon-candle-fill:before {
  content: '\f2f8';
}
.remixicon-candle-line:before {
  content: '\f2f9';
}
.remixicon-cash-fill:before {
  content: '\f2fa';
}
.remixicon-cash-line:before {
  content: '\f2fb';
}
.remixicon-contract-left-fill:before {
  content: '\f2fc';
}
.remixicon-contract-left-line:before {
  content: '\f2fd';
}
.remixicon-contract-left-right-fill:before {
  content: '\f2fe';
}
.remixicon-contract-left-right-line:before {
  content: '\f2ff';
}
.remixicon-contract-right-fill:before {
  content: '\f300';
}
.remixicon-contract-right-line:before {
  content: '\f301';
}
.remixicon-contract-up-down-fill:before {
  content: '\f302';
}
.remixicon-contract-up-down-line:before {
  content: '\f303';
}
.remixicon-copilot-fill:before {
  content: '\f304';
}
.remixicon-copilot-line:before {
  content: '\f305';
}
.remixicon-corner-down-left-fill:before {
  content: '\f306';
}
.remixicon-corner-down-left-line:before {
  content: '\f307';
}
.remixicon-corner-down-right-fill:before {
  content: '\f308';
}
.remixicon-corner-down-right-line:before {
  content: '\f309';
}
.remixicon-corner-left-down-fill:before {
  content: '\f30a';
}
.remixicon-corner-left-down-line:before {
  content: '\f30b';
}
.remixicon-corner-left-up-fill:before {
  content: '\f30c';
}
.remixicon-corner-left-up-line:before {
  content: '\f30d';
}
.remixicon-corner-right-down-fill:before {
  content: '\f30e';
}
.remixicon-corner-right-down-line:before {
  content: '\f30f';
}
.remixicon-corner-right-up-fill:before {
  content: '\f310';
}
.remixicon-corner-right-up-line:before {
  content: '\f311';
}
.remixicon-corner-up-left-double-fill:before {
  content: '\f312';
}
.remixicon-corner-up-left-double-line:before {
  content: '\f313';
}
.remixicon-corner-up-left-fill:before {
  content: '\f314';
}
.remixicon-corner-up-left-line:before {
  content: '\f315';
}
.remixicon-corner-up-right-double-fill:before {
  content: '\f316';
}
.remixicon-corner-up-right-double-line:before {
  content: '\f317';
}
.remixicon-corner-up-right-fill:before {
  content: '\f318';
}
.remixicon-corner-up-right-line:before {
  content: '\f319';
}
.remixicon-cross-fill:before {
  content: '\f31a';
}
.remixicon-cross-line:before {
  content: '\f31b';
}
.remixicon-edge-new-fill:before {
  content: '\f31c';
}
.remixicon-edge-new-line:before {
  content: '\f31d';
}
.remixicon-equal-fill:before {
  content: '\f31e';
}
.remixicon-equal-line:before {
  content: '\f31f';
}
.remixicon-expand-left-fill:before {
  content: '\f320';
}
.remixicon-expand-left-line:before {
  content: '\f321';
}
.remixicon-expand-left-right-fill:before {
  content: '\f322';
}
.remixicon-expand-left-right-line:before {
  content: '\f323';
}
.remixicon-expand-right-fill:before {
  content: '\f324';
}
.remixicon-expand-right-line:before {
  content: '\f325';
}
.remixicon-expand-up-down-fill:before {
  content: '\f326';
}
.remixicon-expand-up-down-line:before {
  content: '\f327';
}
.remixicon-flickr-fill:before {
  content: '\f328';
}
.remixicon-flickr-line:before {
  content: '\f329';
}
.remixicon-forward-10-fill:before {
  content: '\f32a';
}
.remixicon-forward-10-line:before {
  content: '\f32b';
}
.remixicon-forward-15-fill:before {
  content: '\f32c';
}
.remixicon-forward-15-line:before {
  content: '\f32d';
}
.remixicon-forward-30-fill:before {
  content: '\f32e';
}
.remixicon-forward-30-line:before {
  content: '\f32f';
}
.remixicon-forward-5-fill:before {
  content: '\f330';
}
.remixicon-forward-5-line:before {
  content: '\f331';
}
.remixicon-graduation-cap-fill:before {
  content: '\f332';
}
.remixicon-graduation-cap-line:before {
  content: '\f333';
}
.remixicon-home-office-fill:before {
  content: '\f334';
}
.remixicon-home-office-line:before {
  content: '\f335';
}
.remixicon-hourglass-2-fill:before {
  content: '\f336';
}
.remixicon-hourglass-2-line:before {
  content: '\f337';
}
.remixicon-hourglass-fill:before {
  content: '\f338';
}
.remixicon-hourglass-line:before {
  content: '\f339';
}
.remixicon-javascript-fill:before {
  content: '\f33a';
}
.remixicon-javascript-line:before {
  content: '\f33b';
}
.remixicon-loop-left-fill:before {
  content: '\f33c';
}
.remixicon-loop-left-line:before {
  content: '\f33d';
}
.remixicon-loop-right-fill:before {
  content: '\f33e';
}
.remixicon-loop-right-line:before {
  content: '\f33f';
}
.remixicon-memories-fill:before {
  content: '\f340';
}
.remixicon-memories-line:before {
  content: '\f341';
}
.remixicon-meta-fill:before {
  content: '\f342';
}
.remixicon-meta-line:before {
  content: '\f343';
}
.remixicon-microsoft-loop-fill:before {
  content: '\f344';
}
.remixicon-microsoft-loop-line:before {
  content: '\f345';
}
.remixicon-nft-fill:before {
  content: '\f346';
}
.remixicon-nft-line:before {
  content: '\f347';
}
.remixicon-notion-fill:before {
  content: '\f348';
}
.remixicon-notion-line:before {
  content: '\f349';
}
.remixicon-openai-fill:before {
  content: '\f34a';
}
.remixicon-openai-line:before {
  content: '\f34b';
}
.remixicon-overline:before {
  content: '\f34c';
}
.remixicon-p2p-fill:before {
  content: '\f34d';
}
.remixicon-p2p-line:before {
  content: '\f34e';
}
.remixicon-presentation-fill:before {
  content: '\f34f';
}
.remixicon-presentation-line:before {
  content: '\f350';
}
.remixicon-replay-10-fill:before {
  content: '\f351';
}
.remixicon-replay-10-line:before {
  content: '\f352';
}
.remixicon-replay-15-fill:before {
  content: '\f353';
}
.remixicon-replay-15-line:before {
  content: '\f354';
}
.remixicon-replay-30-fill:before {
  content: '\f355';
}
.remixicon-replay-30-line:before {
  content: '\f356';
}
.remixicon-replay-5-fill:before {
  content: '\f357';
}
.remixicon-replay-5-line:before {
  content: '\f358';
}
.remixicon-school-fill:before {
  content: '\f359';
}
.remixicon-school-line:before {
  content: '\f35a';
}
.remixicon-shining-2-fill:before {
  content: '\f35b';
}
.remixicon-shining-2-line:before {
  content: '\f35c';
}
.remixicon-shining-fill:before {
  content: '\f35d';
}
.remixicon-shining-line:before {
  content: '\f35e';
}
.remixicon-sketching:before {
  content: '\f35f';
}
.remixicon-skip-down-fill:before {
  content: '\f360';
}
.remixicon-skip-down-line:before {
  content: '\f361';
}
.remixicon-skip-left-fill:before {
  content: '\f362';
}
.remixicon-skip-left-line:before {
  content: '\f363';
}
.remixicon-skip-right-fill:before {
  content: '\f364';
}
.remixicon-skip-right-line:before {
  content: '\f365';
}
.remixicon-skip-up-fill:before {
  content: '\f366';
}
.remixicon-skip-up-line:before {
  content: '\f367';
}
.remixicon-slow-down-fill:before {
  content: '\f368';
}
.remixicon-slow-down-line:before {
  content: '\f369';
}
.remixicon-sparkling-2-fill:before {
  content: '\f36a';
}
.remixicon-sparkling-2-line:before {
  content: '\f36b';
}
.remixicon-sparkling-fill:before {
  content: '\f36c';
}
.remixicon-sparkling-line:before {
  content: '\f36d';
}
.remixicon-speak-fill:before {
  content: '\f36e';
}
.remixicon-speak-line:before {
  content: '\f36f';
}
.remixicon-speed-up-fill:before {
  content: '\f370';
}
.remixicon-speed-up-line:before {
  content: '\f371';
}
.remixicon-tiktok-fill:before {
  content: '\f372';
}
.remixicon-tiktok-line:before {
  content: '\f373';
}
.remixicon-token-swap-fill:before {
  content: '\f374';
}
.remixicon-token-swap-line:before {
  content: '\f375';
}
.remixicon-unpin-fill:before {
  content: '\f376';
}
.remixicon-unpin-line:before {
  content: '\f377';
}
.remixicon-wechat-channels-fill:before {
  content: '\f378';
}
.remixicon-wechat-channels-line:before {
  content: '\f379';
}
.remixicon-wordpress-fill:before {
  content: '\f37a';
}
.remixicon-wordpress-line:before {
  content: '\f37b';
}
.remixicon-blender-fill:before {
  content: '\f37c';
}
.remixicon-blender-line:before {
  content: '\f37d';
}
.remixicon-emoji-sticker-fill:before {
  content: '\f37e';
}
.remixicon-emoji-sticker-line:before {
  content: '\f37f';
}
.remixicon-git-close-pull-request-fill:before {
  content: '\f380';
}
.remixicon-git-close-pull-request-line:before {
  content: '\f381';
}
.remixicon-instance-fill:before {
  content: '\f382';
}
.remixicon-instance-line:before {
  content: '\f383';
}
.remixicon-megaphone-fill:before {
  content: '\f384';
}
.remixicon-megaphone-line:before {
  content: '\f385';
}
.remixicon-pass-expired-fill:before {
  content: '\f386';
}
.remixicon-pass-expired-line:before {
  content: '\f387';
}
.remixicon-pass-pending-fill:before {
  content: '\f388';
}
.remixicon-pass-pending-line:before {
  content: '\f389';
}
.remixicon-pass-valid-fill:before {
  content: '\f38a';
}
.remixicon-pass-valid-line:before {
  content: '\f38b';
}
.remixicon-ai-generate:before {
  content: '\f38c';
}
.remixicon-calendar-close-fill:before {
  content: '\f38d';
}
.remixicon-calendar-close-line:before {
  content: '\f38e';
}
.remixicon-draggable:before {
  content: '\f38f';
}
.remixicon-font-family:before {
  content: '\f390';
}
.remixicon-font-mono:before {
  content: '\f391';
}
.remixicon-font-sans-serif:before {
  content: '\f392';
}
.remixicon-font-sans:before {
  content: '\f393';
}
.remixicon-hard-drive-3-fill:before {
  content: '\f394';
}
.remixicon-hard-drive-3-line:before {
  content: '\f395';
}
.remixicon-kick-fill:before {
  content: '\f396';
}
.remixicon-kick-line:before {
  content: '\f397';
}
.remixicon-list-check-3:before {
  content: '\f398';
}
.remixicon-list-indefinite:before {
  content: '\f399';
}
.remixicon-list-ordered-2:before {
  content: '\f39a';
}
.remixicon-list-radio:before {
  content: '\f39b';
}
.remixicon-openbase-fill:before {
  content: '\f39c';
}
.remixicon-openbase-line:before {
  content: '\f39d';
}
.remixicon-planet-fill:before {
  content: '\f39e';
}
.remixicon-planet-line:before {
  content: '\f39f';
}
.remixicon-prohibited-fill:before {
  content: '\f3a0';
}
.remixicon-prohibited-line:before {
  content: '\f3a1';
}
.remixicon-quote-text:before {
  content: '\f3a2';
}
.remixicon-seo-fill:before {
  content: '\f3a3';
}
.remixicon-seo-line:before {
  content: '\f3a4';
}
.remixicon-slash-commands:before {
  content: '\f3a5';
}
.remixicon-archive-2-fill:before {
  content: '\f3a6';
}
.remixicon-archive-2-line:before {
  content: '\f3a7';
}
.remixicon-inbox-2-fill:before {
  content: '\f3a8';
}
.remixicon-inbox-2-line:before {
  content: '\f3a9';
}
.remixicon-shake-hands-fill:before {
  content: '\f3aa';
}
.remixicon-shake-hands-line:before {
  content: '\f3ab';
}
.remixicon-supabase-fill:before {
  content: '\f3ac';
}
.remixicon-supabase-line:before {
  content: '\f3ad';
}
.remixicon-water-percent-fill:before {
  content: '\f3ae';
}
.remixicon-water-percent-line:before {
  content: '\f3af';
}
.remixicon-yuque-fill:before {
  content: '\f3b0';
}
.remixicon-yuque-line:before {
  content: '\f3b1';
}
.remixicon-crosshair-2-fill:before {
  content: '\f3b2';
}
.remixicon-crosshair-2-line:before {
  content: '\f3b3';
}
.remixicon-crosshair-fill:before {
  content: '\f3b4';
}
.remixicon-crosshair-line:before {
  content: '\f3b5';
}
.remixicon-file-close-fill:before {
  content: '\f3b6';
}
.remixicon-file-close-line:before {
  content: '\f3b7';
}
.remixicon-infinity-fill:before {
  content: '\f3b8';
}
.remixicon-infinity-line:before {
  content: '\f3b9';
}
.remixicon-rfid-fill:before {
  content: '\f3ba';
}
.remixicon-rfid-line:before {
  content: '\f3bb';
}
.remixicon-slash-commands-2:before {
  content: '\f3bc';
}
.remixicon-user-forbid-fill:before {
  content: '\f3bd';
}
.remixicon-user-forbid-line:before {
  content: '\f3be';
}
.remixicon-beer-fill:before {
  content: '\f3bf';
}
.remixicon-beer-line:before {
  content: '\f3c0';
}
.remixicon-circle-fill:before {
  content: '\f3c1';
}
.remixicon-circle-line:before {
  content: '\f3c2';
}
.remixicon-dropdown-list:before {
  content: '\f3c3';
}
.remixicon-file-image-fill:before {
  content: '\f3c4';
}
.remixicon-file-image-line:before {
  content: '\f3c5';
}
.remixicon-file-pdf-2-fill:before {
  content: '\f3c6';
}
.remixicon-file-pdf-2-line:before {
  content: '\f3c7';
}
.remixicon-file-video-fill:before {
  content: '\f3c8';
}
.remixicon-file-video-line:before {
  content: '\f3c9';
}
.remixicon-folder-image-fill:before {
  content: '\f3ca';
}
.remixicon-folder-image-line:before {
  content: '\f3cb';
}
.remixicon-folder-video-fill:before {
  content: '\f3cc';
}
.remixicon-folder-video-line:before {
  content: '\f3cd';
}
.remixicon-hexagon-fill:before {
  content: '\f3ce';
}
.remixicon-hexagon-line:before {
  content: '\f3cf';
}
.remixicon-menu-search-fill:before {
  content: '\f3d0';
}
.remixicon-menu-search-line:before {
  content: '\f3d1';
}
.remixicon-octagon-fill:before {
  content: '\f3d2';
}
.remixicon-octagon-line:before {
  content: '\f3d3';
}
.remixicon-pentagon-fill:before {
  content: '\f3d4';
}
.remixicon-pentagon-line:before {
  content: '\f3d5';
}
.remixicon-rectangle-fill:before {
  content: '\f3d6';
}
.remixicon-rectangle-line:before {
  content: '\f3d7';
}
.remixicon-robot-2-fill:before {
  content: '\f3d8';
}
.remixicon-robot-2-line:before {
  content: '\f3d9';
}
.remixicon-shapes-fill:before {
  content: '\f3da';
}
.remixicon-shapes-line:before {
  content: '\f3db';
}
.remixicon-square-fill:before {
  content: '\f3dc';
}
.remixicon-square-line:before {
  content: '\f3dd';
}
.remixicon-tent-fill:before {
  content: '\f3de';
}
.remixicon-tent-line:before {
  content: '\f3df';
}
.remixicon-threads-fill:before {
  content: '\f3e0';
}
.remixicon-threads-line:before {
  content: '\f3e1';
}
.remixicon-tree-fill:before {
  content: '\f3e2';
}
.remixicon-tree-line:before {
  content: '\f3e3';
}
.remixicon-triangle-fill:before {
  content: '\f3e4';
}
.remixicon-triangle-line:before {
  content: '\f3e5';
}
.remixicon-twitter-x-fill:before {
  content: '\f3e6';
}
.remixicon-twitter-x-line:before {
  content: '\f3e7';
}
.remixicon-verified-badge-fill:before {
  content: '\f3e8';
}
.remixicon-verified-badge-line:before {
  content: '\f3e9';
}
.remixicon-armchair-fill:before {
  content: '\f3ea';
}
.remixicon-armchair-line:before {
  content: '\f3eb';
}
.remixicon-bnb-fill:before {
  content: '\f3ec';
}
.remixicon-bnb-line:before {
  content: '\f3ed';
}
.remixicon-bread-fill:before {
  content: '\f3ee';
}
.remixicon-bread-line:before {
  content: '\f3ef';
}
.remixicon-btc-fill:before {
  content: '\f3f0';
}
.remixicon-btc-line:before {
  content: '\f3f1';
}
.remixicon-calendar-schedule-fill:before {
  content: '\f3f2';
}
.remixicon-calendar-schedule-line:before {
  content: '\f3f3';
}
.remixicon-dice-1-fill:before {
  content: '\f3f4';
}
.remixicon-dice-1-line:before {
  content: '\f3f5';
}
.remixicon-dice-2-fill:before {
  content: '\f3f6';
}
.remixicon-dice-2-line:before {
  content: '\f3f7';
}
.remixicon-dice-3-fill:before {
  content: '\f3f8';
}
.remixicon-dice-3-line:before {
  content: '\f3f9';
}
.remixicon-dice-4-fill:before {
  content: '\f3fa';
}
.remixicon-dice-4-line:before {
  content: '\f3fb';
}
.remixicon-dice-5-fill:before {
  content: '\f3fc';
}
.remixicon-dice-5-line:before {
  content: '\f3fd';
}
.remixicon-dice-6-fill:before {
  content: '\f3fe';
}
.remixicon-dice-6-line:before {
  content: '\f3ff';
}
.remixicon-dice-fill:before {
  content: '\f400';
}
.remixicon-dice-line:before {
  content: '\f401';
}
.remixicon-drinks-fill:before {
  content: '\f402';
}
.remixicon-drinks-line:before {
  content: '\f403';
}
.remixicon-equalizer-2-fill:before {
  content: '\f404';
}
.remixicon-equalizer-2-line:before {
  content: '\f405';
}
.remixicon-equalizer-3-fill:before {
  content: '\f406';
}
.remixicon-equalizer-3-line:before {
  content: '\f407';
}
.remixicon-eth-fill:before {
  content: '\f408';
}
.remixicon-eth-line:before {
  content: '\f409';
}
.remixicon-flower-fill:before {
  content: '\f40a';
}
.remixicon-flower-line:before {
  content: '\f40b';
}
.remixicon-glasses-2-fill:before {
  content: '\f40c';
}
.remixicon-glasses-2-line:before {
  content: '\f40d';
}
.remixicon-glasses-fill:before {
  content: '\f40e';
}
.remixicon-glasses-line:before {
  content: '\f40f';
}
.remixicon-goggles-fill:before {
  content: '\f410';
}
.remixicon-goggles-line:before {
  content: '\f411';
}
.remixicon-image-circle-fill:before {
  content: '\f412';
}
.remixicon-image-circle-line:before {
  content: '\f413';
}
.remixicon-info-i:before {
  content: '\f414';
}
.remixicon-money-rupee-circle-fill:before {
  content: '\f415';
}
.remixicon-money-rupee-circle-line:before {
  content: '\f416';
}
.remixicon-news-fill:before {
  content: '\f417';
}
.remixicon-news-line:before {
  content: '\f418';
}
.remixicon-robot-3-fill:before {
  content: '\f419';
}
.remixicon-robot-3-line:before {
  content: '\f41a';
}
.remixicon-share-2-fill:before {
  content: '\f41b';
}
.remixicon-share-2-line:before {
  content: '\f41c';
}
.remixicon-sofa-fill:before {
  content: '\f41d';
}
.remixicon-sofa-line:before {
  content: '\f41e';
}
.remixicon-svelte-fill:before {
  content: '\f41f';
}
.remixicon-svelte-line:before {
  content: '\f420';
}
.remixicon-vk-fill:before {
  content: '\f421';
}
.remixicon-vk-line:before {
  content: '\f422';
}
.remixicon-xrp-fill:before {
  content: '\f423';
}
.remixicon-xrp-line:before {
  content: '\f424';
}
.remixicon-xtz-fill:before {
  content: '\f425';
}
.remixicon-xtz-line:before {
  content: '\f426';
}
.remixicon-archive-stack-fill:before {
  content: '\f427';
}
.remixicon-archive-stack-line:before {
  content: '\f428';
}
.remixicon-bowl-fill:before {
  content: '\f429';
}
.remixicon-bowl-line:before {
  content: '\f42a';
}
.remixicon-calendar-view:before {
  content: '\f42b';
}
.remixicon-carousel-view:before {
  content: '\f42c';
}
.remixicon-code-block:before {
  content: '\f42d';
}
.remixicon-color-filter-fill:before {
  content: '\f42e';
}
.remixicon-color-filter-line:before {
  content: '\f42f';
}
.remixicon-contacts-book-3-fill:before {
  content: '\f430';
}
.remixicon-contacts-book-3-line:before {
  content: '\f431';
}
.remixicon-contract-fill:before {
  content: '\f432';
}
.remixicon-contract-line:before {
  content: '\f433';
}
.remixicon-drinks-2-fill:before {
  content: '\f434';
}
.remixicon-drinks-2-line:before {
  content: '\f435';
}
.remixicon-export-fill:before {
  content: '\f436';
}
.remixicon-export-line:before {
  content: '\f437';
}
.remixicon-file-check-fill:before {
  content: '\f438';
}
.remixicon-file-check-line:before {
  content: '\f439';
}
.remixicon-focus-mode:before {
  content: '\f43a';
}
.remixicon-folder-6-fill:before {
  content: '\f43b';
}
.remixicon-folder-6-line:before {
  content: '\f43c';
}
.remixicon-folder-check-fill:before {
  content: '\f43d';
}
.remixicon-folder-check-line:before {
  content: '\f43e';
}
.remixicon-folder-close-fill:before {
  content: '\f43f';
}
.remixicon-folder-close-line:before {
  content: '\f440';
}
.remixicon-folder-cloud-fill:before {
  content: '\f441';
}
.remixicon-folder-cloud-line:before {
  content: '\f442';
}
.remixicon-gallery-view-2:before {
  content: '\f443';
}
.remixicon-gallery-view:before {
  content: '\f444';
}
.remixicon-hand:before {
  content: '\f445';
}
.remixicon-import-fill:before {
  content: '\f446';
}
.remixicon-import-line:before {
  content: '\f447';
}
.remixicon-information-2-fill:before {
  content: '\f448';
}
.remixicon-information-2-line:before {
  content: '\f449';
}
.remixicon-kanban-view-2:before {
  content: '\f44a';
}
.remixicon-kanban-view:before {
  content: '\f44b';
}
.remixicon-list-view:before {
  content: '\f44c';
}
.remixicon-lock-star-fill:before {
  content: '\f44d';
}
.remixicon-lock-star-line:before {
  content: '\f44e';
}
.remixicon-puzzle-2-fill:before {
  content: '\f44f';
}
.remixicon-puzzle-2-line:before {
  content: '\f450';
}
.remixicon-puzzle-fill:before {
  content: '\f451';
}
.remixicon-puzzle-line:before {
  content: '\f452';
}
.remixicon-ram-2-fill:before {
  content: '\f453';
}
.remixicon-ram-2-line:before {
  content: '\f454';
}
.remixicon-ram-fill:before {
  content: '\f455';
}
.remixicon-ram-line:before {
  content: '\f456';
}
.remixicon-receipt-fill:before {
  content: '\f457';
}
.remixicon-receipt-line:before {
  content: '\f458';
}
.remixicon-shadow-fill:before {
  content: '\f459';
}
.remixicon-shadow-line:before {
  content: '\f45a';
}
.remixicon-sidebar-fold-fill:before {
  content: '\f45b';
}
.remixicon-sidebar-fold-line:before {
  content: '\f45c';
}
.remixicon-sidebar-unfold-fill:before {
  content: '\f45d';
}
.remixicon-sidebar-unfold-line:before {
  content: '\f45e';
}
.remixicon-slideshow-view:before {
  content: '\f45f';
}
.remixicon-sort-alphabet-asc:before {
  content: '\f460';
}
.remixicon-sort-alphabet-desc:before {
  content: '\f461';
}
.remixicon-sort-number-asc:before {
  content: '\f462';
}
.remixicon-sort-number-desc:before {
  content: '\f463';
}
.remixicon-stacked-view:before {
  content: '\f464';
}
.remixicon-sticky-note-add-fill:before {
  content: '\f465';
}
.remixicon-sticky-note-add-line:before {
  content: '\f466';
}
.remixicon-swap-2-fill:before {
  content: '\f467';
}
.remixicon-swap-2-line:before {
  content: '\f468';
}
.remixicon-swap-3-fill:before {
  content: '\f469';
}
.remixicon-swap-3-line:before {
  content: '\f46a';
}
.remixicon-table-3:before {
  content: '\f46b';
}
.remixicon-table-view:before {
  content: '\f46c';
}
.remixicon-text-block:before {
  content: '\f46d';
}
.remixicon-text-snippet:before {
  content: '\f46e';
}
.remixicon-timeline-view:before {
  content: '\f46f';
}
.remixicon-blogger-fill:before {
  content: '\f470';
}
.remixicon-blogger-line:before {
  content: '\f471';
}
.remixicon-chat-thread-fill:before {
  content: '\f472';
}
.remixicon-chat-thread-line:before {
  content: '\f473';
}
.remixicon-discount-percent-fill:before {
  content: '\f474';
}
.remixicon-discount-percent-line:before {
  content: '\f475';
}
.remixicon-exchange-2-fill:before {
  content: '\f476';
}
.remixicon-exchange-2-line:before {
  content: '\f477';
}
.remixicon-git-fork-fill:before {
  content: '\f478';
}
.remixicon-git-fork-line:before {
  content: '\f479';
}
.remixicon-input-field:before {
  content: '\f47a';
}
.remixicon-progress-1-fill:before {
  content: '\f47b';
}
.remixicon-progress-1-line:before {
  content: '\f47c';
}
.remixicon-progress-2-fill:before {
  content: '\f47d';
}
.remixicon-progress-2-line:before {
  content: '\f47e';
}
.remixicon-progress-3-fill:before {
  content: '\f47f';
}
.remixicon-progress-3-line:before {
  content: '\f480';
}
.remixicon-progress-4-fill:before {
  content: '\f481';
}
.remixicon-progress-4-line:before {
  content: '\f482';
}
.remixicon-progress-5-fill:before {
  content: '\f483';
}
.remixicon-progress-5-line:before {
  content: '\f484';
}
.remixicon-progress-6-fill:before {
  content: '\f485';
}
.remixicon-progress-6-line:before {
  content: '\f486';
}
.remixicon-progress-7-fill:before {
  content: '\f487';
}
.remixicon-progress-7-line:before {
  content: '\f488';
}
.remixicon-progress-8-fill:before {
  content: '\f489';
}
.remixicon-progress-8-line:before {
  content: '\f48a';
}
.remixicon-remix-run-fill:before {
  content: '\f48b';
}
.remixicon-remix-run-line:before {
  content: '\f48c';
}
.remixicon-signpost-fill:before {
  content: '\f48d';
}
.remixicon-signpost-line:before {
  content: '\f48e';
}
.remixicon-time-zone-fill:before {
  content: '\f48f';
}
.remixicon-time-zone-line:before {
  content: '\f490';
}
.remixicon-arrow-down-wide-fill:before {
  content: '\f491';
}
.remixicon-arrow-down-wide-line:before {
  content: '\f492';
}
.remixicon-arrow-left-wide-fill:before {
  content: '\f493';
}
.remixicon-arrow-left-wide-line:before {
  content: '\f494';
}
.remixicon-arrow-right-wide-fill:before {
  content: '\f495';
}
.remixicon-arrow-right-wide-line:before {
  content: '\f496';
}
.remixicon-arrow-up-wide-fill:before {
  content: '\f497';
}
.remixicon-arrow-up-wide-line:before {
  content: '\f498';
}
.remixicon-bluesky-fill:before {
  content: '\f499';
}
.remixicon-bluesky-line:before {
  content: '\f49a';
}
.remixicon-expand-height-fill:before {
  content: '\f49b';
}
.remixicon-expand-height-line:before {
  content: '\f49c';
}
.remixicon-expand-width-fill:before {
  content: '\f49d';
}
.remixicon-expand-width-line:before {
  content: '\f49e';
}
.remixicon-forward-end-fill:before {
  content: '\f49f';
}
.remixicon-forward-end-line:before {
  content: '\f4a0';
}
.remixicon-forward-end-mini-fill:before {
  content: '\f4a1';
}
.remixicon-forward-end-mini-line:before {
  content: '\f4a2';
}
.remixicon-friendica-fill:before {
  content: '\f4a3';
}
.remixicon-friendica-line:before {
  content: '\f4a4';
}
.remixicon-git-pr-draft-fill:before {
  content: '\f4a5';
}
.remixicon-git-pr-draft-line:before {
  content: '\f4a6';
}
.remixicon-play-reverse-fill:before {
  content: '\f4a7';
}
.remixicon-play-reverse-line:before {
  content: '\f4a8';
}
.remixicon-play-reverse-mini-fill:before {
  content: '\f4a9';
}
.remixicon-play-reverse-mini-line:before {
  content: '\f4aa';
}
.remixicon-rewind-start-fill:before {
  content: '\f4ab';
}
.remixicon-rewind-start-line:before {
  content: '\f4ac';
}
.remixicon-rewind-start-mini-fill:before {
  content: '\f4ad';
}
.remixicon-rewind-start-mini-line:before {
  content: '\f4ae';
}
.remixicon-scroll-to-bottom-fill:before {
  content: '\f4af';
}
.remixicon-scroll-to-bottom-line:before {
  content: '\f4b0';
}
.remixicon-add-large-fill:before {
  content: '\f4b1';
}
.remixicon-add-large-line:before {
  content: '\f4b2';
}
.remixicon-aed-electrodes-fill:before {
  content: '\f4b3';
}
.remixicon-aed-electrodes-line:before {
  content: '\f4b4';
}
.remixicon-aed-fill:before {
  content: '\f4b5';
}
.remixicon-aed-line:before {
  content: '\f4b6';
}
.remixicon-alibaba-cloud-fill:before {
  content: '\f4b7';
}
.remixicon-alibaba-cloud-line:before {
  content: '\f4b8';
}
.remixicon-align-item-bottom-fill:before {
  content: '\f4b9';
}
.remixicon-align-item-bottom-line:before {
  content: '\f4ba';
}
.remixicon-align-item-horizontal-center-fill:before {
  content: '\f4bb';
}
.remixicon-align-item-horizontal-center-line:before {
  content: '\f4bc';
}
.remixicon-align-item-left-fill:before {
  content: '\f4bd';
}
.remixicon-align-item-left-line:before {
  content: '\f4be';
}
.remixicon-align-item-right-fill:before {
  content: '\f4bf';
}
.remixicon-align-item-right-line:before {
  content: '\f4c0';
}
.remixicon-align-item-top-fill:before {
  content: '\f4c1';
}
.remixicon-align-item-top-line:before {
  content: '\f4c2';
}
.remixicon-align-item-vertical-center-fill:before {
  content: '\f4c3';
}
.remixicon-align-item-vertical-center-line:before {
  content: '\f4c4';
}
.remixicon-apps-2-add-fill:before {
  content: '\f4c5';
}
.remixicon-apps-2-add-line:before {
  content: '\f4c6';
}
.remixicon-close-large-fill:before {
  content: '\f4c7';
}
.remixicon-close-large-line:before {
  content: '\f4c8';
}
.remixicon-collapse-diagonal-2-fill:before {
  content: '\f4c9';
}
.remixicon-collapse-diagonal-2-line:before {
  content: '\f4ca';
}
.remixicon-collapse-diagonal-fill:before {
  content: '\f4cb';
}
.remixicon-collapse-diagonal-line:before {
  content: '\f4cc';
}
.remixicon-dashboard-horizontal-fill:before {
  content: '\f4cd';
}
.remixicon-dashboard-horizontal-line:before {
  content: '\f4ce';
}
.remixicon-expand-diagonal-2-fill:before {
  content: '\f4cf';
}
.remixicon-expand-diagonal-2-line:before {
  content: '\f4d0';
}
.remixicon-expand-diagonal-fill:before {
  content: '\f4d1';
}
.remixicon-expand-diagonal-line:before {
  content: '\f4d2';
}
.remixicon-firebase-fill:before {
  content: '\f4d3';
}
.remixicon-firebase-line:before {
  content: '\f4d4';
}
.remixicon-flip-horizontal-2-fill:before {
  content: '\f4d5';
}
.remixicon-flip-horizontal-2-line:before {
  content: '\f4d6';
}
.remixicon-flip-horizontal-fill:before {
  content: '\f4d7';
}
.remixicon-flip-horizontal-line:before {
  content: '\f4d8';
}
.remixicon-flip-vertical-2-fill:before {
  content: '\f4d9';
}
.remixicon-flip-vertical-2-line:before {
  content: '\f4da';
}
.remixicon-flip-vertical-fill:before {
  content: '\f4db';
}
.remixicon-flip-vertical-line:before {
  content: '\f4dc';
}
.remixicon-formula:before {
  content: '\f4dd';
}
.remixicon-function-add-fill:before {
  content: '\f4de';
}
.remixicon-function-add-line:before {
  content: '\f4df';
}
.remixicon-goblet-2-fill:before {
  content: '\f4e0';
}
.remixicon-goblet-2-line:before {
  content: '\f4e1';
}
.remixicon-golf-ball-fill:before {
  content: '\f4e2';
}
.remixicon-golf-ball-line:before {
  content: '\f4e3';
}
.remixicon-group-3-fill:before {
  content: '\f4e4';
}
.remixicon-group-3-line:before {
  content: '\f4e5';
}
.remixicon-heart-add-2-fill:before {
  content: '\f4e6';
}
.remixicon-heart-add-2-line:before {
  content: '\f4e7';
}
.remixicon-id-card-fill:before {
  content: '\f4e8';
}
.remixicon-id-card-line:before {
  content: '\f4e9';
}
.remixicon-information-off-fill:before {
  content: '\f4ea';
}
.remixicon-information-off-line:before {
  content: '\f4eb';
}
.remixicon-java-fill:before {
  content: '\f4ec';
}
.remixicon-java-line:before {
  content: '\f4ed';
}
.remixicon-layout-grid-2-fill:before {
  content: '\f4ee';
}
.remixicon-layout-grid-2-line:before {
  content: '\f4ef';
}
.remixicon-layout-horizontal-fill:before {
  content: '\f4f0';
}
.remixicon-layout-horizontal-line:before {
  content: '\f4f1';
}
.remixicon-layout-vertical-fill:before {
  content: '\f4f2';
}
.remixicon-layout-vertical-line:before {
  content: '\f4f3';
}
.remixicon-menu-fold-2-fill:before {
  content: '\f4f4';
}
.remixicon-menu-fold-2-line:before {
  content: '\f4f5';
}
.remixicon-menu-fold-3-fill:before {
  content: '\f4f6';
}
.remixicon-menu-fold-3-line:before {
  content: '\f4f7';
}
.remixicon-menu-fold-4-fill:before {
  content: '\f4f8';
}
.remixicon-menu-fold-4-line:before {
  content: '\f4f9';
}
.remixicon-menu-unfold-2-fill:before {
  content: '\f4fa';
}
.remixicon-menu-unfold-2-line:before {
  content: '\f4fb';
}
.remixicon-menu-unfold-3-fill:before {
  content: '\f4fc';
}
.remixicon-menu-unfold-3-line:before {
  content: '\f4fd';
}
.remixicon-menu-unfold-4-fill:before {
  content: '\f4fe';
}
.remixicon-menu-unfold-4-line:before {
  content: '\f4ff';
}
.remixicon-mobile-download-fill:before {
  content: '\f500';
}
.remixicon-mobile-download-line:before {
  content: '\f501';
}
.remixicon-nextjs-fill:before {
  content: '\f502';
}
.remixicon-nextjs-line:before {
  content: '\f503';
}
.remixicon-nodejs-fill:before {
  content: '\f504';
}
.remixicon-nodejs-line:before {
  content: '\f505';
}
.remixicon-pause-large-fill:before {
  content: '\f506';
}
.remixicon-pause-large-line:before {
  content: '\f507';
}
.remixicon-play-large-fill:before {
  content: '\f508';
}
.remixicon-play-large-line:before {
  content: '\f509';
}
.remixicon-play-reverse-large-fill:before {
  content: '\f50a';
}
.remixicon-play-reverse-large-line:before {
  content: '\f50b';
}
.remixicon-police-badge-fill:before {
  content: '\f50c';
}
.remixicon-police-badge-line:before {
  content: '\f50d';
}
.remixicon-prohibited-2-fill:before {
  content: '\f50e';
}
.remixicon-prohibited-2-line:before {
  content: '\f50f';
}
.remixicon-shopping-bag-4-fill:before {
  content: '\f510';
}
.remixicon-shopping-bag-4-line:before {
  content: '\f511';
}
.remixicon-snowflake-fill:before {
  content: '\f512';
}
.remixicon-snowflake-line:before {
  content: '\f513';
}
.remixicon-square-root:before {
  content: '\f514';
}
.remixicon-stop-large-fill:before {
  content: '\f515';
}
.remixicon-stop-large-line:before {
  content: '\f516';
}
.remixicon-tailwind-css-fill:before {
  content: '\f517';
}
.remixicon-tailwind-css-line:before {
  content: '\f518';
}
.remixicon-tooth-fill:before {
  content: '\f519';
}
.remixicon-tooth-line:before {
  content: '\f51a';
}
.remixicon-video-off-fill:before {
  content: '\f51b';
}
.remixicon-video-off-line:before {
  content: '\f51c';
}
.remixicon-video-on-fill:before {
  content: '\f51d';
}
.remixicon-video-on-line:before {
  content: '\f51e';
}
.remixicon-webhook-fill:before {
  content: '\f51f';
}
.remixicon-webhook-line:before {
  content: '\f520';
}
.remixicon-weight-fill:before {
  content: '\f521';
}
.remixicon-weight-line:before {
  content: '\f522';
}
.remixicon-book-shelf-fill:before {
  content: '\f523';
}
.remixicon-book-shelf-line:before {
  content: '\f524';
}
.remixicon-brain-2-fill:before {
  content: '\f525';
}
.remixicon-brain-2-line:before {
  content: '\f526';
}
.remixicon-chat-search-fill:before {
  content: '\f527';
}
.remixicon-chat-search-line:before {
  content: '\f528';
}
.remixicon-chat-unread-fill:before {
  content: '\f529';
}
.remixicon-chat-unread-line:before {
  content: '\f52a';
}
.remixicon-collapse-horizontal-fill:before {
  content: '\f52b';
}
.remixicon-collapse-horizontal-line:before {
  content: '\f52c';
}
.remixicon-collapse-vertical-fill:before {
  content: '\f52d';
}
.remixicon-collapse-vertical-line:before {
  content: '\f52e';
}
.remixicon-dna-fill:before {
  content: '\f52f';
}
.remixicon-dna-line:before {
  content: '\f530';
}
.remixicon-dropper-fill:before {
  content: '\f531';
}
.remixicon-dropper-line:before {
  content: '\f532';
}
.remixicon-expand-diagonal-s-2-fill:before {
  content: '\f533';
}
.remixicon-expand-diagonal-s-2-line:before {
  content: '\f534';
}
.remixicon-expand-diagonal-s-fill:before {
  content: '\f535';
}
.remixicon-expand-diagonal-s-line:before {
  content: '\f536';
}
.remixicon-expand-horizontal-fill:before {
  content: '\f537';
}
.remixicon-expand-horizontal-line:before {
  content: '\f538';
}
.remixicon-expand-horizontal-s-fill:before {
  content: '\f539';
}
.remixicon-expand-horizontal-s-line:before {
  content: '\f53a';
}
.remixicon-expand-vertical-fill:before {
  content: '\f53b';
}
.remixicon-expand-vertical-line:before {
  content: '\f53c';
}
.remixicon-expand-vertical-s-fill:before {
  content: '\f53d';
}
.remixicon-expand-vertical-s-line:before {
  content: '\f53e';
}
.remixicon-gemini-fill:before {
  content: '\f53f';
}
.remixicon-gemini-line:before {
  content: '\f540';
}
.remixicon-reset-left-fill:before {
  content: '\f541';
}
.remixicon-reset-left-line:before {
  content: '\f542';
}
.remixicon-reset-right-fill:before {
  content: '\f543';
}
.remixicon-reset-right-line:before {
  content: '\f544';
}
.remixicon-stairs-fill:before {
  content: '\f545';
}
.remixicon-stairs-line:before {
  content: '\f546';
}
.remixicon-telegram-2-fill:before {
  content: '\f547';
}
.remixicon-telegram-2-line:before {
  content: '\f548';
}
.remixicon-triangular-flag-fill:before {
  content: '\f549';
}
.remixicon-triangular-flag-line:before {
  content: '\f54a';
}
.remixicon-user-minus-fill:before {
  content: '\f54b';
}
.remixicon-user-minus-line:before {
  content: '\f54c';
}

$remixicon-24-hours-fill: '\ea01';
$remixicon-24-hours-line: '\ea02';
$remixicon-4k-fill: '\ea03';
$remixicon-4k-line: '\ea04';
$remixicon-a-b: '\ea05';
$remixicon-account-box-fill: '\ea06';
$remixicon-account-box-line: '\ea07';
$remixicon-account-circle-fill: '\ea08';
$remixicon-account-circle-line: '\ea09';
$remixicon-account-pin-box-fill: '\ea0a';
$remixicon-account-pin-box-line: '\ea0b';
$remixicon-account-pin-circle-fill: '\ea0c';
$remixicon-account-pin-circle-line: '\ea0d';
$remixicon-add-box-fill: '\ea0e';
$remixicon-add-box-line: '\ea0f';
$remixicon-add-circle-fill: '\ea10';
$remixicon-add-circle-line: '\ea11';
$remixicon-add-fill: '\ea12';
$remixicon-add-line: '\ea13';
$remixicon-admin-fill: '\ea14';
$remixicon-admin-line: '\ea15';
$remixicon-advertisement-fill: '\ea16';
$remixicon-advertisement-line: '\ea17';
$remixicon-airplay-fill: '\ea18';
$remixicon-airplay-line: '\ea19';
$remixicon-alarm-fill: '\ea1a';
$remixicon-alarm-line: '\ea1b';
$remixicon-alarm-warning-fill: '\ea1c';
$remixicon-alarm-warning-line: '\ea1d';
$remixicon-album-fill: '\ea1e';
$remixicon-album-line: '\ea1f';
$remixicon-alert-fill: '\ea20';
$remixicon-alert-line: '\ea21';
$remixicon-aliens-fill: '\ea22';
$remixicon-aliens-line: '\ea23';
$remixicon-align-bottom: '\ea24';
$remixicon-align-center: '\ea25';
$remixicon-align-justify: '\ea26';
$remixicon-align-left: '\ea27';
$remixicon-align-right: '\ea28';
$remixicon-align-top: '\ea29';
$remixicon-align-vertically: '\ea2a';
$remixicon-alipay-fill: '\ea2b';
$remixicon-alipay-line: '\ea2c';
$remixicon-amazon-fill: '\ea2d';
$remixicon-amazon-line: '\ea2e';
$remixicon-anchor-fill: '\ea2f';
$remixicon-anchor-line: '\ea30';
$remixicon-ancient-gate-fill: '\ea31';
$remixicon-ancient-gate-line: '\ea32';
$remixicon-ancient-pavilion-fill: '\ea33';
$remixicon-ancient-pavilion-line: '\ea34';
$remixicon-android-fill: '\ea35';
$remixicon-android-line: '\ea36';
$remixicon-angularjs-fill: '\ea37';
$remixicon-angularjs-line: '\ea38';
$remixicon-anticlockwise-2-fill: '\ea39';
$remixicon-anticlockwise-2-line: '\ea3a';
$remixicon-anticlockwise-fill: '\ea3b';
$remixicon-anticlockwise-line: '\ea3c';
$remixicon-app-store-fill: '\ea3d';
$remixicon-app-store-line: '\ea3e';
$remixicon-apple-fill: '\ea3f';
$remixicon-apple-line: '\ea40';
$remixicon-apps-2-fill: '\ea41';
$remixicon-apps-2-line: '\ea42';
$remixicon-apps-fill: '\ea43';
$remixicon-apps-line: '\ea44';
$remixicon-archive-drawer-fill: '\ea45';
$remixicon-archive-drawer-line: '\ea46';
$remixicon-archive-fill: '\ea47';
$remixicon-archive-line: '\ea48';
$remixicon-arrow-down-circle-fill: '\ea49';
$remixicon-arrow-down-circle-line: '\ea4a';
$remixicon-arrow-down-fill: '\ea4b';
$remixicon-arrow-down-line: '\ea4c';
$remixicon-arrow-down-s-fill: '\ea4d';
$remixicon-arrow-down-s-line: '\ea4e';
$remixicon-arrow-drop-down-fill: '\ea4f';
$remixicon-arrow-drop-down-line: '\ea50';
$remixicon-arrow-drop-left-fill: '\ea51';
$remixicon-arrow-drop-left-line: '\ea52';
$remixicon-arrow-drop-right-fill: '\ea53';
$remixicon-arrow-drop-right-line: '\ea54';
$remixicon-arrow-drop-up-fill: '\ea55';
$remixicon-arrow-drop-up-line: '\ea56';
$remixicon-arrow-go-back-fill: '\ea57';
$remixicon-arrow-go-back-line: '\ea58';
$remixicon-arrow-go-forward-fill: '\ea59';
$remixicon-arrow-go-forward-line: '\ea5a';
$remixicon-arrow-left-circle-fill: '\ea5b';
$remixicon-arrow-left-circle-line: '\ea5c';
$remixicon-arrow-left-down-fill: '\ea5d';
$remixicon-arrow-left-down-line: '\ea5e';
$remixicon-arrow-left-fill: '\ea5f';
$remixicon-arrow-left-line: '\ea60';
$remixicon-arrow-left-right-fill: '\ea61';
$remixicon-arrow-left-right-line: '\ea62';
$remixicon-arrow-left-s-fill: '\ea63';
$remixicon-arrow-left-s-line: '\ea64';
$remixicon-arrow-left-up-fill: '\ea65';
$remixicon-arrow-left-up-line: '\ea66';
$remixicon-arrow-right-circle-fill: '\ea67';
$remixicon-arrow-right-circle-line: '\ea68';
$remixicon-arrow-right-down-fill: '\ea69';
$remixicon-arrow-right-down-line: '\ea6a';
$remixicon-arrow-right-fill: '\ea6b';
$remixicon-arrow-right-line: '\ea6c';
$remixicon-arrow-right-s-fill: '\ea6d';
$remixicon-arrow-right-s-line: '\ea6e';
$remixicon-arrow-right-up-fill: '\ea6f';
$remixicon-arrow-right-up-line: '\ea70';
$remixicon-arrow-up-circle-fill: '\ea71';
$remixicon-arrow-up-circle-line: '\ea72';
$remixicon-arrow-up-down-fill: '\ea73';
$remixicon-arrow-up-down-line: '\ea74';
$remixicon-arrow-up-fill: '\ea75';
$remixicon-arrow-up-line: '\ea76';
$remixicon-arrow-up-s-fill: '\ea77';
$remixicon-arrow-up-s-line: '\ea78';
$remixicon-artboard-2-fill: '\ea79';
$remixicon-artboard-2-line: '\ea7a';
$remixicon-artboard-fill: '\ea7b';
$remixicon-artboard-line: '\ea7c';
$remixicon-article-fill: '\ea7d';
$remixicon-article-line: '\ea7e';
$remixicon-aspect-ratio-fill: '\ea7f';
$remixicon-aspect-ratio-line: '\ea80';
$remixicon-asterisk: '\ea81';
$remixicon-at-fill: '\ea82';
$remixicon-at-line: '\ea83';
$remixicon-attachment-2: '\ea84';
$remixicon-attachment-fill: '\ea85';
$remixicon-attachment-line: '\ea86';
$remixicon-auction-fill: '\ea87';
$remixicon-auction-line: '\ea88';
$remixicon-award-fill: '\ea89';
$remixicon-award-line: '\ea8a';
$remixicon-baidu-fill: '\ea8b';
$remixicon-baidu-line: '\ea8c';
$remixicon-ball-pen-fill: '\ea8d';
$remixicon-ball-pen-line: '\ea8e';
$remixicon-bank-card-2-fill: '\ea8f';
$remixicon-bank-card-2-line: '\ea90';
$remixicon-bank-card-fill: '\ea91';
$remixicon-bank-card-line: '\ea92';
$remixicon-bank-fill: '\ea93';
$remixicon-bank-line: '\ea94';
$remixicon-bar-chart-2-fill: '\ea95';
$remixicon-bar-chart-2-line: '\ea96';
$remixicon-bar-chart-box-fill: '\ea97';
$remixicon-bar-chart-box-line: '\ea98';
$remixicon-bar-chart-fill: '\ea99';
$remixicon-bar-chart-grouped-fill: '\ea9a';
$remixicon-bar-chart-grouped-line: '\ea9b';
$remixicon-bar-chart-horizontal-fill: '\ea9c';
$remixicon-bar-chart-horizontal-line: '\ea9d';
$remixicon-bar-chart-line: '\ea9e';
$remixicon-barcode-box-fill: '\ea9f';
$remixicon-barcode-box-line: '\eaa0';
$remixicon-barcode-fill: '\eaa1';
$remixicon-barcode-line: '\eaa2';
$remixicon-barricade-fill: '\eaa3';
$remixicon-barricade-line: '\eaa4';
$remixicon-base-station-fill: '\eaa5';
$remixicon-base-station-line: '\eaa6';
$remixicon-basketball-fill: '\eaa7';
$remixicon-basketball-line: '\eaa8';
$remixicon-battery-2-charge-fill: '\eaa9';
$remixicon-battery-2-charge-line: '\eaaa';
$remixicon-battery-2-fill: '\eaab';
$remixicon-battery-2-line: '\eaac';
$remixicon-battery-charge-fill: '\eaad';
$remixicon-battery-charge-line: '\eaae';
$remixicon-battery-fill: '\eaaf';
$remixicon-battery-line: '\eab0';
$remixicon-battery-low-fill: '\eab1';
$remixicon-battery-low-line: '\eab2';
$remixicon-battery-saver-fill: '\eab3';
$remixicon-battery-saver-line: '\eab4';
$remixicon-battery-share-fill: '\eab5';
$remixicon-battery-share-line: '\eab6';
$remixicon-bear-smile-fill: '\eab7';
$remixicon-bear-smile-line: '\eab8';
$remixicon-behance-fill: '\eab9';
$remixicon-behance-line: '\eaba';
$remixicon-bell-fill: '\eabb';
$remixicon-bell-line: '\eabc';
$remixicon-bike-fill: '\eabd';
$remixicon-bike-line: '\eabe';
$remixicon-bilibili-fill: '\eabf';
$remixicon-bilibili-line: '\eac0';
$remixicon-bill-fill: '\eac1';
$remixicon-bill-line: '\eac2';
$remixicon-billiards-fill: '\eac3';
$remixicon-billiards-line: '\eac4';
$remixicon-bit-coin-fill: '\eac5';
$remixicon-bit-coin-line: '\eac6';
$remixicon-blaze-fill: '\eac7';
$remixicon-blaze-line: '\eac8';
$remixicon-bluetooth-connect-fill: '\eac9';
$remixicon-bluetooth-connect-line: '\eaca';
$remixicon-bluetooth-fill: '\eacb';
$remixicon-bluetooth-line: '\eacc';
$remixicon-blur-off-fill: '\eacd';
$remixicon-blur-off-line: '\eace';
$remixicon-body-scan-fill: '\eacf';
$remixicon-body-scan-line: '\ead0';
$remixicon-bold: '\ead1';
$remixicon-book-2-fill: '\ead2';
$remixicon-book-2-line: '\ead3';
$remixicon-book-3-fill: '\ead4';
$remixicon-book-3-line: '\ead5';
$remixicon-book-fill: '\ead6';
$remixicon-book-line: '\ead7';
$remixicon-book-marked-fill: '\ead8';
$remixicon-book-marked-line: '\ead9';
$remixicon-book-open-fill: '\eada';
$remixicon-book-open-line: '\eadb';
$remixicon-book-read-fill: '\eadc';
$remixicon-book-read-line: '\eadd';
$remixicon-booklet-fill: '\eade';
$remixicon-booklet-line: '\eadf';
$remixicon-bookmark-2-fill: '\eae0';
$remixicon-bookmark-2-line: '\eae1';
$remixicon-bookmark-3-fill: '\eae2';
$remixicon-bookmark-3-line: '\eae3';
$remixicon-bookmark-fill: '\eae4';
$remixicon-bookmark-line: '\eae5';
$remixicon-boxing-fill: '\eae6';
$remixicon-boxing-line: '\eae7';
$remixicon-braces-fill: '\eae8';
$remixicon-braces-line: '\eae9';
$remixicon-brackets-fill: '\eaea';
$remixicon-brackets-line: '\eaeb';
$remixicon-briefcase-2-fill: '\eaec';
$remixicon-briefcase-2-line: '\eaed';
$remixicon-briefcase-3-fill: '\eaee';
$remixicon-briefcase-3-line: '\eaef';
$remixicon-briefcase-4-fill: '\eaf0';
$remixicon-briefcase-4-line: '\eaf1';
$remixicon-briefcase-5-fill: '\eaf2';
$remixicon-briefcase-5-line: '\eaf3';
$remixicon-briefcase-fill: '\eaf4';
$remixicon-briefcase-line: '\eaf5';
$remixicon-bring-forward: '\eaf6';
$remixicon-bring-to-front: '\eaf7';
$remixicon-broadcast-fill: '\eaf8';
$remixicon-broadcast-line: '\eaf9';
$remixicon-brush-2-fill: '\eafa';
$remixicon-brush-2-line: '\eafb';
$remixicon-brush-3-fill: '\eafc';
$remixicon-brush-3-line: '\eafd';
$remixicon-brush-4-fill: '\eafe';
$remixicon-brush-4-line: '\eaff';
$remixicon-brush-fill: '\eb00';
$remixicon-brush-line: '\eb01';
$remixicon-bubble-chart-fill: '\eb02';
$remixicon-bubble-chart-line: '\eb03';
$remixicon-bug-2-fill: '\eb04';
$remixicon-bug-2-line: '\eb05';
$remixicon-bug-fill: '\eb06';
$remixicon-bug-line: '\eb07';
$remixicon-building-2-fill: '\eb08';
$remixicon-building-2-line: '\eb09';
$remixicon-building-3-fill: '\eb0a';
$remixicon-building-3-line: '\eb0b';
$remixicon-building-4-fill: '\eb0c';
$remixicon-building-4-line: '\eb0d';
$remixicon-building-fill: '\eb0e';
$remixicon-building-line: '\eb0f';
$remixicon-bus-2-fill: '\eb10';
$remixicon-bus-2-line: '\eb11';
$remixicon-bus-fill: '\eb12';
$remixicon-bus-line: '\eb13';
$remixicon-bus-wifi-fill: '\eb14';
$remixicon-bus-wifi-line: '\eb15';
$remixicon-cactus-fill: '\eb16';
$remixicon-cactus-line: '\eb17';
$remixicon-cake-2-fill: '\eb18';
$remixicon-cake-2-line: '\eb19';
$remixicon-cake-3-fill: '\eb1a';
$remixicon-cake-3-line: '\eb1b';
$remixicon-cake-fill: '\eb1c';
$remixicon-cake-line: '\eb1d';
$remixicon-calculator-fill: '\eb1e';
$remixicon-calculator-line: '\eb1f';
$remixicon-calendar-2-fill: '\eb20';
$remixicon-calendar-2-line: '\eb21';
$remixicon-calendar-check-fill: '\eb22';
$remixicon-calendar-check-line: '\eb23';
$remixicon-calendar-event-fill: '\eb24';
$remixicon-calendar-event-line: '\eb25';
$remixicon-calendar-fill: '\eb26';
$remixicon-calendar-line: '\eb27';
$remixicon-calendar-todo-fill: '\eb28';
$remixicon-calendar-todo-line: '\eb29';
$remixicon-camera-2-fill: '\eb2a';
$remixicon-camera-2-line: '\eb2b';
$remixicon-camera-3-fill: '\eb2c';
$remixicon-camera-3-line: '\eb2d';
$remixicon-camera-fill: '\eb2e';
$remixicon-camera-lens-fill: '\eb2f';
$remixicon-camera-lens-line: '\eb30';
$remixicon-camera-line: '\eb31';
$remixicon-camera-off-fill: '\eb32';
$remixicon-camera-off-line: '\eb33';
$remixicon-camera-switch-fill: '\eb34';
$remixicon-camera-switch-line: '\eb35';
$remixicon-capsule-fill: '\eb36';
$remixicon-capsule-line: '\eb37';
$remixicon-car-fill: '\eb38';
$remixicon-car-line: '\eb39';
$remixicon-car-washing-fill: '\eb3a';
$remixicon-car-washing-line: '\eb3b';
$remixicon-caravan-fill: '\eb3c';
$remixicon-caravan-line: '\eb3d';
$remixicon-cast-fill: '\eb3e';
$remixicon-cast-line: '\eb3f';
$remixicon-cellphone-fill: '\eb40';
$remixicon-cellphone-line: '\eb41';
$remixicon-celsius-fill: '\eb42';
$remixicon-celsius-line: '\eb43';
$remixicon-centos-fill: '\eb44';
$remixicon-centos-line: '\eb45';
$remixicon-character-recognition-fill: '\eb46';
$remixicon-character-recognition-line: '\eb47';
$remixicon-charging-pile-2-fill: '\eb48';
$remixicon-charging-pile-2-line: '\eb49';
$remixicon-charging-pile-fill: '\eb4a';
$remixicon-charging-pile-line: '\eb4b';
$remixicon-chat-1-fill: '\eb4c';
$remixicon-chat-1-line: '\eb4d';
$remixicon-chat-2-fill: '\eb4e';
$remixicon-chat-2-line: '\eb4f';
$remixicon-chat-3-fill: '\eb50';
$remixicon-chat-3-line: '\eb51';
$remixicon-chat-4-fill: '\eb52';
$remixicon-chat-4-line: '\eb53';
$remixicon-chat-check-fill: '\eb54';
$remixicon-chat-check-line: '\eb55';
$remixicon-chat-delete-fill: '\eb56';
$remixicon-chat-delete-line: '\eb57';
$remixicon-chat-download-fill: '\eb58';
$remixicon-chat-download-line: '\eb59';
$remixicon-chat-follow-up-fill: '\eb5a';
$remixicon-chat-follow-up-line: '\eb5b';
$remixicon-chat-forward-fill: '\eb5c';
$remixicon-chat-forward-line: '\eb5d';
$remixicon-chat-heart-fill: '\eb5e';
$remixicon-chat-heart-line: '\eb5f';
$remixicon-chat-history-fill: '\eb60';
$remixicon-chat-history-line: '\eb61';
$remixicon-chat-new-fill: '\eb62';
$remixicon-chat-new-line: '\eb63';
$remixicon-chat-off-fill: '\eb64';
$remixicon-chat-off-line: '\eb65';
$remixicon-chat-poll-fill: '\eb66';
$remixicon-chat-poll-line: '\eb67';
$remixicon-chat-private-fill: '\eb68';
$remixicon-chat-private-line: '\eb69';
$remixicon-chat-quote-fill: '\eb6a';
$remixicon-chat-quote-line: '\eb6b';
$remixicon-chat-settings-fill: '\eb6c';
$remixicon-chat-settings-line: '\eb6d';
$remixicon-chat-smile-2-fill: '\eb6e';
$remixicon-chat-smile-2-line: '\eb6f';
$remixicon-chat-smile-3-fill: '\eb70';
$remixicon-chat-smile-3-line: '\eb71';
$remixicon-chat-smile-fill: '\eb72';
$remixicon-chat-smile-line: '\eb73';
$remixicon-chat-upload-fill: '\eb74';
$remixicon-chat-upload-line: '\eb75';
$remixicon-chat-voice-fill: '\eb76';
$remixicon-chat-voice-line: '\eb77';
$remixicon-check-double-fill: '\eb78';
$remixicon-check-double-line: '\eb79';
$remixicon-check-fill: '\eb7a';
$remixicon-check-line: '\eb7b';
$remixicon-checkbox-blank-circle-fill: '\eb7c';
$remixicon-checkbox-blank-circle-line: '\eb7d';
$remixicon-checkbox-blank-fill: '\eb7e';
$remixicon-checkbox-blank-line: '\eb7f';
$remixicon-checkbox-circle-fill: '\eb80';
$remixicon-checkbox-circle-line: '\eb81';
$remixicon-checkbox-fill: '\eb82';
$remixicon-checkbox-indeterminate-fill: '\eb83';
$remixicon-checkbox-indeterminate-line: '\eb84';
$remixicon-checkbox-line: '\eb85';
$remixicon-checkbox-multiple-blank-fill: '\eb86';
$remixicon-checkbox-multiple-blank-line: '\eb87';
$remixicon-checkbox-multiple-fill: '\eb88';
$remixicon-checkbox-multiple-line: '\eb89';
$remixicon-china-railway-fill: '\eb8a';
$remixicon-china-railway-line: '\eb8b';
$remixicon-chrome-fill: '\eb8c';
$remixicon-chrome-line: '\eb8d';
$remixicon-clapperboard-fill: '\eb8e';
$remixicon-clapperboard-line: '\eb8f';
$remixicon-clipboard-fill: '\eb90';
$remixicon-clipboard-line: '\eb91';
$remixicon-clockwise-2-fill: '\eb92';
$remixicon-clockwise-2-line: '\eb93';
$remixicon-clockwise-fill: '\eb94';
$remixicon-clockwise-line: '\eb95';
$remixicon-close-circle-fill: '\eb96';
$remixicon-close-circle-line: '\eb97';
$remixicon-close-fill: '\eb98';
$remixicon-close-line: '\eb99';
$remixicon-closed-captioning-fill: '\eb9a';
$remixicon-closed-captioning-line: '\eb9b';
$remixicon-cloud-fill: '\eb9c';
$remixicon-cloud-line: '\eb9d';
$remixicon-cloud-off-fill: '\eb9e';
$remixicon-cloud-off-line: '\eb9f';
$remixicon-cloud-windy-fill: '\eba0';
$remixicon-cloud-windy-line: '\eba1';
$remixicon-cloudy-2-fill: '\eba2';
$remixicon-cloudy-2-line: '\eba3';
$remixicon-cloudy-fill: '\eba4';
$remixicon-cloudy-line: '\eba5';
$remixicon-code-box-fill: '\eba6';
$remixicon-code-box-line: '\eba7';
$remixicon-code-fill: '\eba8';
$remixicon-code-line: '\eba9';
$remixicon-code-s-fill: '\ebaa';
$remixicon-code-s-line: '\ebab';
$remixicon-code-s-slash-fill: '\ebac';
$remixicon-code-s-slash-line: '\ebad';
$remixicon-code-view: '\ebae';
$remixicon-codepen-fill: '\ebaf';
$remixicon-codepen-line: '\ebb0';
$remixicon-coin-fill: '\ebb1';
$remixicon-coin-line: '\ebb2';
$remixicon-coins-fill: '\ebb3';
$remixicon-coins-line: '\ebb4';
$remixicon-collage-fill: '\ebb5';
$remixicon-collage-line: '\ebb6';
$remixicon-command-fill: '\ebb7';
$remixicon-command-line: '\ebb8';
$remixicon-community-fill: '\ebb9';
$remixicon-community-line: '\ebba';
$remixicon-compass-2-fill: '\ebbb';
$remixicon-compass-2-line: '\ebbc';
$remixicon-compass-3-fill: '\ebbd';
$remixicon-compass-3-line: '\ebbe';
$remixicon-compass-4-fill: '\ebbf';
$remixicon-compass-4-line: '\ebc0';
$remixicon-compass-discover-fill: '\ebc1';
$remixicon-compass-discover-line: '\ebc2';
$remixicon-compass-fill: '\ebc3';
$remixicon-compass-line: '\ebc4';
$remixicon-compasses-2-fill: '\ebc5';
$remixicon-compasses-2-line: '\ebc6';
$remixicon-compasses-fill: '\ebc7';
$remixicon-compasses-line: '\ebc8';
$remixicon-computer-fill: '\ebc9';
$remixicon-computer-line: '\ebca';
$remixicon-contacts-book-2-fill: '\ebcb';
$remixicon-contacts-book-2-line: '\ebcc';
$remixicon-contacts-book-fill: '\ebcd';
$remixicon-contacts-book-line: '\ebce';
$remixicon-contacts-book-upload-fill: '\ebcf';
$remixicon-contacts-book-upload-line: '\ebd0';
$remixicon-contacts-fill: '\ebd1';
$remixicon-contacts-line: '\ebd2';
$remixicon-contrast-2-fill: '\ebd3';
$remixicon-contrast-2-line: '\ebd4';
$remixicon-contrast-drop-2-fill: '\ebd5';
$remixicon-contrast-drop-2-line: '\ebd6';
$remixicon-contrast-drop-fill: '\ebd7';
$remixicon-contrast-drop-line: '\ebd8';
$remixicon-contrast-fill: '\ebd9';
$remixicon-contrast-line: '\ebda';
$remixicon-copper-coin-fill: '\ebdb';
$remixicon-copper-coin-line: '\ebdc';
$remixicon-copper-diamond-fill: '\ebdd';
$remixicon-copper-diamond-line: '\ebde';
$remixicon-copyleft-fill: '\ebdf';
$remixicon-copyleft-line: '\ebe0';
$remixicon-copyright-fill: '\ebe1';
$remixicon-copyright-line: '\ebe2';
$remixicon-coreos-fill: '\ebe3';
$remixicon-coreos-line: '\ebe4';
$remixicon-coupon-2-fill: '\ebe5';
$remixicon-coupon-2-line: '\ebe6';
$remixicon-coupon-3-fill: '\ebe7';
$remixicon-coupon-3-line: '\ebe8';
$remixicon-coupon-4-fill: '\ebe9';
$remixicon-coupon-4-line: '\ebea';
$remixicon-coupon-5-fill: '\ebeb';
$remixicon-coupon-5-line: '\ebec';
$remixicon-coupon-fill: '\ebed';
$remixicon-coupon-line: '\ebee';
$remixicon-cpu-fill: '\ebef';
$remixicon-cpu-line: '\ebf0';
$remixicon-creative-commons-by-fill: '\ebf1';
$remixicon-creative-commons-by-line: '\ebf2';
$remixicon-creative-commons-fill: '\ebf3';
$remixicon-creative-commons-line: '\ebf4';
$remixicon-creative-commons-nc-fill: '\ebf5';
$remixicon-creative-commons-nc-line: '\ebf6';
$remixicon-creative-commons-nd-fill: '\ebf7';
$remixicon-creative-commons-nd-line: '\ebf8';
$remixicon-creative-commons-sa-fill: '\ebf9';
$remixicon-creative-commons-sa-line: '\ebfa';
$remixicon-creative-commons-zero-fill: '\ebfb';
$remixicon-creative-commons-zero-line: '\ebfc';
$remixicon-criminal-fill: '\ebfd';
$remixicon-criminal-line: '\ebfe';
$remixicon-crop-2-fill: '\ebff';
$remixicon-crop-2-line: '\ec00';
$remixicon-crop-fill: '\ec01';
$remixicon-crop-line: '\ec02';
$remixicon-css3-fill: '\ec03';
$remixicon-css3-line: '\ec04';
$remixicon-cup-fill: '\ec05';
$remixicon-cup-line: '\ec06';
$remixicon-currency-fill: '\ec07';
$remixicon-currency-line: '\ec08';
$remixicon-cursor-fill: '\ec09';
$remixicon-cursor-line: '\ec0a';
$remixicon-customer-service-2-fill: '\ec0b';
$remixicon-customer-service-2-line: '\ec0c';
$remixicon-customer-service-fill: '\ec0d';
$remixicon-customer-service-line: '\ec0e';
$remixicon-dashboard-2-fill: '\ec0f';
$remixicon-dashboard-2-line: '\ec10';
$remixicon-dashboard-3-fill: '\ec11';
$remixicon-dashboard-3-line: '\ec12';
$remixicon-dashboard-fill: '\ec13';
$remixicon-dashboard-line: '\ec14';
$remixicon-database-2-fill: '\ec15';
$remixicon-database-2-line: '\ec16';
$remixicon-database-fill: '\ec17';
$remixicon-database-line: '\ec18';
$remixicon-delete-back-2-fill: '\ec19';
$remixicon-delete-back-2-line: '\ec1a';
$remixicon-delete-back-fill: '\ec1b';
$remixicon-delete-back-line: '\ec1c';
$remixicon-delete-bin-2-fill: '\ec1d';
$remixicon-delete-bin-2-line: '\ec1e';
$remixicon-delete-bin-3-fill: '\ec1f';
$remixicon-delete-bin-3-line: '\ec20';
$remixicon-delete-bin-4-fill: '\ec21';
$remixicon-delete-bin-4-line: '\ec22';
$remixicon-delete-bin-5-fill: '\ec23';
$remixicon-delete-bin-5-line: '\ec24';
$remixicon-delete-bin-6-fill: '\ec25';
$remixicon-delete-bin-6-line: '\ec26';
$remixicon-delete-bin-7-fill: '\ec27';
$remixicon-delete-bin-7-line: '\ec28';
$remixicon-delete-bin-fill: '\ec29';
$remixicon-delete-bin-line: '\ec2a';
$remixicon-delete-column: '\ec2b';
$remixicon-delete-row: '\ec2c';
$remixicon-device-fill: '\ec2d';
$remixicon-device-line: '\ec2e';
$remixicon-device-recover-fill: '\ec2f';
$remixicon-device-recover-line: '\ec30';
$remixicon-dingding-fill: '\ec31';
$remixicon-dingding-line: '\ec32';
$remixicon-direction-fill: '\ec33';
$remixicon-direction-line: '\ec34';
$remixicon-disc-fill: '\ec35';
$remixicon-disc-line: '\ec36';
$remixicon-discord-fill: '\ec37';
$remixicon-discord-line: '\ec38';
$remixicon-discuss-fill: '\ec39';
$remixicon-discuss-line: '\ec3a';
$remixicon-dislike-fill: '\ec3b';
$remixicon-dislike-line: '\ec3c';
$remixicon-disqus-fill: '\ec3d';
$remixicon-disqus-line: '\ec3e';
$remixicon-divide-fill: '\ec3f';
$remixicon-divide-line: '\ec40';
$remixicon-donut-chart-fill: '\ec41';
$remixicon-donut-chart-line: '\ec42';
$remixicon-door-closed-fill: '\ec43';
$remixicon-door-closed-line: '\ec44';
$remixicon-door-fill: '\ec45';
$remixicon-door-line: '\ec46';
$remixicon-door-lock-box-fill: '\ec47';
$remixicon-door-lock-box-line: '\ec48';
$remixicon-door-lock-fill: '\ec49';
$remixicon-door-lock-line: '\ec4a';
$remixicon-door-open-fill: '\ec4b';
$remixicon-door-open-line: '\ec4c';
$remixicon-dossier-fill: '\ec4d';
$remixicon-dossier-line: '\ec4e';
$remixicon-douban-fill: '\ec4f';
$remixicon-douban-line: '\ec50';
$remixicon-double-quotes-l: '\ec51';
$remixicon-double-quotes-r: '\ec52';
$remixicon-download-2-fill: '\ec53';
$remixicon-download-2-line: '\ec54';
$remixicon-download-cloud-2-fill: '\ec55';
$remixicon-download-cloud-2-line: '\ec56';
$remixicon-download-cloud-fill: '\ec57';
$remixicon-download-cloud-line: '\ec58';
$remixicon-download-fill: '\ec59';
$remixicon-download-line: '\ec5a';
$remixicon-draft-fill: '\ec5b';
$remixicon-draft-line: '\ec5c';
$remixicon-drag-drop-fill: '\ec5d';
$remixicon-drag-drop-line: '\ec5e';
$remixicon-drag-move-2-fill: '\ec5f';
$remixicon-drag-move-2-line: '\ec60';
$remixicon-drag-move-fill: '\ec61';
$remixicon-drag-move-line: '\ec62';
$remixicon-dribbble-fill: '\ec63';
$remixicon-dribbble-line: '\ec64';
$remixicon-drive-fill: '\ec65';
$remixicon-drive-line: '\ec66';
$remixicon-drizzle-fill: '\ec67';
$remixicon-drizzle-line: '\ec68';
$remixicon-drop-fill: '\ec69';
$remixicon-drop-line: '\ec6a';
$remixicon-dropbox-fill: '\ec6b';
$remixicon-dropbox-line: '\ec6c';
$remixicon-dual-sim-1-fill: '\ec6d';
$remixicon-dual-sim-1-line: '\ec6e';
$remixicon-dual-sim-2-fill: '\ec6f';
$remixicon-dual-sim-2-line: '\ec70';
$remixicon-dv-fill: '\ec71';
$remixicon-dv-line: '\ec72';
$remixicon-dvd-fill: '\ec73';
$remixicon-dvd-line: '\ec74';
$remixicon-e-bike-2-fill: '\ec75';
$remixicon-e-bike-2-line: '\ec76';
$remixicon-e-bike-fill: '\ec77';
$remixicon-e-bike-line: '\ec78';
$remixicon-earth-fill: '\ec79';
$remixicon-earth-line: '\ec7a';
$remixicon-earthquake-fill: '\ec7b';
$remixicon-earthquake-line: '\ec7c';
$remixicon-edge-fill: '\ec7d';
$remixicon-edge-line: '\ec7e';
$remixicon-edit-2-fill: '\ec7f';
$remixicon-edit-2-line: '\ec80';
$remixicon-edit-box-fill: '\ec81';
$remixicon-edit-box-line: '\ec82';
$remixicon-edit-circle-fill: '\ec83';
$remixicon-edit-circle-line: '\ec84';
$remixicon-edit-fill: '\ec85';
$remixicon-edit-line: '\ec86';
$remixicon-eject-fill: '\ec87';
$remixicon-eject-line: '\ec88';
$remixicon-emotion-2-fill: '\ec89';
$remixicon-emotion-2-line: '\ec8a';
$remixicon-emotion-fill: '\ec8b';
$remixicon-emotion-happy-fill: '\ec8c';
$remixicon-emotion-happy-line: '\ec8d';
$remixicon-emotion-laugh-fill: '\ec8e';
$remixicon-emotion-laugh-line: '\ec8f';
$remixicon-emotion-line: '\ec90';
$remixicon-emotion-normal-fill: '\ec91';
$remixicon-emotion-normal-line: '\ec92';
$remixicon-emotion-sad-fill: '\ec93';
$remixicon-emotion-sad-line: '\ec94';
$remixicon-emotion-unhappy-fill: '\ec95';
$remixicon-emotion-unhappy-line: '\ec96';
$remixicon-empathize-fill: '\ec97';
$remixicon-empathize-line: '\ec98';
$remixicon-emphasis-cn: '\ec99';
$remixicon-emphasis: '\ec9a';
$remixicon-english-input: '\ec9b';
$remixicon-equalizer-fill: '\ec9c';
$remixicon-equalizer-line: '\ec9d';
$remixicon-eraser-fill: '\ec9e';
$remixicon-eraser-line: '\ec9f';
$remixicon-error-warning-fill: '\eca0';
$remixicon-error-warning-line: '\eca1';
$remixicon-evernote-fill: '\eca2';
$remixicon-evernote-line: '\eca3';
$remixicon-exchange-box-fill: '\eca4';
$remixicon-exchange-box-line: '\eca5';
$remixicon-exchange-cny-fill: '\eca6';
$remixicon-exchange-cny-line: '\eca7';
$remixicon-exchange-dollar-fill: '\eca8';
$remixicon-exchange-dollar-line: '\eca9';
$remixicon-exchange-fill: '\ecaa';
$remixicon-exchange-funds-fill: '\ecab';
$remixicon-exchange-funds-line: '\ecac';
$remixicon-exchange-line: '\ecad';
$remixicon-external-link-fill: '\ecae';
$remixicon-external-link-line: '\ecaf';
$remixicon-eye-2-fill: '\ecb0';
$remixicon-eye-2-line: '\ecb1';
$remixicon-eye-close-fill: '\ecb2';
$remixicon-eye-close-line: '\ecb3';
$remixicon-eye-fill: '\ecb4';
$remixicon-eye-line: '\ecb5';
$remixicon-eye-off-fill: '\ecb6';
$remixicon-eye-off-line: '\ecb7';
$remixicon-facebook-box-fill: '\ecb8';
$remixicon-facebook-box-line: '\ecb9';
$remixicon-facebook-circle-fill: '\ecba';
$remixicon-facebook-circle-line: '\ecbb';
$remixicon-facebook-fill: '\ecbc';
$remixicon-facebook-line: '\ecbd';
$remixicon-fahrenheit-fill: '\ecbe';
$remixicon-fahrenheit-line: '\ecbf';
$remixicon-feedback-fill: '\ecc0';
$remixicon-feedback-line: '\ecc1';
$remixicon-file-2-fill: '\ecc2';
$remixicon-file-2-line: '\ecc3';
$remixicon-file-3-fill: '\ecc4';
$remixicon-file-3-line: '\ecc5';
$remixicon-file-4-fill: '\ecc6';
$remixicon-file-4-line: '\ecc7';
$remixicon-file-add-fill: '\ecc8';
$remixicon-file-add-line: '\ecc9';
$remixicon-file-chart-2-fill: '\ecca';
$remixicon-file-chart-2-line: '\eccb';
$remixicon-file-chart-fill: '\eccc';
$remixicon-file-chart-line: '\eccd';
$remixicon-file-cloud-fill: '\ecce';
$remixicon-file-cloud-line: '\eccf';
$remixicon-file-code-fill: '\ecd0';
$remixicon-file-code-line: '\ecd1';
$remixicon-file-copy-2-fill: '\ecd2';
$remixicon-file-copy-2-line: '\ecd3';
$remixicon-file-copy-fill: '\ecd4';
$remixicon-file-copy-line: '\ecd5';
$remixicon-file-damage-fill: '\ecd6';
$remixicon-file-damage-line: '\ecd7';
$remixicon-file-download-fill: '\ecd8';
$remixicon-file-download-line: '\ecd9';
$remixicon-file-edit-fill: '\ecda';
$remixicon-file-edit-line: '\ecdb';
$remixicon-file-excel-2-fill: '\ecdc';
$remixicon-file-excel-2-line: '\ecdd';
$remixicon-file-excel-fill: '\ecde';
$remixicon-file-excel-line: '\ecdf';
$remixicon-file-fill: '\ece0';
$remixicon-file-forbid-fill: '\ece1';
$remixicon-file-forbid-line: '\ece2';
$remixicon-file-gif-fill: '\ece3';
$remixicon-file-gif-line: '\ece4';
$remixicon-file-history-fill: '\ece5';
$remixicon-file-history-line: '\ece6';
$remixicon-file-hwp-fill: '\ece7';
$remixicon-file-hwp-line: '\ece8';
$remixicon-file-info-fill: '\ece9';
$remixicon-file-info-line: '\ecea';
$remixicon-file-line: '\eceb';
$remixicon-file-list-2-fill: '\ecec';
$remixicon-file-list-2-line: '\eced';
$remixicon-file-list-3-fill: '\ecee';
$remixicon-file-list-3-line: '\ecef';
$remixicon-file-list-fill: '\ecf0';
$remixicon-file-list-line: '\ecf1';
$remixicon-file-lock-fill: '\ecf2';
$remixicon-file-lock-line: '\ecf3';
$remixicon-file-marked-fill: '\ecf4';
$remixicon-file-marked-line: '\ecf5';
$remixicon-file-music-fill: '\ecf6';
$remixicon-file-music-line: '\ecf7';
$remixicon-file-paper-2-fill: '\ecf8';
$remixicon-file-paper-2-line: '\ecf9';
$remixicon-file-paper-fill: '\ecfa';
$remixicon-file-paper-line: '\ecfb';
$remixicon-file-pdf-fill: '\ecfc';
$remixicon-file-pdf-line: '\ecfd';
$remixicon-file-ppt-2-fill: '\ecfe';
$remixicon-file-ppt-2-line: '\ecff';
$remixicon-file-ppt-fill: '\ed00';
$remixicon-file-ppt-line: '\ed01';
$remixicon-file-reduce-fill: '\ed02';
$remixicon-file-reduce-line: '\ed03';
$remixicon-file-search-fill: '\ed04';
$remixicon-file-search-line: '\ed05';
$remixicon-file-settings-fill: '\ed06';
$remixicon-file-settings-line: '\ed07';
$remixicon-file-shield-2-fill: '\ed08';
$remixicon-file-shield-2-line: '\ed09';
$remixicon-file-shield-fill: '\ed0a';
$remixicon-file-shield-line: '\ed0b';
$remixicon-file-shred-fill: '\ed0c';
$remixicon-file-shred-line: '\ed0d';
$remixicon-file-text-fill: '\ed0e';
$remixicon-file-text-line: '\ed0f';
$remixicon-file-transfer-fill: '\ed10';
$remixicon-file-transfer-line: '\ed11';
$remixicon-file-unknow-fill: '\ed12';
$remixicon-file-unknow-line: '\ed13';
$remixicon-file-upload-fill: '\ed14';
$remixicon-file-upload-line: '\ed15';
$remixicon-file-user-fill: '\ed16';
$remixicon-file-user-line: '\ed17';
$remixicon-file-warning-fill: '\ed18';
$remixicon-file-warning-line: '\ed19';
$remixicon-file-word-2-fill: '\ed1a';
$remixicon-file-word-2-line: '\ed1b';
$remixicon-file-word-fill: '\ed1c';
$remixicon-file-word-line: '\ed1d';
$remixicon-file-zip-fill: '\ed1e';
$remixicon-file-zip-line: '\ed1f';
$remixicon-film-fill: '\ed20';
$remixicon-film-line: '\ed21';
$remixicon-filter-2-fill: '\ed22';
$remixicon-filter-2-line: '\ed23';
$remixicon-filter-3-fill: '\ed24';
$remixicon-filter-3-line: '\ed25';
$remixicon-filter-fill: '\ed26';
$remixicon-filter-line: '\ed27';
$remixicon-filter-off-fill: '\ed28';
$remixicon-filter-off-line: '\ed29';
$remixicon-find-replace-fill: '\ed2a';
$remixicon-find-replace-line: '\ed2b';
$remixicon-finder-fill: '\ed2c';
$remixicon-finder-line: '\ed2d';
$remixicon-fingerprint-2-fill: '\ed2e';
$remixicon-fingerprint-2-line: '\ed2f';
$remixicon-fingerprint-fill: '\ed30';
$remixicon-fingerprint-line: '\ed31';
$remixicon-fire-fill: '\ed32';
$remixicon-fire-line: '\ed33';
$remixicon-firefox-fill: '\ed34';
$remixicon-firefox-line: '\ed35';
$remixicon-first-aid-kit-fill: '\ed36';
$remixicon-first-aid-kit-line: '\ed37';
$remixicon-flag-2-fill: '\ed38';
$remixicon-flag-2-line: '\ed39';
$remixicon-flag-fill: '\ed3a';
$remixicon-flag-line: '\ed3b';
$remixicon-flashlight-fill: '\ed3c';
$remixicon-flashlight-line: '\ed3d';
$remixicon-flask-fill: '\ed3e';
$remixicon-flask-line: '\ed3f';
$remixicon-flight-land-fill: '\ed40';
$remixicon-flight-land-line: '\ed41';
$remixicon-flight-takeoff-fill: '\ed42';
$remixicon-flight-takeoff-line: '\ed43';
$remixicon-flood-fill: '\ed44';
$remixicon-flood-line: '\ed45';
$remixicon-flow-chart: '\ed46';
$remixicon-flutter-fill: '\ed47';
$remixicon-flutter-line: '\ed48';
$remixicon-focus-2-fill: '\ed49';
$remixicon-focus-2-line: '\ed4a';
$remixicon-focus-3-fill: '\ed4b';
$remixicon-focus-3-line: '\ed4c';
$remixicon-focus-fill: '\ed4d';
$remixicon-focus-line: '\ed4e';
$remixicon-foggy-fill: '\ed4f';
$remixicon-foggy-line: '\ed50';
$remixicon-folder-2-fill: '\ed51';
$remixicon-folder-2-line: '\ed52';
$remixicon-folder-3-fill: '\ed53';
$remixicon-folder-3-line: '\ed54';
$remixicon-folder-4-fill: '\ed55';
$remixicon-folder-4-line: '\ed56';
$remixicon-folder-5-fill: '\ed57';
$remixicon-folder-5-line: '\ed58';
$remixicon-folder-add-fill: '\ed59';
$remixicon-folder-add-line: '\ed5a';
$remixicon-folder-chart-2-fill: '\ed5b';
$remixicon-folder-chart-2-line: '\ed5c';
$remixicon-folder-chart-fill: '\ed5d';
$remixicon-folder-chart-line: '\ed5e';
$remixicon-folder-download-fill: '\ed5f';
$remixicon-folder-download-line: '\ed60';
$remixicon-folder-fill: '\ed61';
$remixicon-folder-forbid-fill: '\ed62';
$remixicon-folder-forbid-line: '\ed63';
$remixicon-folder-history-fill: '\ed64';
$remixicon-folder-history-line: '\ed65';
$remixicon-folder-info-fill: '\ed66';
$remixicon-folder-info-line: '\ed67';
$remixicon-folder-keyhole-fill: '\ed68';
$remixicon-folder-keyhole-line: '\ed69';
$remixicon-folder-line: '\ed6a';
$remixicon-folder-lock-fill: '\ed6b';
$remixicon-folder-lock-line: '\ed6c';
$remixicon-folder-music-fill: '\ed6d';
$remixicon-folder-music-line: '\ed6e';
$remixicon-folder-open-fill: '\ed6f';
$remixicon-folder-open-line: '\ed70';
$remixicon-folder-received-fill: '\ed71';
$remixicon-folder-received-line: '\ed72';
$remixicon-folder-reduce-fill: '\ed73';
$remixicon-folder-reduce-line: '\ed74';
$remixicon-folder-settings-fill: '\ed75';
$remixicon-folder-settings-line: '\ed76';
$remixicon-folder-shared-fill: '\ed77';
$remixicon-folder-shared-line: '\ed78';
$remixicon-folder-shield-2-fill: '\ed79';
$remixicon-folder-shield-2-line: '\ed7a';
$remixicon-folder-shield-fill: '\ed7b';
$remixicon-folder-shield-line: '\ed7c';
$remixicon-folder-transfer-fill: '\ed7d';
$remixicon-folder-transfer-line: '\ed7e';
$remixicon-folder-unknow-fill: '\ed7f';
$remixicon-folder-unknow-line: '\ed80';
$remixicon-folder-upload-fill: '\ed81';
$remixicon-folder-upload-line: '\ed82';
$remixicon-folder-user-fill: '\ed83';
$remixicon-folder-user-line: '\ed84';
$remixicon-folder-warning-fill: '\ed85';
$remixicon-folder-warning-line: '\ed86';
$remixicon-folder-zip-fill: '\ed87';
$remixicon-folder-zip-line: '\ed88';
$remixicon-folders-fill: '\ed89';
$remixicon-folders-line: '\ed8a';
$remixicon-font-color: '\ed8b';
$remixicon-font-size-2: '\ed8c';
$remixicon-font-size: '\ed8d';
$remixicon-football-fill: '\ed8e';
$remixicon-football-line: '\ed8f';
$remixicon-footprint-fill: '\ed90';
$remixicon-footprint-line: '\ed91';
$remixicon-forbid-2-fill: '\ed92';
$remixicon-forbid-2-line: '\ed93';
$remixicon-forbid-fill: '\ed94';
$remixicon-forbid-line: '\ed95';
$remixicon-format-clear: '\ed96';
$remixicon-fridge-fill: '\ed97';
$remixicon-fridge-line: '\ed98';
$remixicon-fullscreen-exit-fill: '\ed99';
$remixicon-fullscreen-exit-line: '\ed9a';
$remixicon-fullscreen-fill: '\ed9b';
$remixicon-fullscreen-line: '\ed9c';
$remixicon-function-fill: '\ed9d';
$remixicon-function-line: '\ed9e';
$remixicon-functions: '\ed9f';
$remixicon-funds-box-fill: '\eda0';
$remixicon-funds-box-line: '\eda1';
$remixicon-funds-fill: '\eda2';
$remixicon-funds-line: '\eda3';
$remixicon-gallery-fill: '\eda4';
$remixicon-gallery-line: '\eda5';
$remixicon-gallery-upload-fill: '\eda6';
$remixicon-gallery-upload-line: '\eda7';
$remixicon-game-fill: '\eda8';
$remixicon-game-line: '\eda9';
$remixicon-gamepad-fill: '\edaa';
$remixicon-gamepad-line: '\edab';
$remixicon-gas-station-fill: '\edac';
$remixicon-gas-station-line: '\edad';
$remixicon-gatsby-fill: '\edae';
$remixicon-gatsby-line: '\edaf';
$remixicon-genderless-fill: '\edb0';
$remixicon-genderless-line: '\edb1';
$remixicon-ghost-2-fill: '\edb2';
$remixicon-ghost-2-line: '\edb3';
$remixicon-ghost-fill: '\edb4';
$remixicon-ghost-line: '\edb5';
$remixicon-ghost-smile-fill: '\edb6';
$remixicon-ghost-smile-line: '\edb7';
$remixicon-gift-2-fill: '\edb8';
$remixicon-gift-2-line: '\edb9';
$remixicon-gift-fill: '\edba';
$remixicon-gift-line: '\edbb';
$remixicon-git-branch-fill: '\edbc';
$remixicon-git-branch-line: '\edbd';
$remixicon-git-commit-fill: '\edbe';
$remixicon-git-commit-line: '\edbf';
$remixicon-git-merge-fill: '\edc0';
$remixicon-git-merge-line: '\edc1';
$remixicon-git-pull-request-fill: '\edc2';
$remixicon-git-pull-request-line: '\edc3';
$remixicon-git-repository-commits-fill: '\edc4';
$remixicon-git-repository-commits-line: '\edc5';
$remixicon-git-repository-fill: '\edc6';
$remixicon-git-repository-line: '\edc7';
$remixicon-git-repository-private-fill: '\edc8';
$remixicon-git-repository-private-line: '\edc9';
$remixicon-github-fill: '\edca';
$remixicon-github-line: '\edcb';
$remixicon-gitlab-fill: '\edcc';
$remixicon-gitlab-line: '\edcd';
$remixicon-global-fill: '\edce';
$remixicon-global-line: '\edcf';
$remixicon-globe-fill: '\edd0';
$remixicon-globe-line: '\edd1';
$remixicon-goblet-fill: '\edd2';
$remixicon-goblet-line: '\edd3';
$remixicon-google-fill: '\edd4';
$remixicon-google-line: '\edd5';
$remixicon-google-play-fill: '\edd6';
$remixicon-google-play-line: '\edd7';
$remixicon-government-fill: '\edd8';
$remixicon-government-line: '\edd9';
$remixicon-gps-fill: '\edda';
$remixicon-gps-line: '\eddb';
$remixicon-gradienter-fill: '\eddc';
$remixicon-gradienter-line: '\eddd';
$remixicon-grid-fill: '\edde';
$remixicon-grid-line: '\eddf';
$remixicon-group-2-fill: '\ede0';
$remixicon-group-2-line: '\ede1';
$remixicon-group-fill: '\ede2';
$remixicon-group-line: '\ede3';
$remixicon-guide-fill: '\ede4';
$remixicon-guide-line: '\ede5';
$remixicon-h-1: '\ede6';
$remixicon-h-2: '\ede7';
$remixicon-h-3: '\ede8';
$remixicon-h-4: '\ede9';
$remixicon-h-5: '\edea';
$remixicon-h-6: '\edeb';
$remixicon-hail-fill: '\edec';
$remixicon-hail-line: '\eded';
$remixicon-hammer-fill: '\edee';
$remixicon-hammer-line: '\edef';
$remixicon-hand-coin-fill: '\edf0';
$remixicon-hand-coin-line: '\edf1';
$remixicon-hand-heart-fill: '\edf2';
$remixicon-hand-heart-line: '\edf3';
$remixicon-hand-sanitizer-fill: '\edf4';
$remixicon-hand-sanitizer-line: '\edf5';
$remixicon-handbag-fill: '\edf6';
$remixicon-handbag-line: '\edf7';
$remixicon-hard-drive-2-fill: '\edf8';
$remixicon-hard-drive-2-line: '\edf9';
$remixicon-hard-drive-fill: '\edfa';
$remixicon-hard-drive-line: '\edfb';
$remixicon-hashtag: '\edfc';
$remixicon-haze-2-fill: '\edfd';
$remixicon-haze-2-line: '\edfe';
$remixicon-haze-fill: '\edff';
$remixicon-haze-line: '\ee00';
$remixicon-hd-fill: '\ee01';
$remixicon-hd-line: '\ee02';
$remixicon-heading: '\ee03';
$remixicon-headphone-fill: '\ee04';
$remixicon-headphone-line: '\ee05';
$remixicon-health-book-fill: '\ee06';
$remixicon-health-book-line: '\ee07';
$remixicon-heart-2-fill: '\ee08';
$remixicon-heart-2-line: '\ee09';
$remixicon-heart-3-fill: '\ee0a';
$remixicon-heart-3-line: '\ee0b';
$remixicon-heart-add-fill: '\ee0c';
$remixicon-heart-add-line: '\ee0d';
$remixicon-heart-fill: '\ee0e';
$remixicon-heart-line: '\ee0f';
$remixicon-heart-pulse-fill: '\ee10';
$remixicon-heart-pulse-line: '\ee11';
$remixicon-hearts-fill: '\ee12';
$remixicon-hearts-line: '\ee13';
$remixicon-heavy-showers-fill: '\ee14';
$remixicon-heavy-showers-line: '\ee15';
$remixicon-history-fill: '\ee16';
$remixicon-history-line: '\ee17';
$remixicon-home-2-fill: '\ee18';
$remixicon-home-2-line: '\ee19';
$remixicon-home-3-fill: '\ee1a';
$remixicon-home-3-line: '\ee1b';
$remixicon-home-4-fill: '\ee1c';
$remixicon-home-4-line: '\ee1d';
$remixicon-home-5-fill: '\ee1e';
$remixicon-home-5-line: '\ee1f';
$remixicon-home-6-fill: '\ee20';
$remixicon-home-6-line: '\ee21';
$remixicon-home-7-fill: '\ee22';
$remixicon-home-7-line: '\ee23';
$remixicon-home-8-fill: '\ee24';
$remixicon-home-8-line: '\ee25';
$remixicon-home-fill: '\ee26';
$remixicon-home-gear-fill: '\ee27';
$remixicon-home-gear-line: '\ee28';
$remixicon-home-heart-fill: '\ee29';
$remixicon-home-heart-line: '\ee2a';
$remixicon-home-line: '\ee2b';
$remixicon-home-smile-2-fill: '\ee2c';
$remixicon-home-smile-2-line: '\ee2d';
$remixicon-home-smile-fill: '\ee2e';
$remixicon-home-smile-line: '\ee2f';
$remixicon-home-wifi-fill: '\ee30';
$remixicon-home-wifi-line: '\ee31';
$remixicon-honor-of-kings-fill: '\ee32';
$remixicon-honor-of-kings-line: '\ee33';
$remixicon-honour-fill: '\ee34';
$remixicon-honour-line: '\ee35';
$remixicon-hospital-fill: '\ee36';
$remixicon-hospital-line: '\ee37';
$remixicon-hotel-bed-fill: '\ee38';
$remixicon-hotel-bed-line: '\ee39';
$remixicon-hotel-fill: '\ee3a';
$remixicon-hotel-line: '\ee3b';
$remixicon-hotspot-fill: '\ee3c';
$remixicon-hotspot-line: '\ee3d';
$remixicon-hq-fill: '\ee3e';
$remixicon-hq-line: '\ee3f';
$remixicon-html5-fill: '\ee40';
$remixicon-html5-line: '\ee41';
$remixicon-ie-fill: '\ee42';
$remixicon-ie-line: '\ee43';
$remixicon-image-2-fill: '\ee44';
$remixicon-image-2-line: '\ee45';
$remixicon-image-add-fill: '\ee46';
$remixicon-image-add-line: '\ee47';
$remixicon-image-edit-fill: '\ee48';
$remixicon-image-edit-line: '\ee49';
$remixicon-image-fill: '\ee4a';
$remixicon-image-line: '\ee4b';
$remixicon-inbox-archive-fill: '\ee4c';
$remixicon-inbox-archive-line: '\ee4d';
$remixicon-inbox-fill: '\ee4e';
$remixicon-inbox-line: '\ee4f';
$remixicon-inbox-unarchive-fill: '\ee50';
$remixicon-inbox-unarchive-line: '\ee51';
$remixicon-increase-decrease-fill: '\ee52';
$remixicon-increase-decrease-line: '\ee53';
$remixicon-indent-decrease: '\ee54';
$remixicon-indent-increase: '\ee55';
$remixicon-indeterminate-circle-fill: '\ee56';
$remixicon-indeterminate-circle-line: '\ee57';
$remixicon-information-fill: '\ee58';
$remixicon-information-line: '\ee59';
$remixicon-infrared-thermometer-fill: '\ee5a';
$remixicon-infrared-thermometer-line: '\ee5b';
$remixicon-ink-bottle-fill: '\ee5c';
$remixicon-ink-bottle-line: '\ee5d';
$remixicon-input-cursor-move: '\ee5e';
$remixicon-input-method-fill: '\ee5f';
$remixicon-input-method-line: '\ee60';
$remixicon-insert-column-left: '\ee61';
$remixicon-insert-column-right: '\ee62';
$remixicon-insert-row-bottom: '\ee63';
$remixicon-insert-row-top: '\ee64';
$remixicon-instagram-fill: '\ee65';
$remixicon-instagram-line: '\ee66';
$remixicon-install-fill: '\ee67';
$remixicon-install-line: '\ee68';
$remixicon-invision-fill: '\ee69';
$remixicon-invision-line: '\ee6a';
$remixicon-italic: '\ee6b';
$remixicon-kakao-talk-fill: '\ee6c';
$remixicon-kakao-talk-line: '\ee6d';
$remixicon-key-2-fill: '\ee6e';
$remixicon-key-2-line: '\ee6f';
$remixicon-key-fill: '\ee70';
$remixicon-key-line: '\ee71';
$remixicon-keyboard-box-fill: '\ee72';
$remixicon-keyboard-box-line: '\ee73';
$remixicon-keyboard-fill: '\ee74';
$remixicon-keyboard-line: '\ee75';
$remixicon-keynote-fill: '\ee76';
$remixicon-keynote-line: '\ee77';
$remixicon-knife-blood-fill: '\ee78';
$remixicon-knife-blood-line: '\ee79';
$remixicon-knife-fill: '\ee7a';
$remixicon-knife-line: '\ee7b';
$remixicon-landscape-fill: '\ee7c';
$remixicon-landscape-line: '\ee7d';
$remixicon-layout-2-fill: '\ee7e';
$remixicon-layout-2-line: '\ee7f';
$remixicon-layout-3-fill: '\ee80';
$remixicon-layout-3-line: '\ee81';
$remixicon-layout-4-fill: '\ee82';
$remixicon-layout-4-line: '\ee83';
$remixicon-layout-5-fill: '\ee84';
$remixicon-layout-5-line: '\ee85';
$remixicon-layout-6-fill: '\ee86';
$remixicon-layout-6-line: '\ee87';
$remixicon-layout-bottom-2-fill: '\ee88';
$remixicon-layout-bottom-2-line: '\ee89';
$remixicon-layout-bottom-fill: '\ee8a';
$remixicon-layout-bottom-line: '\ee8b';
$remixicon-layout-column-fill: '\ee8c';
$remixicon-layout-column-line: '\ee8d';
$remixicon-layout-fill: '\ee8e';
$remixicon-layout-grid-fill: '\ee8f';
$remixicon-layout-grid-line: '\ee90';
$remixicon-layout-left-2-fill: '\ee91';
$remixicon-layout-left-2-line: '\ee92';
$remixicon-layout-left-fill: '\ee93';
$remixicon-layout-left-line: '\ee94';
$remixicon-layout-line: '\ee95';
$remixicon-layout-masonry-fill: '\ee96';
$remixicon-layout-masonry-line: '\ee97';
$remixicon-layout-right-2-fill: '\ee98';
$remixicon-layout-right-2-line: '\ee99';
$remixicon-layout-right-fill: '\ee9a';
$remixicon-layout-right-line: '\ee9b';
$remixicon-layout-row-fill: '\ee9c';
$remixicon-layout-row-line: '\ee9d';
$remixicon-layout-top-2-fill: '\ee9e';
$remixicon-layout-top-2-line: '\ee9f';
$remixicon-layout-top-fill: '\eea0';
$remixicon-layout-top-line: '\eea1';
$remixicon-leaf-fill: '\eea2';
$remixicon-leaf-line: '\eea3';
$remixicon-lifebuoy-fill: '\eea4';
$remixicon-lifebuoy-line: '\eea5';
$remixicon-lightbulb-fill: '\eea6';
$remixicon-lightbulb-flash-fill: '\eea7';
$remixicon-lightbulb-flash-line: '\eea8';
$remixicon-lightbulb-line: '\eea9';
$remixicon-line-chart-fill: '\eeaa';
$remixicon-line-chart-line: '\eeab';
$remixicon-line-fill: '\eeac';
$remixicon-line-height: '\eead';
$remixicon-line-line: '\eeae';
$remixicon-link-m: '\eeaf';
$remixicon-link-unlink-m: '\eeb0';
$remixicon-link-unlink: '\eeb1';
$remixicon-link: '\eeb2';
$remixicon-linkedin-box-fill: '\eeb3';
$remixicon-linkedin-box-line: '\eeb4';
$remixicon-linkedin-fill: '\eeb5';
$remixicon-linkedin-line: '\eeb6';
$remixicon-links-fill: '\eeb7';
$remixicon-links-line: '\eeb8';
$remixicon-list-check-2: '\eeb9';
$remixicon-list-check: '\eeba';
$remixicon-list-ordered: '\eebb';
$remixicon-list-settings-fill: '\eebc';
$remixicon-list-settings-line: '\eebd';
$remixicon-list-unordered: '\eebe';
$remixicon-live-fill: '\eebf';
$remixicon-live-line: '\eec0';
$remixicon-loader-2-fill: '\eec1';
$remixicon-loader-2-line: '\eec2';
$remixicon-loader-3-fill: '\eec3';
$remixicon-loader-3-line: '\eec4';
$remixicon-loader-4-fill: '\eec5';
$remixicon-loader-4-line: '\eec6';
$remixicon-loader-5-fill: '\eec7';
$remixicon-loader-5-line: '\eec8';
$remixicon-loader-fill: '\eec9';
$remixicon-loader-line: '\eeca';
$remixicon-lock-2-fill: '\eecb';
$remixicon-lock-2-line: '\eecc';
$remixicon-lock-fill: '\eecd';
$remixicon-lock-line: '\eece';
$remixicon-lock-password-fill: '\eecf';
$remixicon-lock-password-line: '\eed0';
$remixicon-lock-unlock-fill: '\eed1';
$remixicon-lock-unlock-line: '\eed2';
$remixicon-login-box-fill: '\eed3';
$remixicon-login-box-line: '\eed4';
$remixicon-login-circle-fill: '\eed5';
$remixicon-login-circle-line: '\eed6';
$remixicon-logout-box-fill: '\eed7';
$remixicon-logout-box-line: '\eed8';
$remixicon-logout-box-r-fill: '\eed9';
$remixicon-logout-box-r-line: '\eeda';
$remixicon-logout-circle-fill: '\eedb';
$remixicon-logout-circle-line: '\eedc';
$remixicon-logout-circle-r-fill: '\eedd';
$remixicon-logout-circle-r-line: '\eede';
$remixicon-luggage-cart-fill: '\eedf';
$remixicon-luggage-cart-line: '\eee0';
$remixicon-luggage-deposit-fill: '\eee1';
$remixicon-luggage-deposit-line: '\eee2';
$remixicon-lungs-fill: '\eee3';
$remixicon-lungs-line: '\eee4';
$remixicon-mac-fill: '\eee5';
$remixicon-mac-line: '\eee6';
$remixicon-macbook-fill: '\eee7';
$remixicon-macbook-line: '\eee8';
$remixicon-magic-fill: '\eee9';
$remixicon-magic-line: '\eeea';
$remixicon-mail-add-fill: '\eeeb';
$remixicon-mail-add-line: '\eeec';
$remixicon-mail-check-fill: '\eeed';
$remixicon-mail-check-line: '\eeee';
$remixicon-mail-close-fill: '\eeef';
$remixicon-mail-close-line: '\eef0';
$remixicon-mail-download-fill: '\eef1';
$remixicon-mail-download-line: '\eef2';
$remixicon-mail-fill: '\eef3';
$remixicon-mail-forbid-fill: '\eef4';
$remixicon-mail-forbid-line: '\eef5';
$remixicon-mail-line: '\eef6';
$remixicon-mail-lock-fill: '\eef7';
$remixicon-mail-lock-line: '\eef8';
$remixicon-mail-open-fill: '\eef9';
$remixicon-mail-open-line: '\eefa';
$remixicon-mail-send-fill: '\eefb';
$remixicon-mail-send-line: '\eefc';
$remixicon-mail-settings-fill: '\eefd';
$remixicon-mail-settings-line: '\eefe';
$remixicon-mail-star-fill: '\eeff';
$remixicon-mail-star-line: '\ef00';
$remixicon-mail-unread-fill: '\ef01';
$remixicon-mail-unread-line: '\ef02';
$remixicon-mail-volume-fill: '\ef03';
$remixicon-mail-volume-line: '\ef04';
$remixicon-map-2-fill: '\ef05';
$remixicon-map-2-line: '\ef06';
$remixicon-map-fill: '\ef07';
$remixicon-map-line: '\ef08';
$remixicon-map-pin-2-fill: '\ef09';
$remixicon-map-pin-2-line: '\ef0a';
$remixicon-map-pin-3-fill: '\ef0b';
$remixicon-map-pin-3-line: '\ef0c';
$remixicon-map-pin-4-fill: '\ef0d';
$remixicon-map-pin-4-line: '\ef0e';
$remixicon-map-pin-5-fill: '\ef0f';
$remixicon-map-pin-5-line: '\ef10';
$remixicon-map-pin-add-fill: '\ef11';
$remixicon-map-pin-add-line: '\ef12';
$remixicon-map-pin-fill: '\ef13';
$remixicon-map-pin-line: '\ef14';
$remixicon-map-pin-range-fill: '\ef15';
$remixicon-map-pin-range-line: '\ef16';
$remixicon-map-pin-time-fill: '\ef17';
$remixicon-map-pin-time-line: '\ef18';
$remixicon-map-pin-user-fill: '\ef19';
$remixicon-map-pin-user-line: '\ef1a';
$remixicon-mark-pen-fill: '\ef1b';
$remixicon-mark-pen-line: '\ef1c';
$remixicon-markdown-fill: '\ef1d';
$remixicon-markdown-line: '\ef1e';
$remixicon-markup-fill: '\ef1f';
$remixicon-markup-line: '\ef20';
$remixicon-mastercard-fill: '\ef21';
$remixicon-mastercard-line: '\ef22';
$remixicon-mastodon-fill: '\ef23';
$remixicon-mastodon-line: '\ef24';
$remixicon-medal-2-fill: '\ef25';
$remixicon-medal-2-line: '\ef26';
$remixicon-medal-fill: '\ef27';
$remixicon-medal-line: '\ef28';
$remixicon-medicine-bottle-fill: '\ef29';
$remixicon-medicine-bottle-line: '\ef2a';
$remixicon-medium-fill: '\ef2b';
$remixicon-medium-line: '\ef2c';
$remixicon-men-fill: '\ef2d';
$remixicon-men-line: '\ef2e';
$remixicon-mental-health-fill: '\ef2f';
$remixicon-mental-health-line: '\ef30';
$remixicon-menu-2-fill: '\ef31';
$remixicon-menu-2-line: '\ef32';
$remixicon-menu-3-fill: '\ef33';
$remixicon-menu-3-line: '\ef34';
$remixicon-menu-4-fill: '\ef35';
$remixicon-menu-4-line: '\ef36';
$remixicon-menu-5-fill: '\ef37';
$remixicon-menu-5-line: '\ef38';
$remixicon-menu-add-fill: '\ef39';
$remixicon-menu-add-line: '\ef3a';
$remixicon-menu-fill: '\ef3b';
$remixicon-menu-fold-fill: '\ef3c';
$remixicon-menu-fold-line: '\ef3d';
$remixicon-menu-line: '\ef3e';
$remixicon-menu-unfold-fill: '\ef3f';
$remixicon-menu-unfold-line: '\ef40';
$remixicon-merge-cells-horizontal: '\ef41';
$remixicon-merge-cells-vertical: '\ef42';
$remixicon-message-2-fill: '\ef43';
$remixicon-message-2-line: '\ef44';
$remixicon-message-3-fill: '\ef45';
$remixicon-message-3-line: '\ef46';
$remixicon-message-fill: '\ef47';
$remixicon-message-line: '\ef48';
$remixicon-messenger-fill: '\ef49';
$remixicon-messenger-line: '\ef4a';
$remixicon-meteor-fill: '\ef4b';
$remixicon-meteor-line: '\ef4c';
$remixicon-mic-2-fill: '\ef4d';
$remixicon-mic-2-line: '\ef4e';
$remixicon-mic-fill: '\ef4f';
$remixicon-mic-line: '\ef50';
$remixicon-mic-off-fill: '\ef51';
$remixicon-mic-off-line: '\ef52';
$remixicon-mickey-fill: '\ef53';
$remixicon-mickey-line: '\ef54';
$remixicon-microscope-fill: '\ef55';
$remixicon-microscope-line: '\ef56';
$remixicon-microsoft-fill: '\ef57';
$remixicon-microsoft-line: '\ef58';
$remixicon-mind-map: '\ef59';
$remixicon-mini-program-fill: '\ef5a';
$remixicon-mini-program-line: '\ef5b';
$remixicon-mist-fill: '\ef5c';
$remixicon-mist-line: '\ef5d';
$remixicon-money-cny-box-fill: '\ef5e';
$remixicon-money-cny-box-line: '\ef5f';
$remixicon-money-cny-circle-fill: '\ef60';
$remixicon-money-cny-circle-line: '\ef61';
$remixicon-money-dollar-box-fill: '\ef62';
$remixicon-money-dollar-box-line: '\ef63';
$remixicon-money-dollar-circle-fill: '\ef64';
$remixicon-money-dollar-circle-line: '\ef65';
$remixicon-money-euro-box-fill: '\ef66';
$remixicon-money-euro-box-line: '\ef67';
$remixicon-money-euro-circle-fill: '\ef68';
$remixicon-money-euro-circle-line: '\ef69';
$remixicon-money-pound-box-fill: '\ef6a';
$remixicon-money-pound-box-line: '\ef6b';
$remixicon-money-pound-circle-fill: '\ef6c';
$remixicon-money-pound-circle-line: '\ef6d';
$remixicon-moon-clear-fill: '\ef6e';
$remixicon-moon-clear-line: '\ef6f';
$remixicon-moon-cloudy-fill: '\ef70';
$remixicon-moon-cloudy-line: '\ef71';
$remixicon-moon-fill: '\ef72';
$remixicon-moon-foggy-fill: '\ef73';
$remixicon-moon-foggy-line: '\ef74';
$remixicon-moon-line: '\ef75';
$remixicon-more-2-fill: '\ef76';
$remixicon-more-2-line: '\ef77';
$remixicon-more-fill: '\ef78';
$remixicon-more-line: '\ef79';
$remixicon-motorbike-fill: '\ef7a';
$remixicon-motorbike-line: '\ef7b';
$remixicon-mouse-fill: '\ef7c';
$remixicon-mouse-line: '\ef7d';
$remixicon-movie-2-fill: '\ef7e';
$remixicon-movie-2-line: '\ef7f';
$remixicon-movie-fill: '\ef80';
$remixicon-movie-line: '\ef81';
$remixicon-music-2-fill: '\ef82';
$remixicon-music-2-line: '\ef83';
$remixicon-music-fill: '\ef84';
$remixicon-music-line: '\ef85';
$remixicon-mv-fill: '\ef86';
$remixicon-mv-line: '\ef87';
$remixicon-navigation-fill: '\ef88';
$remixicon-navigation-line: '\ef89';
$remixicon-netease-cloud-music-fill: '\ef8a';
$remixicon-netease-cloud-music-line: '\ef8b';
$remixicon-netflix-fill: '\ef8c';
$remixicon-netflix-line: '\ef8d';
$remixicon-newspaper-fill: '\ef8e';
$remixicon-newspaper-line: '\ef8f';
$remixicon-node-tree: '\ef90';
$remixicon-notification-2-fill: '\ef91';
$remixicon-notification-2-line: '\ef92';
$remixicon-notification-3-fill: '\ef93';
$remixicon-notification-3-line: '\ef94';
$remixicon-notification-4-fill: '\ef95';
$remixicon-notification-4-line: '\ef96';
$remixicon-notification-badge-fill: '\ef97';
$remixicon-notification-badge-line: '\ef98';
$remixicon-notification-fill: '\ef99';
$remixicon-notification-line: '\ef9a';
$remixicon-notification-off-fill: '\ef9b';
$remixicon-notification-off-line: '\ef9c';
$remixicon-npmjs-fill: '\ef9d';
$remixicon-npmjs-line: '\ef9e';
$remixicon-number-0: '\ef9f';
$remixicon-number-1: '\efa0';
$remixicon-number-2: '\efa1';
$remixicon-number-3: '\efa2';
$remixicon-number-4: '\efa3';
$remixicon-number-5: '\efa4';
$remixicon-number-6: '\efa5';
$remixicon-number-7: '\efa6';
$remixicon-number-8: '\efa7';
$remixicon-number-9: '\efa8';
$remixicon-numbers-fill: '\efa9';
$remixicon-numbers-line: '\efaa';
$remixicon-nurse-fill: '\efab';
$remixicon-nurse-line: '\efac';
$remixicon-oil-fill: '\efad';
$remixicon-oil-line: '\efae';
$remixicon-omega: '\efaf';
$remixicon-open-arm-fill: '\efb0';
$remixicon-open-arm-line: '\efb1';
$remixicon-open-source-fill: '\efb2';
$remixicon-open-source-line: '\efb3';
$remixicon-opera-fill: '\efb4';
$remixicon-opera-line: '\efb5';
$remixicon-order-play-fill: '\efb6';
$remixicon-order-play-line: '\efb7';
$remixicon-organization-chart: '\efb8';
$remixicon-outlet-2-fill: '\efb9';
$remixicon-outlet-2-line: '\efba';
$remixicon-outlet-fill: '\efbb';
$remixicon-outlet-line: '\efbc';
$remixicon-page-separator: '\efbd';
$remixicon-pages-fill: '\efbe';
$remixicon-pages-line: '\efbf';
$remixicon-paint-brush-fill: '\efc0';
$remixicon-paint-brush-line: '\efc1';
$remixicon-paint-fill: '\efc2';
$remixicon-paint-line: '\efc3';
$remixicon-palette-fill: '\efc4';
$remixicon-palette-line: '\efc5';
$remixicon-pantone-fill: '\efc6';
$remixicon-pantone-line: '\efc7';
$remixicon-paragraph: '\efc8';
$remixicon-parent-fill: '\efc9';
$remixicon-parent-line: '\efca';
$remixicon-parentheses-fill: '\efcb';
$remixicon-parentheses-line: '\efcc';
$remixicon-parking-box-fill: '\efcd';
$remixicon-parking-box-line: '\efce';
$remixicon-parking-fill: '\efcf';
$remixicon-parking-line: '\efd0';
$remixicon-passport-fill: '\efd1';
$remixicon-passport-line: '\efd2';
$remixicon-patreon-fill: '\efd3';
$remixicon-patreon-line: '\efd4';
$remixicon-pause-circle-fill: '\efd5';
$remixicon-pause-circle-line: '\efd6';
$remixicon-pause-fill: '\efd7';
$remixicon-pause-line: '\efd8';
$remixicon-pause-mini-fill: '\efd9';
$remixicon-pause-mini-line: '\efda';
$remixicon-paypal-fill: '\efdb';
$remixicon-paypal-line: '\efdc';
$remixicon-pen-nib-fill: '\efdd';
$remixicon-pen-nib-line: '\efde';
$remixicon-pencil-fill: '\efdf';
$remixicon-pencil-line: '\efe0';
$remixicon-pencil-ruler-2-fill: '\efe1';
$remixicon-pencil-ruler-2-line: '\efe2';
$remixicon-pencil-ruler-fill: '\efe3';
$remixicon-pencil-ruler-line: '\efe4';
$remixicon-percent-fill: '\efe5';
$remixicon-percent-line: '\efe6';
$remixicon-phone-camera-fill: '\efe7';
$remixicon-phone-camera-line: '\efe8';
$remixicon-phone-fill: '\efe9';
$remixicon-phone-find-fill: '\efea';
$remixicon-phone-find-line: '\efeb';
$remixicon-phone-line: '\efec';
$remixicon-phone-lock-fill: '\efed';
$remixicon-phone-lock-line: '\efee';
$remixicon-picture-in-picture-2-fill: '\efef';
$remixicon-picture-in-picture-2-line: '\eff0';
$remixicon-picture-in-picture-exit-fill: '\eff1';
$remixicon-picture-in-picture-exit-line: '\eff2';
$remixicon-picture-in-picture-fill: '\eff3';
$remixicon-picture-in-picture-line: '\eff4';
$remixicon-pie-chart-2-fill: '\eff5';
$remixicon-pie-chart-2-line: '\eff6';
$remixicon-pie-chart-box-fill: '\eff7';
$remixicon-pie-chart-box-line: '\eff8';
$remixicon-pie-chart-fill: '\eff9';
$remixicon-pie-chart-line: '\effa';
$remixicon-pin-distance-fill: '\effb';
$remixicon-pin-distance-line: '\effc';
$remixicon-ping-pong-fill: '\effd';
$remixicon-ping-pong-line: '\effe';
$remixicon-pinterest-fill: '\efff';
$remixicon-pinterest-line: '\f000';
$remixicon-pinyin-input: '\f001';
$remixicon-pixelfed-fill: '\f002';
$remixicon-pixelfed-line: '\f003';
$remixicon-plane-fill: '\f004';
$remixicon-plane-line: '\f005';
$remixicon-plant-fill: '\f006';
$remixicon-plant-line: '\f007';
$remixicon-play-circle-fill: '\f008';
$remixicon-play-circle-line: '\f009';
$remixicon-play-fill: '\f00a';
$remixicon-play-line: '\f00b';
$remixicon-play-list-2-fill: '\f00c';
$remixicon-play-list-2-line: '\f00d';
$remixicon-play-list-add-fill: '\f00e';
$remixicon-play-list-add-line: '\f00f';
$remixicon-play-list-fill: '\f010';
$remixicon-play-list-line: '\f011';
$remixicon-play-mini-fill: '\f012';
$remixicon-play-mini-line: '\f013';
$remixicon-playstation-fill: '\f014';
$remixicon-playstation-line: '\f015';
$remixicon-plug-2-fill: '\f016';
$remixicon-plug-2-line: '\f017';
$remixicon-plug-fill: '\f018';
$remixicon-plug-line: '\f019';
$remixicon-polaroid-2-fill: '\f01a';
$remixicon-polaroid-2-line: '\f01b';
$remixicon-polaroid-fill: '\f01c';
$remixicon-polaroid-line: '\f01d';
$remixicon-police-car-fill: '\f01e';
$remixicon-police-car-line: '\f01f';
$remixicon-price-tag-2-fill: '\f020';
$remixicon-price-tag-2-line: '\f021';
$remixicon-price-tag-3-fill: '\f022';
$remixicon-price-tag-3-line: '\f023';
$remixicon-price-tag-fill: '\f024';
$remixicon-price-tag-line: '\f025';
$remixicon-printer-cloud-fill: '\f026';
$remixicon-printer-cloud-line: '\f027';
$remixicon-printer-fill: '\f028';
$remixicon-printer-line: '\f029';
$remixicon-product-hunt-fill: '\f02a';
$remixicon-product-hunt-line: '\f02b';
$remixicon-profile-fill: '\f02c';
$remixicon-profile-line: '\f02d';
$remixicon-projector-2-fill: '\f02e';
$remixicon-projector-2-line: '\f02f';
$remixicon-projector-fill: '\f030';
$remixicon-projector-line: '\f031';
$remixicon-psychotherapy-fill: '\f032';
$remixicon-psychotherapy-line: '\f033';
$remixicon-pulse-fill: '\f034';
$remixicon-pulse-line: '\f035';
$remixicon-pushpin-2-fill: '\f036';
$remixicon-pushpin-2-line: '\f037';
$remixicon-pushpin-fill: '\f038';
$remixicon-pushpin-line: '\f039';
$remixicon-qq-fill: '\f03a';
$remixicon-qq-line: '\f03b';
$remixicon-qr-code-fill: '\f03c';
$remixicon-qr-code-line: '\f03d';
$remixicon-qr-scan-2-fill: '\f03e';
$remixicon-qr-scan-2-line: '\f03f';
$remixicon-qr-scan-fill: '\f040';
$remixicon-qr-scan-line: '\f041';
$remixicon-question-answer-fill: '\f042';
$remixicon-question-answer-line: '\f043';
$remixicon-question-fill: '\f044';
$remixicon-question-line: '\f045';
$remixicon-question-mark: '\f046';
$remixicon-questionnaire-fill: '\f047';
$remixicon-questionnaire-line: '\f048';
$remixicon-quill-pen-fill: '\f049';
$remixicon-quill-pen-line: '\f04a';
$remixicon-radar-fill: '\f04b';
$remixicon-radar-line: '\f04c';
$remixicon-radio-2-fill: '\f04d';
$remixicon-radio-2-line: '\f04e';
$remixicon-radio-button-fill: '\f04f';
$remixicon-radio-button-line: '\f050';
$remixicon-radio-fill: '\f051';
$remixicon-radio-line: '\f052';
$remixicon-rainbow-fill: '\f053';
$remixicon-rainbow-line: '\f054';
$remixicon-rainy-fill: '\f055';
$remixicon-rainy-line: '\f056';
$remixicon-reactjs-fill: '\f057';
$remixicon-reactjs-line: '\f058';
$remixicon-record-circle-fill: '\f059';
$remixicon-record-circle-line: '\f05a';
$remixicon-record-mail-fill: '\f05b';
$remixicon-record-mail-line: '\f05c';
$remixicon-recycle-fill: '\f05d';
$remixicon-recycle-line: '\f05e';
$remixicon-red-packet-fill: '\f05f';
$remixicon-red-packet-line: '\f060';
$remixicon-reddit-fill: '\f061';
$remixicon-reddit-line: '\f062';
$remixicon-refresh-fill: '\f063';
$remixicon-refresh-line: '\f064';
$remixicon-refund-2-fill: '\f065';
$remixicon-refund-2-line: '\f066';
$remixicon-refund-fill: '\f067';
$remixicon-refund-line: '\f068';
$remixicon-registered-fill: '\f069';
$remixicon-registered-line: '\f06a';
$remixicon-remixicon-fill: '\f06b';
$remixicon-remixicon-line: '\f06c';
$remixicon-remote-control-2-fill: '\f06d';
$remixicon-remote-control-2-line: '\f06e';
$remixicon-remote-control-fill: '\f06f';
$remixicon-remote-control-line: '\f070';
$remixicon-repeat-2-fill: '\f071';
$remixicon-repeat-2-line: '\f072';
$remixicon-repeat-fill: '\f073';
$remixicon-repeat-line: '\f074';
$remixicon-repeat-one-fill: '\f075';
$remixicon-repeat-one-line: '\f076';
$remixicon-reply-all-fill: '\f077';
$remixicon-reply-all-line: '\f078';
$remixicon-reply-fill: '\f079';
$remixicon-reply-line: '\f07a';
$remixicon-reserved-fill: '\f07b';
$remixicon-reserved-line: '\f07c';
$remixicon-rest-time-fill: '\f07d';
$remixicon-rest-time-line: '\f07e';
$remixicon-restart-fill: '\f07f';
$remixicon-restart-line: '\f080';
$remixicon-restaurant-2-fill: '\f081';
$remixicon-restaurant-2-line: '\f082';
$remixicon-restaurant-fill: '\f083';
$remixicon-restaurant-line: '\f084';
$remixicon-rewind-fill: '\f085';
$remixicon-rewind-line: '\f086';
$remixicon-rewind-mini-fill: '\f087';
$remixicon-rewind-mini-line: '\f088';
$remixicon-rhythm-fill: '\f089';
$remixicon-rhythm-line: '\f08a';
$remixicon-riding-fill: '\f08b';
$remixicon-riding-line: '\f08c';
$remixicon-road-map-fill: '\f08d';
$remixicon-road-map-line: '\f08e';
$remixicon-roadster-fill: '\f08f';
$remixicon-roadster-line: '\f090';
$remixicon-robot-fill: '\f091';
$remixicon-robot-line: '\f092';
$remixicon-rocket-2-fill: '\f093';
$remixicon-rocket-2-line: '\f094';
$remixicon-rocket-fill: '\f095';
$remixicon-rocket-line: '\f096';
$remixicon-rotate-lock-fill: '\f097';
$remixicon-rotate-lock-line: '\f098';
$remixicon-rounded-corner: '\f099';
$remixicon-route-fill: '\f09a';
$remixicon-route-line: '\f09b';
$remixicon-router-fill: '\f09c';
$remixicon-router-line: '\f09d';
$remixicon-rss-fill: '\f09e';
$remixicon-rss-line: '\f09f';
$remixicon-ruler-2-fill: '\f0a0';
$remixicon-ruler-2-line: '\f0a1';
$remixicon-ruler-fill: '\f0a2';
$remixicon-ruler-line: '\f0a3';
$remixicon-run-fill: '\f0a4';
$remixicon-run-line: '\f0a5';
$remixicon-safari-fill: '\f0a6';
$remixicon-safari-line: '\f0a7';
$remixicon-safe-2-fill: '\f0a8';
$remixicon-safe-2-line: '\f0a9';
$remixicon-safe-fill: '\f0aa';
$remixicon-safe-line: '\f0ab';
$remixicon-sailboat-fill: '\f0ac';
$remixicon-sailboat-line: '\f0ad';
$remixicon-save-2-fill: '\f0ae';
$remixicon-save-2-line: '\f0af';
$remixicon-save-3-fill: '\f0b0';
$remixicon-save-3-line: '\f0b1';
$remixicon-save-fill: '\f0b2';
$remixicon-save-line: '\f0b3';
$remixicon-scales-2-fill: '\f0b4';
$remixicon-scales-2-line: '\f0b5';
$remixicon-scales-3-fill: '\f0b6';
$remixicon-scales-3-line: '\f0b7';
$remixicon-scales-fill: '\f0b8';
$remixicon-scales-line: '\f0b9';
$remixicon-scan-2-fill: '\f0ba';
$remixicon-scan-2-line: '\f0bb';
$remixicon-scan-fill: '\f0bc';
$remixicon-scan-line: '\f0bd';
$remixicon-scissors-2-fill: '\f0be';
$remixicon-scissors-2-line: '\f0bf';
$remixicon-scissors-cut-fill: '\f0c0';
$remixicon-scissors-cut-line: '\f0c1';
$remixicon-scissors-fill: '\f0c2';
$remixicon-scissors-line: '\f0c3';
$remixicon-screenshot-2-fill: '\f0c4';
$remixicon-screenshot-2-line: '\f0c5';
$remixicon-screenshot-fill: '\f0c6';
$remixicon-screenshot-line: '\f0c7';
$remixicon-sd-card-fill: '\f0c8';
$remixicon-sd-card-line: '\f0c9';
$remixicon-sd-card-mini-fill: '\f0ca';
$remixicon-sd-card-mini-line: '\f0cb';
$remixicon-search-2-fill: '\f0cc';
$remixicon-search-2-line: '\f0cd';
$remixicon-search-eye-fill: '\f0ce';
$remixicon-search-eye-line: '\f0cf';
$remixicon-search-fill: '\f0d0';
$remixicon-search-line: '\f0d1';
$remixicon-secure-payment-fill: '\f0d2';
$remixicon-secure-payment-line: '\f0d3';
$remixicon-seedling-fill: '\f0d4';
$remixicon-seedling-line: '\f0d5';
$remixicon-send-backward: '\f0d6';
$remixicon-send-plane-2-fill: '\f0d7';
$remixicon-send-plane-2-line: '\f0d8';
$remixicon-send-plane-fill: '\f0d9';
$remixicon-send-plane-line: '\f0da';
$remixicon-send-to-back: '\f0db';
$remixicon-sensor-fill: '\f0dc';
$remixicon-sensor-line: '\f0dd';
$remixicon-separator: '\f0de';
$remixicon-server-fill: '\f0df';
$remixicon-server-line: '\f0e0';
$remixicon-service-fill: '\f0e1';
$remixicon-service-line: '\f0e2';
$remixicon-settings-2-fill: '\f0e3';
$remixicon-settings-2-line: '\f0e4';
$remixicon-settings-3-fill: '\f0e5';
$remixicon-settings-3-line: '\f0e6';
$remixicon-settings-4-fill: '\f0e7';
$remixicon-settings-4-line: '\f0e8';
$remixicon-settings-5-fill: '\f0e9';
$remixicon-settings-5-line: '\f0ea';
$remixicon-settings-6-fill: '\f0eb';
$remixicon-settings-6-line: '\f0ec';
$remixicon-settings-fill: '\f0ed';
$remixicon-settings-line: '\f0ee';
$remixicon-shape-2-fill: '\f0ef';
$remixicon-shape-2-line: '\f0f0';
$remixicon-shape-fill: '\f0f1';
$remixicon-shape-line: '\f0f2';
$remixicon-share-box-fill: '\f0f3';
$remixicon-share-box-line: '\f0f4';
$remixicon-share-circle-fill: '\f0f5';
$remixicon-share-circle-line: '\f0f6';
$remixicon-share-fill: '\f0f7';
$remixicon-share-forward-2-fill: '\f0f8';
$remixicon-share-forward-2-line: '\f0f9';
$remixicon-share-forward-box-fill: '\f0fa';
$remixicon-share-forward-box-line: '\f0fb';
$remixicon-share-forward-fill: '\f0fc';
$remixicon-share-forward-line: '\f0fd';
$remixicon-share-line: '\f0fe';
$remixicon-shield-check-fill: '\f0ff';
$remixicon-shield-check-line: '\f100';
$remixicon-shield-cross-fill: '\f101';
$remixicon-shield-cross-line: '\f102';
$remixicon-shield-fill: '\f103';
$remixicon-shield-flash-fill: '\f104';
$remixicon-shield-flash-line: '\f105';
$remixicon-shield-keyhole-fill: '\f106';
$remixicon-shield-keyhole-line: '\f107';
$remixicon-shield-line: '\f108';
$remixicon-shield-star-fill: '\f109';
$remixicon-shield-star-line: '\f10a';
$remixicon-shield-user-fill: '\f10b';
$remixicon-shield-user-line: '\f10c';
$remixicon-ship-2-fill: '\f10d';
$remixicon-ship-2-line: '\f10e';
$remixicon-ship-fill: '\f10f';
$remixicon-ship-line: '\f110';
$remixicon-shirt-fill: '\f111';
$remixicon-shirt-line: '\f112';
$remixicon-shopping-bag-2-fill: '\f113';
$remixicon-shopping-bag-2-line: '\f114';
$remixicon-shopping-bag-3-fill: '\f115';
$remixicon-shopping-bag-3-line: '\f116';
$remixicon-shopping-bag-fill: '\f117';
$remixicon-shopping-bag-line: '\f118';
$remixicon-shopping-basket-2-fill: '\f119';
$remixicon-shopping-basket-2-line: '\f11a';
$remixicon-shopping-basket-fill: '\f11b';
$remixicon-shopping-basket-line: '\f11c';
$remixicon-shopping-cart-2-fill: '\f11d';
$remixicon-shopping-cart-2-line: '\f11e';
$remixicon-shopping-cart-fill: '\f11f';
$remixicon-shopping-cart-line: '\f120';
$remixicon-showers-fill: '\f121';
$remixicon-showers-line: '\f122';
$remixicon-shuffle-fill: '\f123';
$remixicon-shuffle-line: '\f124';
$remixicon-shut-down-fill: '\f125';
$remixicon-shut-down-line: '\f126';
$remixicon-side-bar-fill: '\f127';
$remixicon-side-bar-line: '\f128';
$remixicon-signal-tower-fill: '\f129';
$remixicon-signal-tower-line: '\f12a';
$remixicon-signal-wifi-1-fill: '\f12b';
$remixicon-signal-wifi-1-line: '\f12c';
$remixicon-signal-wifi-2-fill: '\f12d';
$remixicon-signal-wifi-2-line: '\f12e';
$remixicon-signal-wifi-3-fill: '\f12f';
$remixicon-signal-wifi-3-line: '\f130';
$remixicon-signal-wifi-error-fill: '\f131';
$remixicon-signal-wifi-error-line: '\f132';
$remixicon-signal-wifi-fill: '\f133';
$remixicon-signal-wifi-line: '\f134';
$remixicon-signal-wifi-off-fill: '\f135';
$remixicon-signal-wifi-off-line: '\f136';
$remixicon-sim-card-2-fill: '\f137';
$remixicon-sim-card-2-line: '\f138';
$remixicon-sim-card-fill: '\f139';
$remixicon-sim-card-line: '\f13a';
$remixicon-single-quotes-l: '\f13b';
$remixicon-single-quotes-r: '\f13c';
$remixicon-sip-fill: '\f13d';
$remixicon-sip-line: '\f13e';
$remixicon-skip-back-fill: '\f13f';
$remixicon-skip-back-line: '\f140';
$remixicon-skip-back-mini-fill: '\f141';
$remixicon-skip-back-mini-line: '\f142';
$remixicon-skip-forward-fill: '\f143';
$remixicon-skip-forward-line: '\f144';
$remixicon-skip-forward-mini-fill: '\f145';
$remixicon-skip-forward-mini-line: '\f146';
$remixicon-skull-2-fill: '\f147';
$remixicon-skull-2-line: '\f148';
$remixicon-skull-fill: '\f149';
$remixicon-skull-line: '\f14a';
$remixicon-skype-fill: '\f14b';
$remixicon-skype-line: '\f14c';
$remixicon-slack-fill: '\f14d';
$remixicon-slack-line: '\f14e';
$remixicon-slice-fill: '\f14f';
$remixicon-slice-line: '\f150';
$remixicon-slideshow-2-fill: '\f151';
$remixicon-slideshow-2-line: '\f152';
$remixicon-slideshow-3-fill: '\f153';
$remixicon-slideshow-3-line: '\f154';
$remixicon-slideshow-4-fill: '\f155';
$remixicon-slideshow-4-line: '\f156';
$remixicon-slideshow-fill: '\f157';
$remixicon-slideshow-line: '\f158';
$remixicon-smartphone-fill: '\f159';
$remixicon-smartphone-line: '\f15a';
$remixicon-snapchat-fill: '\f15b';
$remixicon-snapchat-line: '\f15c';
$remixicon-snowy-fill: '\f15d';
$remixicon-snowy-line: '\f15e';
$remixicon-sort-asc: '\f15f';
$remixicon-sort-desc: '\f160';
$remixicon-sound-module-fill: '\f161';
$remixicon-sound-module-line: '\f162';
$remixicon-soundcloud-fill: '\f163';
$remixicon-soundcloud-line: '\f164';
$remixicon-space-ship-fill: '\f165';
$remixicon-space-ship-line: '\f166';
$remixicon-space: '\f167';
$remixicon-spam-2-fill: '\f168';
$remixicon-spam-2-line: '\f169';
$remixicon-spam-3-fill: '\f16a';
$remixicon-spam-3-line: '\f16b';
$remixicon-spam-fill: '\f16c';
$remixicon-spam-line: '\f16d';
$remixicon-speaker-2-fill: '\f16e';
$remixicon-speaker-2-line: '\f16f';
$remixicon-speaker-3-fill: '\f170';
$remixicon-speaker-3-line: '\f171';
$remixicon-speaker-fill: '\f172';
$remixicon-speaker-line: '\f173';
$remixicon-spectrum-fill: '\f174';
$remixicon-spectrum-line: '\f175';
$remixicon-speed-fill: '\f176';
$remixicon-speed-line: '\f177';
$remixicon-speed-mini-fill: '\f178';
$remixicon-speed-mini-line: '\f179';
$remixicon-split-cells-horizontal: '\f17a';
$remixicon-split-cells-vertical: '\f17b';
$remixicon-spotify-fill: '\f17c';
$remixicon-spotify-line: '\f17d';
$remixicon-spy-fill: '\f17e';
$remixicon-spy-line: '\f17f';
$remixicon-stack-fill: '\f180';
$remixicon-stack-line: '\f181';
$remixicon-stack-overflow-fill: '\f182';
$remixicon-stack-overflow-line: '\f183';
$remixicon-stackshare-fill: '\f184';
$remixicon-stackshare-line: '\f185';
$remixicon-star-fill: '\f186';
$remixicon-star-half-fill: '\f187';
$remixicon-star-half-line: '\f188';
$remixicon-star-half-s-fill: '\f189';
$remixicon-star-half-s-line: '\f18a';
$remixicon-star-line: '\f18b';
$remixicon-star-s-fill: '\f18c';
$remixicon-star-s-line: '\f18d';
$remixicon-star-smile-fill: '\f18e';
$remixicon-star-smile-line: '\f18f';
$remixicon-steam-fill: '\f190';
$remixicon-steam-line: '\f191';
$remixicon-steering-2-fill: '\f192';
$remixicon-steering-2-line: '\f193';
$remixicon-steering-fill: '\f194';
$remixicon-steering-line: '\f195';
$remixicon-stethoscope-fill: '\f196';
$remixicon-stethoscope-line: '\f197';
$remixicon-sticky-note-2-fill: '\f198';
$remixicon-sticky-note-2-line: '\f199';
$remixicon-sticky-note-fill: '\f19a';
$remixicon-sticky-note-line: '\f19b';
$remixicon-stock-fill: '\f19c';
$remixicon-stock-line: '\f19d';
$remixicon-stop-circle-fill: '\f19e';
$remixicon-stop-circle-line: '\f19f';
$remixicon-stop-fill: '\f1a0';
$remixicon-stop-line: '\f1a1';
$remixicon-stop-mini-fill: '\f1a2';
$remixicon-stop-mini-line: '\f1a3';
$remixicon-store-2-fill: '\f1a4';
$remixicon-store-2-line: '\f1a5';
$remixicon-store-3-fill: '\f1a6';
$remixicon-store-3-line: '\f1a7';
$remixicon-store-fill: '\f1a8';
$remixicon-store-line: '\f1a9';
$remixicon-strikethrough-2: '\f1aa';
$remixicon-strikethrough: '\f1ab';
$remixicon-subscript-2: '\f1ac';
$remixicon-subscript: '\f1ad';
$remixicon-subtract-fill: '\f1ae';
$remixicon-subtract-line: '\f1af';
$remixicon-subway-fill: '\f1b0';
$remixicon-subway-line: '\f1b1';
$remixicon-subway-wifi-fill: '\f1b2';
$remixicon-subway-wifi-line: '\f1b3';
$remixicon-suitcase-2-fill: '\f1b4';
$remixicon-suitcase-2-line: '\f1b5';
$remixicon-suitcase-3-fill: '\f1b6';
$remixicon-suitcase-3-line: '\f1b7';
$remixicon-suitcase-fill: '\f1b8';
$remixicon-suitcase-line: '\f1b9';
$remixicon-sun-cloudy-fill: '\f1ba';
$remixicon-sun-cloudy-line: '\f1bb';
$remixicon-sun-fill: '\f1bc';
$remixicon-sun-foggy-fill: '\f1bd';
$remixicon-sun-foggy-line: '\f1be';
$remixicon-sun-line: '\f1bf';
$remixicon-superscript-2: '\f1c0';
$remixicon-superscript: '\f1c1';
$remixicon-surgical-mask-fill: '\f1c2';
$remixicon-surgical-mask-line: '\f1c3';
$remixicon-surround-sound-fill: '\f1c4';
$remixicon-surround-sound-line: '\f1c5';
$remixicon-survey-fill: '\f1c6';
$remixicon-survey-line: '\f1c7';
$remixicon-swap-box-fill: '\f1c8';
$remixicon-swap-box-line: '\f1c9';
$remixicon-swap-fill: '\f1ca';
$remixicon-swap-line: '\f1cb';
$remixicon-switch-fill: '\f1cc';
$remixicon-switch-line: '\f1cd';
$remixicon-sword-fill: '\f1ce';
$remixicon-sword-line: '\f1cf';
$remixicon-syringe-fill: '\f1d0';
$remixicon-syringe-line: '\f1d1';
$remixicon-t-box-fill: '\f1d2';
$remixicon-t-box-line: '\f1d3';
$remixicon-t-shirt-2-fill: '\f1d4';
$remixicon-t-shirt-2-line: '\f1d5';
$remixicon-t-shirt-air-fill: '\f1d6';
$remixicon-t-shirt-air-line: '\f1d7';
$remixicon-t-shirt-fill: '\f1d8';
$remixicon-t-shirt-line: '\f1d9';
$remixicon-table-2: '\f1da';
$remixicon-table-alt-fill: '\f1db';
$remixicon-table-alt-line: '\f1dc';
$remixicon-table-fill: '\f1dd';
$remixicon-table-line: '\f1de';
$remixicon-tablet-fill: '\f1df';
$remixicon-tablet-line: '\f1e0';
$remixicon-takeaway-fill: '\f1e1';
$remixicon-takeaway-line: '\f1e2';
$remixicon-taobao-fill: '\f1e3';
$remixicon-taobao-line: '\f1e4';
$remixicon-tape-fill: '\f1e5';
$remixicon-tape-line: '\f1e6';
$remixicon-task-fill: '\f1e7';
$remixicon-task-line: '\f1e8';
$remixicon-taxi-fill: '\f1e9';
$remixicon-taxi-line: '\f1ea';
$remixicon-taxi-wifi-fill: '\f1eb';
$remixicon-taxi-wifi-line: '\f1ec';
$remixicon-team-fill: '\f1ed';
$remixicon-team-line: '\f1ee';
$remixicon-telegram-fill: '\f1ef';
$remixicon-telegram-line: '\f1f0';
$remixicon-temp-cold-fill: '\f1f1';
$remixicon-temp-cold-line: '\f1f2';
$remixicon-temp-hot-fill: '\f1f3';
$remixicon-temp-hot-line: '\f1f4';
$remixicon-terminal-box-fill: '\f1f5';
$remixicon-terminal-box-line: '\f1f6';
$remixicon-terminal-fill: '\f1f7';
$remixicon-terminal-line: '\f1f8';
$remixicon-terminal-window-fill: '\f1f9';
$remixicon-terminal-window-line: '\f1fa';
$remixicon-test-tube-fill: '\f1fb';
$remixicon-test-tube-line: '\f1fc';
$remixicon-text-direction-l: '\f1fd';
$remixicon-text-direction-r: '\f1fe';
$remixicon-text-spacing: '\f1ff';
$remixicon-text-wrap: '\f200';
$remixicon-text: '\f201';
$remixicon-thermometer-fill: '\f202';
$remixicon-thermometer-line: '\f203';
$remixicon-thumb-down-fill: '\f204';
$remixicon-thumb-down-line: '\f205';
$remixicon-thumb-up-fill: '\f206';
$remixicon-thumb-up-line: '\f207';
$remixicon-thunderstorms-fill: '\f208';
$remixicon-thunderstorms-line: '\f209';
$remixicon-ticket-2-fill: '\f20a';
$remixicon-ticket-2-line: '\f20b';
$remixicon-ticket-fill: '\f20c';
$remixicon-ticket-line: '\f20d';
$remixicon-time-fill: '\f20e';
$remixicon-time-line: '\f20f';
$remixicon-timer-2-fill: '\f210';
$remixicon-timer-2-line: '\f211';
$remixicon-timer-fill: '\f212';
$remixicon-timer-flash-fill: '\f213';
$remixicon-timer-flash-line: '\f214';
$remixicon-timer-line: '\f215';
$remixicon-todo-fill: '\f216';
$remixicon-todo-line: '\f217';
$remixicon-toggle-fill: '\f218';
$remixicon-toggle-line: '\f219';
$remixicon-tools-fill: '\f21a';
$remixicon-tools-line: '\f21b';
$remixicon-tornado-fill: '\f21c';
$remixicon-tornado-line: '\f21d';
$remixicon-trademark-fill: '\f21e';
$remixicon-trademark-line: '\f21f';
$remixicon-traffic-light-fill: '\f220';
$remixicon-traffic-light-line: '\f221';
$remixicon-train-fill: '\f222';
$remixicon-train-line: '\f223';
$remixicon-train-wifi-fill: '\f224';
$remixicon-train-wifi-line: '\f225';
$remixicon-translate-2: '\f226';
$remixicon-translate: '\f227';
$remixicon-travesti-fill: '\f228';
$remixicon-travesti-line: '\f229';
$remixicon-treasure-map-fill: '\f22a';
$remixicon-treasure-map-line: '\f22b';
$remixicon-trello-fill: '\f22c';
$remixicon-trello-line: '\f22d';
$remixicon-trophy-fill: '\f22e';
$remixicon-trophy-line: '\f22f';
$remixicon-truck-fill: '\f230';
$remixicon-truck-line: '\f231';
$remixicon-tumblr-fill: '\f232';
$remixicon-tumblr-line: '\f233';
$remixicon-tv-2-fill: '\f234';
$remixicon-tv-2-line: '\f235';
$remixicon-tv-fill: '\f236';
$remixicon-tv-line: '\f237';
$remixicon-twitch-fill: '\f238';
$remixicon-twitch-line: '\f239';
$remixicon-twitter-fill: '\f23a';
$remixicon-twitter-line: '\f23b';
$remixicon-typhoon-fill: '\f23c';
$remixicon-typhoon-line: '\f23d';
$remixicon-u-disk-fill: '\f23e';
$remixicon-u-disk-line: '\f23f';
$remixicon-ubuntu-fill: '\f240';
$remixicon-ubuntu-line: '\f241';
$remixicon-umbrella-fill: '\f242';
$remixicon-umbrella-line: '\f243';
$remixicon-underline: '\f244';
$remixicon-uninstall-fill: '\f245';
$remixicon-uninstall-line: '\f246';
$remixicon-unsplash-fill: '\f247';
$remixicon-unsplash-line: '\f248';
$remixicon-upload-2-fill: '\f249';
$remixicon-upload-2-line: '\f24a';
$remixicon-upload-cloud-2-fill: '\f24b';
$remixicon-upload-cloud-2-line: '\f24c';
$remixicon-upload-cloud-fill: '\f24d';
$remixicon-upload-cloud-line: '\f24e';
$remixicon-upload-fill: '\f24f';
$remixicon-upload-line: '\f250';
$remixicon-usb-fill: '\f251';
$remixicon-usb-line: '\f252';
$remixicon-user-2-fill: '\f253';
$remixicon-user-2-line: '\f254';
$remixicon-user-3-fill: '\f255';
$remixicon-user-3-line: '\f256';
$remixicon-user-4-fill: '\f257';
$remixicon-user-4-line: '\f258';
$remixicon-user-5-fill: '\f259';
$remixicon-user-5-line: '\f25a';
$remixicon-user-6-fill: '\f25b';
$remixicon-user-6-line: '\f25c';
$remixicon-user-add-fill: '\f25d';
$remixicon-user-add-line: '\f25e';
$remixicon-user-fill: '\f25f';
$remixicon-user-follow-fill: '\f260';
$remixicon-user-follow-line: '\f261';
$remixicon-user-heart-fill: '\f262';
$remixicon-user-heart-line: '\f263';
$remixicon-user-line: '\f264';
$remixicon-user-location-fill: '\f265';
$remixicon-user-location-line: '\f266';
$remixicon-user-received-2-fill: '\f267';
$remixicon-user-received-2-line: '\f268';
$remixicon-user-received-fill: '\f269';
$remixicon-user-received-line: '\f26a';
$remixicon-user-search-fill: '\f26b';
$remixicon-user-search-line: '\f26c';
$remixicon-user-settings-fill: '\f26d';
$remixicon-user-settings-line: '\f26e';
$remixicon-user-shared-2-fill: '\f26f';
$remixicon-user-shared-2-line: '\f270';
$remixicon-user-shared-fill: '\f271';
$remixicon-user-shared-line: '\f272';
$remixicon-user-smile-fill: '\f273';
$remixicon-user-smile-line: '\f274';
$remixicon-user-star-fill: '\f275';
$remixicon-user-star-line: '\f276';
$remixicon-user-unfollow-fill: '\f277';
$remixicon-user-unfollow-line: '\f278';
$remixicon-user-voice-fill: '\f279';
$remixicon-user-voice-line: '\f27a';
$remixicon-video-add-fill: '\f27b';
$remixicon-video-add-line: '\f27c';
$remixicon-video-chat-fill: '\f27d';
$remixicon-video-chat-line: '\f27e';
$remixicon-video-download-fill: '\f27f';
$remixicon-video-download-line: '\f280';
$remixicon-video-fill: '\f281';
$remixicon-video-line: '\f282';
$remixicon-video-upload-fill: '\f283';
$remixicon-video-upload-line: '\f284';
$remixicon-vidicon-2-fill: '\f285';
$remixicon-vidicon-2-line: '\f286';
$remixicon-vidicon-fill: '\f287';
$remixicon-vidicon-line: '\f288';
$remixicon-vimeo-fill: '\f289';
$remixicon-vimeo-line: '\f28a';
$remixicon-vip-crown-2-fill: '\f28b';
$remixicon-vip-crown-2-line: '\f28c';
$remixicon-vip-crown-fill: '\f28d';
$remixicon-vip-crown-line: '\f28e';
$remixicon-vip-diamond-fill: '\f28f';
$remixicon-vip-diamond-line: '\f290';
$remixicon-vip-fill: '\f291';
$remixicon-vip-line: '\f292';
$remixicon-virus-fill: '\f293';
$remixicon-virus-line: '\f294';
$remixicon-visa-fill: '\f295';
$remixicon-visa-line: '\f296';
$remixicon-voice-recognition-fill: '\f297';
$remixicon-voice-recognition-line: '\f298';
$remixicon-voiceprint-fill: '\f299';
$remixicon-voiceprint-line: '\f29a';
$remixicon-volume-down-fill: '\f29b';
$remixicon-volume-down-line: '\f29c';
$remixicon-volume-mute-fill: '\f29d';
$remixicon-volume-mute-line: '\f29e';
$remixicon-volume-off-vibrate-fill: '\f29f';
$remixicon-volume-off-vibrate-line: '\f2a0';
$remixicon-volume-up-fill: '\f2a1';
$remixicon-volume-up-line: '\f2a2';
$remixicon-volume-vibrate-fill: '\f2a3';
$remixicon-volume-vibrate-line: '\f2a4';
$remixicon-vuejs-fill: '\f2a5';
$remixicon-vuejs-line: '\f2a6';
$remixicon-walk-fill: '\f2a7';
$remixicon-walk-line: '\f2a8';
$remixicon-wallet-2-fill: '\f2a9';
$remixicon-wallet-2-line: '\f2aa';
$remixicon-wallet-3-fill: '\f2ab';
$remixicon-wallet-3-line: '\f2ac';
$remixicon-wallet-fill: '\f2ad';
$remixicon-wallet-line: '\f2ae';
$remixicon-water-flash-fill: '\f2af';
$remixicon-water-flash-line: '\f2b0';
$remixicon-webcam-fill: '\f2b1';
$remixicon-webcam-line: '\f2b2';
$remixicon-wechat-2-fill: '\f2b3';
$remixicon-wechat-2-line: '\f2b4';
$remixicon-wechat-fill: '\f2b5';
$remixicon-wechat-line: '\f2b6';
$remixicon-wechat-pay-fill: '\f2b7';
$remixicon-wechat-pay-line: '\f2b8';
$remixicon-weibo-fill: '\f2b9';
$remixicon-weibo-line: '\f2ba';
$remixicon-whatsapp-fill: '\f2bb';
$remixicon-whatsapp-line: '\f2bc';
$remixicon-wheelchair-fill: '\f2bd';
$remixicon-wheelchair-line: '\f2be';
$remixicon-wifi-fill: '\f2bf';
$remixicon-wifi-line: '\f2c0';
$remixicon-wifi-off-fill: '\f2c1';
$remixicon-wifi-off-line: '\f2c2';
$remixicon-window-2-fill: '\f2c3';
$remixicon-window-2-line: '\f2c4';
$remixicon-window-fill: '\f2c5';
$remixicon-window-line: '\f2c6';
$remixicon-windows-fill: '\f2c7';
$remixicon-windows-line: '\f2c8';
$remixicon-windy-fill: '\f2c9';
$remixicon-windy-line: '\f2ca';
$remixicon-wireless-charging-fill: '\f2cb';
$remixicon-wireless-charging-line: '\f2cc';
$remixicon-women-fill: '\f2cd';
$remixicon-women-line: '\f2ce';
$remixicon-wubi-input: '\f2cf';
$remixicon-xbox-fill: '\f2d0';
$remixicon-xbox-line: '\f2d1';
$remixicon-xing-fill: '\f2d2';
$remixicon-xing-line: '\f2d3';
$remixicon-youtube-fill: '\f2d4';
$remixicon-youtube-line: '\f2d5';
$remixicon-zcool-fill: '\f2d6';
$remixicon-zcool-line: '\f2d7';
$remixicon-zhihu-fill: '\f2d8';
$remixicon-zhihu-line: '\f2d9';
$remixicon-zoom-in-fill: '\f2da';
$remixicon-zoom-in-line: '\f2db';
$remixicon-zoom-out-fill: '\f2dc';
$remixicon-zoom-out-line: '\f2dd';
$remixicon-zzz-fill: '\f2de';
$remixicon-zzz-line: '\f2df';
$remixicon-arrow-down-double-fill: '\f2e0';
$remixicon-arrow-down-double-line: '\f2e1';
$remixicon-arrow-left-double-fill: '\f2e2';
$remixicon-arrow-left-double-line: '\f2e3';
$remixicon-arrow-right-double-fill: '\f2e4';
$remixicon-arrow-right-double-line: '\f2e5';
$remixicon-arrow-turn-back-fill: '\f2e6';
$remixicon-arrow-turn-back-line: '\f2e7';
$remixicon-arrow-turn-forward-fill: '\f2e8';
$remixicon-arrow-turn-forward-line: '\f2e9';
$remixicon-arrow-up-double-fill: '\f2ea';
$remixicon-arrow-up-double-line: '\f2eb';
$remixicon-bard-fill: '\f2ec';
$remixicon-bard-line: '\f2ed';
$remixicon-bootstrap-fill: '\f2ee';
$remixicon-bootstrap-line: '\f2ef';
$remixicon-box-1-fill: '\f2f0';
$remixicon-box-1-line: '\f2f1';
$remixicon-box-2-fill: '\f2f2';
$remixicon-box-2-line: '\f2f3';
$remixicon-box-3-fill: '\f2f4';
$remixicon-box-3-line: '\f2f5';
$remixicon-brain-fill: '\f2f6';
$remixicon-brain-line: '\f2f7';
$remixicon-candle-fill: '\f2f8';
$remixicon-candle-line: '\f2f9';
$remixicon-cash-fill: '\f2fa';
$remixicon-cash-line: '\f2fb';
$remixicon-contract-left-fill: '\f2fc';
$remixicon-contract-left-line: '\f2fd';
$remixicon-contract-left-right-fill: '\f2fe';
$remixicon-contract-left-right-line: '\f2ff';
$remixicon-contract-right-fill: '\f300';
$remixicon-contract-right-line: '\f301';
$remixicon-contract-up-down-fill: '\f302';
$remixicon-contract-up-down-line: '\f303';
$remixicon-copilot-fill: '\f304';
$remixicon-copilot-line: '\f305';
$remixicon-corner-down-left-fill: '\f306';
$remixicon-corner-down-left-line: '\f307';
$remixicon-corner-down-right-fill: '\f308';
$remixicon-corner-down-right-line: '\f309';
$remixicon-corner-left-down-fill: '\f30a';
$remixicon-corner-left-down-line: '\f30b';
$remixicon-corner-left-up-fill: '\f30c';
$remixicon-corner-left-up-line: '\f30d';
$remixicon-corner-right-down-fill: '\f30e';
$remixicon-corner-right-down-line: '\f30f';
$remixicon-corner-right-up-fill: '\f310';
$remixicon-corner-right-up-line: '\f311';
$remixicon-corner-up-left-double-fill: '\f312';
$remixicon-corner-up-left-double-line: '\f313';
$remixicon-corner-up-left-fill: '\f314';
$remixicon-corner-up-left-line: '\f315';
$remixicon-corner-up-right-double-fill: '\f316';
$remixicon-corner-up-right-double-line: '\f317';
$remixicon-corner-up-right-fill: '\f318';
$remixicon-corner-up-right-line: '\f319';
$remixicon-cross-fill: '\f31a';
$remixicon-cross-line: '\f31b';
$remixicon-edge-new-fill: '\f31c';
$remixicon-edge-new-line: '\f31d';
$remixicon-equal-fill: '\f31e';
$remixicon-equal-line: '\f31f';
$remixicon-expand-left-fill: '\f320';
$remixicon-expand-left-line: '\f321';
$remixicon-expand-left-right-fill: '\f322';
$remixicon-expand-left-right-line: '\f323';
$remixicon-expand-right-fill: '\f324';
$remixicon-expand-right-line: '\f325';
$remixicon-expand-up-down-fill: '\f326';
$remixicon-expand-up-down-line: '\f327';
$remixicon-flickr-fill: '\f328';
$remixicon-flickr-line: '\f329';
$remixicon-forward-10-fill: '\f32a';
$remixicon-forward-10-line: '\f32b';
$remixicon-forward-15-fill: '\f32c';
$remixicon-forward-15-line: '\f32d';
$remixicon-forward-30-fill: '\f32e';
$remixicon-forward-30-line: '\f32f';
$remixicon-forward-5-fill: '\f330';
$remixicon-forward-5-line: '\f331';
$remixicon-graduation-cap-fill: '\f332';
$remixicon-graduation-cap-line: '\f333';
$remixicon-home-office-fill: '\f334';
$remixicon-home-office-line: '\f335';
$remixicon-hourglass-2-fill: '\f336';
$remixicon-hourglass-2-line: '\f337';
$remixicon-hourglass-fill: '\f338';
$remixicon-hourglass-line: '\f339';
$remixicon-javascript-fill: '\f33a';
$remixicon-javascript-line: '\f33b';
$remixicon-loop-left-fill: '\f33c';
$remixicon-loop-left-line: '\f33d';
$remixicon-loop-right-fill: '\f33e';
$remixicon-loop-right-line: '\f33f';
$remixicon-memories-fill: '\f340';
$remixicon-memories-line: '\f341';
$remixicon-meta-fill: '\f342';
$remixicon-meta-line: '\f343';
$remixicon-microsoft-loop-fill: '\f344';
$remixicon-microsoft-loop-line: '\f345';
$remixicon-nft-fill: '\f346';
$remixicon-nft-line: '\f347';
$remixicon-notion-fill: '\f348';
$remixicon-notion-line: '\f349';
$remixicon-openai-fill: '\f34a';
$remixicon-openai-line: '\f34b';
$remixicon-overline: '\f34c';
$remixicon-p2p-fill: '\f34d';
$remixicon-p2p-line: '\f34e';
$remixicon-presentation-fill: '\f34f';
$remixicon-presentation-line: '\f350';
$remixicon-replay-10-fill: '\f351';
$remixicon-replay-10-line: '\f352';
$remixicon-replay-15-fill: '\f353';
$remixicon-replay-15-line: '\f354';
$remixicon-replay-30-fill: '\f355';
$remixicon-replay-30-line: '\f356';
$remixicon-replay-5-fill: '\f357';
$remixicon-replay-5-line: '\f358';
$remixicon-school-fill: '\f359';
$remixicon-school-line: '\f35a';
$remixicon-shining-2-fill: '\f35b';
$remixicon-shining-2-line: '\f35c';
$remixicon-shining-fill: '\f35d';
$remixicon-shining-line: '\f35e';
$remixicon-sketching: '\f35f';
$remixicon-skip-down-fill: '\f360';
$remixicon-skip-down-line: '\f361';
$remixicon-skip-left-fill: '\f362';
$remixicon-skip-left-line: '\f363';
$remixicon-skip-right-fill: '\f364';
$remixicon-skip-right-line: '\f365';
$remixicon-skip-up-fill: '\f366';
$remixicon-skip-up-line: '\f367';
$remixicon-slow-down-fill: '\f368';
$remixicon-slow-down-line: '\f369';
$remixicon-sparkling-2-fill: '\f36a';
$remixicon-sparkling-2-line: '\f36b';
$remixicon-sparkling-fill: '\f36c';
$remixicon-sparkling-line: '\f36d';
$remixicon-speak-fill: '\f36e';
$remixicon-speak-line: '\f36f';
$remixicon-speed-up-fill: '\f370';
$remixicon-speed-up-line: '\f371';
$remixicon-tiktok-fill: '\f372';
$remixicon-tiktok-line: '\f373';
$remixicon-token-swap-fill: '\f374';
$remixicon-token-swap-line: '\f375';
$remixicon-unpin-fill: '\f376';
$remixicon-unpin-line: '\f377';
$remixicon-wechat-channels-fill: '\f378';
$remixicon-wechat-channels-line: '\f379';
$remixicon-wordpress-fill: '\f37a';
$remixicon-wordpress-line: '\f37b';
$remixicon-blender-fill: '\f37c';
$remixicon-blender-line: '\f37d';
$remixicon-emoji-sticker-fill: '\f37e';
$remixicon-emoji-sticker-line: '\f37f';
$remixicon-git-close-pull-request-fill: '\f380';
$remixicon-git-close-pull-request-line: '\f381';
$remixicon-instance-fill: '\f382';
$remixicon-instance-line: '\f383';
$remixicon-megaphone-fill: '\f384';
$remixicon-megaphone-line: '\f385';
$remixicon-pass-expired-fill: '\f386';
$remixicon-pass-expired-line: '\f387';
$remixicon-pass-pending-fill: '\f388';
$remixicon-pass-pending-line: '\f389';
$remixicon-pass-valid-fill: '\f38a';
$remixicon-pass-valid-line: '\f38b';
$remixicon-ai-generate: '\f38c';
$remixicon-calendar-close-fill: '\f38d';
$remixicon-calendar-close-line: '\f38e';
$remixicon-draggable: '\f38f';
$remixicon-font-family: '\f390';
$remixicon-font-mono: '\f391';
$remixicon-font-sans-serif: '\f392';
$remixicon-font-sans: '\f393';
$remixicon-hard-drive-3-fill: '\f394';
$remixicon-hard-drive-3-line: '\f395';
$remixicon-kick-fill: '\f396';
$remixicon-kick-line: '\f397';
$remixicon-list-check-3: '\f398';
$remixicon-list-indefinite: '\f399';
$remixicon-list-ordered-2: '\f39a';
$remixicon-list-radio: '\f39b';
$remixicon-openbase-fill: '\f39c';
$remixicon-openbase-line: '\f39d';
$remixicon-planet-fill: '\f39e';
$remixicon-planet-line: '\f39f';
$remixicon-prohibited-fill: '\f3a0';
$remixicon-prohibited-line: '\f3a1';
$remixicon-quote-text: '\f3a2';
$remixicon-seo-fill: '\f3a3';
$remixicon-seo-line: '\f3a4';
$remixicon-slash-commands: '\f3a5';
$remixicon-archive-2-fill: '\f3a6';
$remixicon-archive-2-line: '\f3a7';
$remixicon-inbox-2-fill: '\f3a8';
$remixicon-inbox-2-line: '\f3a9';
$remixicon-shake-hands-fill: '\f3aa';
$remixicon-shake-hands-line: '\f3ab';
$remixicon-supabase-fill: '\f3ac';
$remixicon-supabase-line: '\f3ad';
$remixicon-water-percent-fill: '\f3ae';
$remixicon-water-percent-line: '\f3af';
$remixicon-yuque-fill: '\f3b0';
$remixicon-yuque-line: '\f3b1';
$remixicon-crosshair-2-fill: '\f3b2';
$remixicon-crosshair-2-line: '\f3b3';
$remixicon-crosshair-fill: '\f3b4';
$remixicon-crosshair-line: '\f3b5';
$remixicon-file-close-fill: '\f3b6';
$remixicon-file-close-line: '\f3b7';
$remixicon-infinity-fill: '\f3b8';
$remixicon-infinity-line: '\f3b9';
$remixicon-rfid-fill: '\f3ba';
$remixicon-rfid-line: '\f3bb';
$remixicon-slash-commands-2: '\f3bc';
$remixicon-user-forbid-fill: '\f3bd';
$remixicon-user-forbid-line: '\f3be';
$remixicon-beer-fill: '\f3bf';
$remixicon-beer-line: '\f3c0';
$remixicon-circle-fill: '\f3c1';
$remixicon-circle-line: '\f3c2';
$remixicon-dropdown-list: '\f3c3';
$remixicon-file-image-fill: '\f3c4';
$remixicon-file-image-line: '\f3c5';
$remixicon-file-pdf-2-fill: '\f3c6';
$remixicon-file-pdf-2-line: '\f3c7';
$remixicon-file-video-fill: '\f3c8';
$remixicon-file-video-line: '\f3c9';
$remixicon-folder-image-fill: '\f3ca';
$remixicon-folder-image-line: '\f3cb';
$remixicon-folder-video-fill: '\f3cc';
$remixicon-folder-video-line: '\f3cd';
$remixicon-hexagon-fill: '\f3ce';
$remixicon-hexagon-line: '\f3cf';
$remixicon-menu-search-fill: '\f3d0';
$remixicon-menu-search-line: '\f3d1';
$remixicon-octagon-fill: '\f3d2';
$remixicon-octagon-line: '\f3d3';
$remixicon-pentagon-fill: '\f3d4';
$remixicon-pentagon-line: '\f3d5';
$remixicon-rectangle-fill: '\f3d6';
$remixicon-rectangle-line: '\f3d7';
$remixicon-robot-2-fill: '\f3d8';
$remixicon-robot-2-line: '\f3d9';
$remixicon-shapes-fill: '\f3da';
$remixicon-shapes-line: '\f3db';
$remixicon-square-fill: '\f3dc';
$remixicon-square-line: '\f3dd';
$remixicon-tent-fill: '\f3de';
$remixicon-tent-line: '\f3df';
$remixicon-threads-fill: '\f3e0';
$remixicon-threads-line: '\f3e1';
$remixicon-tree-fill: '\f3e2';
$remixicon-tree-line: '\f3e3';
$remixicon-triangle-fill: '\f3e4';
$remixicon-triangle-line: '\f3e5';
$remixicon-twitter-x-fill: '\f3e6';
$remixicon-twitter-x-line: '\f3e7';
$remixicon-verified-badge-fill: '\f3e8';
$remixicon-verified-badge-line: '\f3e9';
$remixicon-armchair-fill: '\f3ea';
$remixicon-armchair-line: '\f3eb';
$remixicon-bnb-fill: '\f3ec';
$remixicon-bnb-line: '\f3ed';
$remixicon-bread-fill: '\f3ee';
$remixicon-bread-line: '\f3ef';
$remixicon-btc-fill: '\f3f0';
$remixicon-btc-line: '\f3f1';
$remixicon-calendar-schedule-fill: '\f3f2';
$remixicon-calendar-schedule-line: '\f3f3';
$remixicon-dice-1-fill: '\f3f4';
$remixicon-dice-1-line: '\f3f5';
$remixicon-dice-2-fill: '\f3f6';
$remixicon-dice-2-line: '\f3f7';
$remixicon-dice-3-fill: '\f3f8';
$remixicon-dice-3-line: '\f3f9';
$remixicon-dice-4-fill: '\f3fa';
$remixicon-dice-4-line: '\f3fb';
$remixicon-dice-5-fill: '\f3fc';
$remixicon-dice-5-line: '\f3fd';
$remixicon-dice-6-fill: '\f3fe';
$remixicon-dice-6-line: '\f3ff';
$remixicon-dice-fill: '\f400';
$remixicon-dice-line: '\f401';
$remixicon-drinks-fill: '\f402';
$remixicon-drinks-line: '\f403';
$remixicon-equalizer-2-fill: '\f404';
$remixicon-equalizer-2-line: '\f405';
$remixicon-equalizer-3-fill: '\f406';
$remixicon-equalizer-3-line: '\f407';
$remixicon-eth-fill: '\f408';
$remixicon-eth-line: '\f409';
$remixicon-flower-fill: '\f40a';
$remixicon-flower-line: '\f40b';
$remixicon-glasses-2-fill: '\f40c';
$remixicon-glasses-2-line: '\f40d';
$remixicon-glasses-fill: '\f40e';
$remixicon-glasses-line: '\f40f';
$remixicon-goggles-fill: '\f410';
$remixicon-goggles-line: '\f411';
$remixicon-image-circle-fill: '\f412';
$remixicon-image-circle-line: '\f413';
$remixicon-info-i: '\f414';
$remixicon-money-rupee-circle-fill: '\f415';
$remixicon-money-rupee-circle-line: '\f416';
$remixicon-news-fill: '\f417';
$remixicon-news-line: '\f418';
$remixicon-robot-3-fill: '\f419';
$remixicon-robot-3-line: '\f41a';
$remixicon-share-2-fill: '\f41b';
$remixicon-share-2-line: '\f41c';
$remixicon-sofa-fill: '\f41d';
$remixicon-sofa-line: '\f41e';
$remixicon-svelte-fill: '\f41f';
$remixicon-svelte-line: '\f420';
$remixicon-vk-fill: '\f421';
$remixicon-vk-line: '\f422';
$remixicon-xrp-fill: '\f423';
$remixicon-xrp-line: '\f424';
$remixicon-xtz-fill: '\f425';
$remixicon-xtz-line: '\f426';
$remixicon-archive-stack-fill: '\f427';
$remixicon-archive-stack-line: '\f428';
$remixicon-bowl-fill: '\f429';
$remixicon-bowl-line: '\f42a';
$remixicon-calendar-view: '\f42b';
$remixicon-carousel-view: '\f42c';
$remixicon-code-block: '\f42d';
$remixicon-color-filter-fill: '\f42e';
$remixicon-color-filter-line: '\f42f';
$remixicon-contacts-book-3-fill: '\f430';
$remixicon-contacts-book-3-line: '\f431';
$remixicon-contract-fill: '\f432';
$remixicon-contract-line: '\f433';
$remixicon-drinks-2-fill: '\f434';
$remixicon-drinks-2-line: '\f435';
$remixicon-export-fill: '\f436';
$remixicon-export-line: '\f437';
$remixicon-file-check-fill: '\f438';
$remixicon-file-check-line: '\f439';
$remixicon-focus-mode: '\f43a';
$remixicon-folder-6-fill: '\f43b';
$remixicon-folder-6-line: '\f43c';
$remixicon-folder-check-fill: '\f43d';
$remixicon-folder-check-line: '\f43e';
$remixicon-folder-close-fill: '\f43f';
$remixicon-folder-close-line: '\f440';
$remixicon-folder-cloud-fill: '\f441';
$remixicon-folder-cloud-line: '\f442';
$remixicon-gallery-view-2: '\f443';
$remixicon-gallery-view: '\f444';
$remixicon-hand: '\f445';
$remixicon-import-fill: '\f446';
$remixicon-import-line: '\f447';
$remixicon-information-2-fill: '\f448';
$remixicon-information-2-line: '\f449';
$remixicon-kanban-view-2: '\f44a';
$remixicon-kanban-view: '\f44b';
$remixicon-list-view: '\f44c';
$remixicon-lock-star-fill: '\f44d';
$remixicon-lock-star-line: '\f44e';
$remixicon-puzzle-2-fill: '\f44f';
$remixicon-puzzle-2-line: '\f450';
$remixicon-puzzle-fill: '\f451';
$remixicon-puzzle-line: '\f452';
$remixicon-ram-2-fill: '\f453';
$remixicon-ram-2-line: '\f454';
$remixicon-ram-fill: '\f455';
$remixicon-ram-line: '\f456';
$remixicon-receipt-fill: '\f457';
$remixicon-receipt-line: '\f458';
$remixicon-shadow-fill: '\f459';
$remixicon-shadow-line: '\f45a';
$remixicon-sidebar-fold-fill: '\f45b';
$remixicon-sidebar-fold-line: '\f45c';
$remixicon-sidebar-unfold-fill: '\f45d';
$remixicon-sidebar-unfold-line: '\f45e';
$remixicon-slideshow-view: '\f45f';
$remixicon-sort-alphabet-asc: '\f460';
$remixicon-sort-alphabet-desc: '\f461';
$remixicon-sort-number-asc: '\f462';
$remixicon-sort-number-desc: '\f463';
$remixicon-stacked-view: '\f464';
$remixicon-sticky-note-add-fill: '\f465';
$remixicon-sticky-note-add-line: '\f466';
$remixicon-swap-2-fill: '\f467';
$remixicon-swap-2-line: '\f468';
$remixicon-swap-3-fill: '\f469';
$remixicon-swap-3-line: '\f46a';
$remixicon-table-3: '\f46b';
$remixicon-table-view: '\f46c';
$remixicon-text-block: '\f46d';
$remixicon-text-snippet: '\f46e';
$remixicon-timeline-view: '\f46f';
$remixicon-blogger-fill: '\f470';
$remixicon-blogger-line: '\f471';
$remixicon-chat-thread-fill: '\f472';
$remixicon-chat-thread-line: '\f473';
$remixicon-discount-percent-fill: '\f474';
$remixicon-discount-percent-line: '\f475';
$remixicon-exchange-2-fill: '\f476';
$remixicon-exchange-2-line: '\f477';
$remixicon-git-fork-fill: '\f478';
$remixicon-git-fork-line: '\f479';
$remixicon-input-field: '\f47a';
$remixicon-progress-1-fill: '\f47b';
$remixicon-progress-1-line: '\f47c';
$remixicon-progress-2-fill: '\f47d';
$remixicon-progress-2-line: '\f47e';
$remixicon-progress-3-fill: '\f47f';
$remixicon-progress-3-line: '\f480';
$remixicon-progress-4-fill: '\f481';
$remixicon-progress-4-line: '\f482';
$remixicon-progress-5-fill: '\f483';
$remixicon-progress-5-line: '\f484';
$remixicon-progress-6-fill: '\f485';
$remixicon-progress-6-line: '\f486';
$remixicon-progress-7-fill: '\f487';
$remixicon-progress-7-line: '\f488';
$remixicon-progress-8-fill: '\f489';
$remixicon-progress-8-line: '\f48a';
$remixicon-remix-run-fill: '\f48b';
$remixicon-remix-run-line: '\f48c';
$remixicon-signpost-fill: '\f48d';
$remixicon-signpost-line: '\f48e';
$remixicon-time-zone-fill: '\f48f';
$remixicon-time-zone-line: '\f490';
$remixicon-arrow-down-wide-fill: '\f491';
$remixicon-arrow-down-wide-line: '\f492';
$remixicon-arrow-left-wide-fill: '\f493';
$remixicon-arrow-left-wide-line: '\f494';
$remixicon-arrow-right-wide-fill: '\f495';
$remixicon-arrow-right-wide-line: '\f496';
$remixicon-arrow-up-wide-fill: '\f497';
$remixicon-arrow-up-wide-line: '\f498';
$remixicon-bluesky-fill: '\f499';
$remixicon-bluesky-line: '\f49a';
$remixicon-expand-height-fill: '\f49b';
$remixicon-expand-height-line: '\f49c';
$remixicon-expand-width-fill: '\f49d';
$remixicon-expand-width-line: '\f49e';
$remixicon-forward-end-fill: '\f49f';
$remixicon-forward-end-line: '\f4a0';
$remixicon-forward-end-mini-fill: '\f4a1';
$remixicon-forward-end-mini-line: '\f4a2';
$remixicon-friendica-fill: '\f4a3';
$remixicon-friendica-line: '\f4a4';
$remixicon-git-pr-draft-fill: '\f4a5';
$remixicon-git-pr-draft-line: '\f4a6';
$remixicon-play-reverse-fill: '\f4a7';
$remixicon-play-reverse-line: '\f4a8';
$remixicon-play-reverse-mini-fill: '\f4a9';
$remixicon-play-reverse-mini-line: '\f4aa';
$remixicon-rewind-start-fill: '\f4ab';
$remixicon-rewind-start-line: '\f4ac';
$remixicon-rewind-start-mini-fill: '\f4ad';
$remixicon-rewind-start-mini-line: '\f4ae';
$remixicon-scroll-to-bottom-fill: '\f4af';
$remixicon-scroll-to-bottom-line: '\f4b0';
$remixicon-add-large-fill: '\f4b1';
$remixicon-add-large-line: '\f4b2';
$remixicon-aed-electrodes-fill: '\f4b3';
$remixicon-aed-electrodes-line: '\f4b4';
$remixicon-aed-fill: '\f4b5';
$remixicon-aed-line: '\f4b6';
$remixicon-alibaba-cloud-fill: '\f4b7';
$remixicon-alibaba-cloud-line: '\f4b8';
$remixicon-align-item-bottom-fill: '\f4b9';
$remixicon-align-item-bottom-line: '\f4ba';
$remixicon-align-item-horizontal-center-fill: '\f4bb';
$remixicon-align-item-horizontal-center-line: '\f4bc';
$remixicon-align-item-left-fill: '\f4bd';
$remixicon-align-item-left-line: '\f4be';
$remixicon-align-item-right-fill: '\f4bf';
$remixicon-align-item-right-line: '\f4c0';
$remixicon-align-item-top-fill: '\f4c1';
$remixicon-align-item-top-line: '\f4c2';
$remixicon-align-item-vertical-center-fill: '\f4c3';
$remixicon-align-item-vertical-center-line: '\f4c4';
$remixicon-apps-2-add-fill: '\f4c5';
$remixicon-apps-2-add-line: '\f4c6';
$remixicon-close-large-fill: '\f4c7';
$remixicon-close-large-line: '\f4c8';
$remixicon-collapse-diagonal-2-fill: '\f4c9';
$remixicon-collapse-diagonal-2-line: '\f4ca';
$remixicon-collapse-diagonal-fill: '\f4cb';
$remixicon-collapse-diagonal-line: '\f4cc';
$remixicon-dashboard-horizontal-fill: '\f4cd';
$remixicon-dashboard-horizontal-line: '\f4ce';
$remixicon-expand-diagonal-2-fill: '\f4cf';
$remixicon-expand-diagonal-2-line: '\f4d0';
$remixicon-expand-diagonal-fill: '\f4d1';
$remixicon-expand-diagonal-line: '\f4d2';
$remixicon-firebase-fill: '\f4d3';
$remixicon-firebase-line: '\f4d4';
$remixicon-flip-horizontal-2-fill: '\f4d5';
$remixicon-flip-horizontal-2-line: '\f4d6';
$remixicon-flip-horizontal-fill: '\f4d7';
$remixicon-flip-horizontal-line: '\f4d8';
$remixicon-flip-vertical-2-fill: '\f4d9';
$remixicon-flip-vertical-2-line: '\f4da';
$remixicon-flip-vertical-fill: '\f4db';
$remixicon-flip-vertical-line: '\f4dc';
$remixicon-formula: '\f4dd';
$remixicon-function-add-fill: '\f4de';
$remixicon-function-add-line: '\f4df';
$remixicon-goblet-2-fill: '\f4e0';
$remixicon-goblet-2-line: '\f4e1';
$remixicon-golf-ball-fill: '\f4e2';
$remixicon-golf-ball-line: '\f4e3';
$remixicon-group-3-fill: '\f4e4';
$remixicon-group-3-line: '\f4e5';
$remixicon-heart-add-2-fill: '\f4e6';
$remixicon-heart-add-2-line: '\f4e7';
$remixicon-id-card-fill: '\f4e8';
$remixicon-id-card-line: '\f4e9';
$remixicon-information-off-fill: '\f4ea';
$remixicon-information-off-line: '\f4eb';
$remixicon-java-fill: '\f4ec';
$remixicon-java-line: '\f4ed';
$remixicon-layout-grid-2-fill: '\f4ee';
$remixicon-layout-grid-2-line: '\f4ef';
$remixicon-layout-horizontal-fill: '\f4f0';
$remixicon-layout-horizontal-line: '\f4f1';
$remixicon-layout-vertical-fill: '\f4f2';
$remixicon-layout-vertical-line: '\f4f3';
$remixicon-menu-fold-2-fill: '\f4f4';
$remixicon-menu-fold-2-line: '\f4f5';
$remixicon-menu-fold-3-fill: '\f4f6';
$remixicon-menu-fold-3-line: '\f4f7';
$remixicon-menu-fold-4-fill: '\f4f8';
$remixicon-menu-fold-4-line: '\f4f9';
$remixicon-menu-unfold-2-fill: '\f4fa';
$remixicon-menu-unfold-2-line: '\f4fb';
$remixicon-menu-unfold-3-fill: '\f4fc';
$remixicon-menu-unfold-3-line: '\f4fd';
$remixicon-menu-unfold-4-fill: '\f4fe';
$remixicon-menu-unfold-4-line: '\f4ff';
$remixicon-mobile-download-fill: '\f500';
$remixicon-mobile-download-line: '\f501';
$remixicon-nextjs-fill: '\f502';
$remixicon-nextjs-line: '\f503';
$remixicon-nodejs-fill: '\f504';
$remixicon-nodejs-line: '\f505';
$remixicon-pause-large-fill: '\f506';
$remixicon-pause-large-line: '\f507';
$remixicon-play-large-fill: '\f508';
$remixicon-play-large-line: '\f509';
$remixicon-play-reverse-large-fill: '\f50a';
$remixicon-play-reverse-large-line: '\f50b';
$remixicon-police-badge-fill: '\f50c';
$remixicon-police-badge-line: '\f50d';
$remixicon-prohibited-2-fill: '\f50e';
$remixicon-prohibited-2-line: '\f50f';
$remixicon-shopping-bag-4-fill: '\f510';
$remixicon-shopping-bag-4-line: '\f511';
$remixicon-snowflake-fill: '\f512';
$remixicon-snowflake-line: '\f513';
$remixicon-square-root: '\f514';
$remixicon-stop-large-fill: '\f515';
$remixicon-stop-large-line: '\f516';
$remixicon-tailwind-css-fill: '\f517';
$remixicon-tailwind-css-line: '\f518';
$remixicon-tooth-fill: '\f519';
$remixicon-tooth-line: '\f51a';
$remixicon-video-off-fill: '\f51b';
$remixicon-video-off-line: '\f51c';
$remixicon-video-on-fill: '\f51d';
$remixicon-video-on-line: '\f51e';
$remixicon-webhook-fill: '\f51f';
$remixicon-webhook-line: '\f520';
$remixicon-weight-fill: '\f521';
$remixicon-weight-line: '\f522';
$remixicon-book-shelf-fill: '\f523';
$remixicon-book-shelf-line: '\f524';
$remixicon-brain-2-fill: '\f525';
$remixicon-brain-2-line: '\f526';
$remixicon-chat-search-fill: '\f527';
$remixicon-chat-search-line: '\f528';
$remixicon-chat-unread-fill: '\f529';
$remixicon-chat-unread-line: '\f52a';
$remixicon-collapse-horizontal-fill: '\f52b';
$remixicon-collapse-horizontal-line: '\f52c';
$remixicon-collapse-vertical-fill: '\f52d';
$remixicon-collapse-vertical-line: '\f52e';
$remixicon-dna-fill: '\f52f';
$remixicon-dna-line: '\f530';
$remixicon-dropper-fill: '\f531';
$remixicon-dropper-line: '\f532';
$remixicon-expand-diagonal-s-2-fill: '\f533';
$remixicon-expand-diagonal-s-2-line: '\f534';
$remixicon-expand-diagonal-s-fill: '\f535';
$remixicon-expand-diagonal-s-line: '\f536';
$remixicon-expand-horizontal-fill: '\f537';
$remixicon-expand-horizontal-line: '\f538';
$remixicon-expand-horizontal-s-fill: '\f539';
$remixicon-expand-horizontal-s-line: '\f53a';
$remixicon-expand-vertical-fill: '\f53b';
$remixicon-expand-vertical-line: '\f53c';
$remixicon-expand-vertical-s-fill: '\f53d';
$remixicon-expand-vertical-s-line: '\f53e';
$remixicon-gemini-fill: '\f53f';
$remixicon-gemini-line: '\f540';
$remixicon-reset-left-fill: '\f541';
$remixicon-reset-left-line: '\f542';
$remixicon-reset-right-fill: '\f543';
$remixicon-reset-right-line: '\f544';
$remixicon-stairs-fill: '\f545';
$remixicon-stairs-line: '\f546';
$remixicon-telegram-2-fill: '\f547';
$remixicon-telegram-2-line: '\f548';
$remixicon-triangular-flag-fill: '\f549';
$remixicon-triangular-flag-line: '\f54a';
$remixicon-user-minus-fill: '\f54b';
$remixicon-user-minus-line: '\f54c';
