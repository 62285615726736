//
// badge.scss
//

.badge {
  color: $white;
  padding: 4px 8px;
  font-size: 12px;
  font-weight: 500;
}

.badge-light {
  color: $gray-800;
}

.badge-lg {
  font-size: $font-size-base;
}

// badge lighten

@mixin badge-light-variant($bg) {
  background-color: rgba($bg, 0.2);
  color: $bg !important;
}

@each $color, $value in $theme-colors {
  .badge-light-#{$color} {
    @include badge-light-variant($value);
  }
}
