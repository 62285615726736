//
// footer.scss
//

.footer {
  bottom: 0;
  padding: 19px 15px 20px;
  position: absolute;
  right: 0;
  color: $gray-600;
  left: 0;
  background-color: $white;
  box-shadow: 0 0 1px rgba(50, 58, 70, 0.1);
  font-size: 0.82rem;

  .footer-links {
    display: flex;
    align-items: center;

    a {
      color: $gray-600;
      margin-left: 1.75rem;
      transition: all 0.4s;
      &:hover {
        color: $gray-900;
      }
      &:first-of-type {
        margin-left: 0;
      }
    }
  }
}

.footer-sticky {
  padding: 19px 15px 20px;
  color: $gray-600;
  background-color: $white;
  box-shadow: 0 0 1px rgba(50, 58, 70, 0.1);
  font-size: 0.82rem;

  .footer-links {
    display: flex;
    align-items: center;

    a {
      color: $gray-600;
      margin-left: 1.75rem;
      transition: all 0.4s;
      &:hover {
        color: $gray-900;
      }
      &:first-of-type {
        margin-left: 0;
      }
    }
  }
}

.footer-alt {
  left: 0 !important;
  text-align: center;
  background-color: transparent;
  box-shadow: none;
}

@include media-breakpoint-down(sm) {
  .footer {
    left: 0 !important;
    text-align: center;
  }
}
