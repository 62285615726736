//
// icons.scss
//

@import 'icons/materialdesignicons';
@import 'icons/feather';
@import 'icons/fontawesome-all';
@import 'icons/remixicon';

.rotate-180 {
  transform: rotate(180deg);
}
