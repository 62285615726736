// 
// widgets.scss
//

// icon bg widgets

@mixin backgorund-icon-variant($bg) {
    border: 6px solid rgba($bg, 0.3) !important;
    background-color: rgba($bg, 0.75) !important;
}

@each $color, $value in $theme-colors {
    .bg-icon-#{$color} {
      @include backgorund-icon-variant($value);
    }
}

.circliful-chart{
    margin-top: -5px;
}

// Inbox-widget(Used Profile)
.inbox-widget {
    .inbox-item {
        overflow: hidden;
        padding: 0.7rem 0;
        position: relative;
        &:last-of-type {
            border-bottom: none;
        }
        .inbox-item-img {
            display: block;
            float: left;
            margin-right: 15px;
            width: 40px;
            position: relative;

            img {
                width: 40px;
            }
        }
        .inbox-item-author {
            color: $dark;
            display: block;
            margin-bottom: 0;
        }
        .inbox-item-text {
            color: $gray-600;
            display: block;
            font-size: 0.8125rem;
            margin: 0;
            overflow: hidden;
        }
        .inbox-item-date {
            color: $gray-600;
            font-size: 0.6875rem;
            position: absolute;
            right: 5px;
            top: 10px;
        }
    }

    .user-status {
        height: 10px;
        width: 10px;
        background-color: $gray-400;
        border-radius: 50%;
        position: absolute;
        right: 0;
        bottom: 2px;
        border: 2px solid $white;

        &.online {
            background-color: $success;
        }

        &.away {
            background-color: $warning;
        }

        &.busy {
            background-color: $danger;
        }
    }
}

/* Chat widget */

.conversation-list {
    list-style: none;
    height: 332px;
    padding: 0 10px;
    li {
        margin-bottom: 24px;
    }
    .chat-avatar {
        float: left;
        text-align: center;
        width: 42px;
        img {
            border-radius: 100%;
            width: 100%;
        }
        i {
            font-size: 12px;
            font-style: normal;
        }
    }
    .ctext-wrap {
        -moz-border-radius: 3px;
        -webkit-border-radius: 3px;
        background: $gray-200;
        border-radius: 3px;
        display: inline-block;
        padding: 12px;
        position: relative;
        i {
            display: block;
            font-size: 12px;
            font-style: normal;
            font-weight: bold;
            position: relative;
        }
        p {
            margin: 0;
            padding-top: 3px;
        }
        &:after {
            right: 99%;
            top: 0;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-top-color: $gray-200;
            border-width: 8px;
            margin-left: -1px;
            border-right-color: $gray-200;
        }
    }
    .conversation-text {
        float: left;
        font-size: 12px;
        margin-left: 12px;
        width: 70%;
    }
    .odd {
        .chat-avatar {
            float: right !important;
        }
        .conversation-text {
            float: right !important;
            margin-right: 12px;
            text-align: right;
            width: 70% !important;
        }
        .ctext-wrap {
            background-color: lighten($primary, 36%);
            &:after {
                border-color: transparent;
                border-left-color: lighten($primary, 36%);
                border-top-color: lighten($primary, 36%);
                left: 99% !important;
            }
        }
    }
}