//
// slimscroll.scss
//

.slimScrollDiv {
  height: auto !important;
}

.scrollbar-track {
  background: transparent !important;
}

.slimscroll-menu {
  height: 100%;
}
.scrollbar-thumb {
  background: rgb(158, 165, 171) !important;
  opacity: 0.4;
}
