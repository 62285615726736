//
// nav.scss
//

.nav-tabs,
.nav-pills {
  > li {
    > a {
      color: $gray-800;
      font-weight: $font-weight-semibold;
    }
  }
}

.nav-tabs.nav-tabs-sm {
  > li {
    > a {
      font-size: 0.8rem;
    }
  }
}

.nav-pills {
  > a {
    color: $gray-800;
    font-weight: $font-weight-semibold;
  }
}

//Navtab custom
.navtab-bg {
  li {
    > a {
      background-color: $gray-200;
      margin: 0 5px;
    }
  }
}

// Nav bordered

.nav-bordered {
  border-bottom: 2px solid rgba($gray-600, 0.2) !important;
  .nav-item {
    margin-bottom: -2px;
  }

  li {
    a {
      border: 0 !important;
      padding: 10px 20px !important;
    }
  }

  a.active {
    border-bottom: 2px solid $primary !important;
  }
}

.tab-content {
  padding: 20px 0 0 0;
}

// .nav-tabs .nav-link.active {
//   background-color: transparent !important;
// }

.nav-tabs .nav-link {
  border-width: 0;
  transition: all 0.3s ease;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-link:focus {
  border-color: $primary;
  border-bottom-width: 2px;
  color: $primary;
}

.nav-tabs .nav-link:hover {
  border-bottom-width: 2px;
  border-color: $primary;
  color: $primary;
}
