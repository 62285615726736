//
// forms.scss
//

// Form-control light
.form-control-light {
  background-color: $gray-100;
  border-color: $gray-100;
}

// Form elements (Color and Range)
input.form-control[type='color'],
input.form-control[type='range'] {
  min-height: 39px;
}

// Custom select
.custom-select.is-invalid,
.form-control.is-invalid,
.custom-select:invalid,
.form-control:invalid,
.custom-select.is-valid,
.form-control.is-valid,
.custom-select:valid,
.form-control:valid {
  &:focus {
    box-shadow: none !important;
  }
}

// Input
input {
  &:focus {
    outline: none;
  }
}

.ng-select.ng-select-multiple.ng-select-disabled > .ng-select-container .ng-value-container .ng-value {
  background-color: rgba($primary, 0.6);
  border: 1px solid #f9f9f9;
  color: -dark;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  border-radius: 12px;
  padding: 2px 6px;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
  border-right-color: rgba($white, 0.5);

  padding: 1px 6px;

  &:hover {
    background-color: unset;
  }
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-label {
  padding: 1px 6px;
}

//disable typing in datepicker
.form-control[readonly] {
  background-color: #fff;
}

.form-control:disabled {
  background-color: #f9f9f9;
}

.form-control[readonly]:disabled {
  background-color: #f9f9f9;
}

.form-label {
  font-size: 0.8rem;
}

.custom-control:not(.non-clickable):hover,
.custom-checkbox:not(.non-clickable):hover {
  cursor: pointer !important;
  input:hover,
  label:hover {
    cursor: pointer !important;
  }
}

.custom-control-label {
  font-weight: 400 !important;
}

textarea {
  resize: none;
}

label.form-label {
  i.remixicon-question-line {
    vertical-align: bottom;
  }
}
