.maplix-wizard {
  height: 100%;
  min-height: 100%;

  .wizard-steps.horizontal {
    height: 100%;
    min-height: 100%;
  }

  aw-wizard-step {
    height: 100%;
    min-height: 100%;
  }

  aw-wizard-navigation-bar ul.steps-indicator li a .label {
    text-transform: none;
  }
  aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li .step-indicator,
  aw-wizard-navigation-bar.vertical.small ul.steps-indicator li .step-indicator {
    color: #e6e6e6;
  }
  aw-wizard-navigation-bar.horizontal ul.steps-indicator li.editing .step-indicator,
  aw-wizard-navigation-bar.vertical ul.steps-indicator li.editing .step-indicator,
  aw-wizard-navigation-bar.horizontal ul.steps-indicator li.editing .step-indicator:hover,
  aw-wizard-navigation-bar.vertical ul.steps-indicator li.editing .step-indicator:hover {
    border-color: map-get($theme-colors, primary) !important;
    color: map-get($theme-colors, primary) !important;
  }
  aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li.done .step-indicator,
  aw-wizard-navigation-bar.vertical.small ul.steps-indicator li.done .step-indicator,
  aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li.done .step-indicator:hover,
  aw-wizard-navigation-bar.vertical.small ul.steps-indicator li.done .step-indicator:hover {
    background-color: map-get($theme-colors, dark) !important;
  }
  aw-wizard-navigation-bar.horizontal ul.steps-indicator li.current .step-indicator,
  aw-wizard-navigation-bar.vertical ul.steps-indicator li.current .step-indicator,
  aw-wizard-navigation-bar.horizontal ul.steps-indicator li.current .step-indicator:hover,
  aw-wizard-navigation-bar.vertical ul.steps-indicator li.current .step-indicator:hover {
    border-color: map-get($theme-colors, primary) !important;
    color: map-get($theme-colors, primary) !important;
  }
  aw-wizard-navigation-bar.horizontal ul.steps-indicator li.done .step-indicator,
  aw-wizard-navigation-bar.vertical ul.steps-indicator li.done .step-indicator,
  aw-wizard-navigation-bar.horizontal ul.steps-indicator li.done .step-indicator:hover,
  aw-wizard-navigation-bar.vertical ul.steps-indicator li.done .step-indicator:hover {
    border-color: map-get($theme-colors, primary) !important;
    color: map-get($theme-colors, primary) !important;
  }
  aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li.editing .step-indicator,
  aw-wizard-navigation-bar.vertical.small ul.steps-indicator li.editing .step-indicator,
  aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li.editing .step-indicator:hover,
  aw-wizard-navigation-bar.vertical.small ul.steps-indicator li.editing .step-indicator:hover {
    background-color: map-get($theme-colors, primary) !important;
  }
  aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li.current .step-indicator,
  aw-wizard-navigation-bar.vertical.small ul.steps-indicator li.current .step-indicator,
  aw-wizard-navigation-bar.horizontal.small ul.steps-indicator li.current .step-indicator:hover,
  aw-wizard-navigation-bar.vertical.small ul.steps-indicator li.current .step-indicator:hover {
    background-color: map-get($theme-colors, primary) !important;
  }
}

.no-title-bar {
  aw-wizard-navigation-bar.vertical {
    width: auto !important;
    max-width: 100px !important;
  }
  .wizard-steps {
    width: auto !important;
    min-width: calc(100% - 100px) !important;
  }
}
